import { formatDate } from "./formatDate";

export const formatDateTime = (date: Date, separator = "-") => {
  const timezoneOffset = Number(date.getTimezoneOffset()) * -1;
  const formattedTimezoneOffset = `${Math.floor(timezoneOffset / 60)
    .toString()
    .padStart(2, "0")}${(timezoneOffset % 60).toString().padStart(2, "0")}`;

  return `${formatDate(date, separator)} ${date
    .getHours()
    .toString()
    .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}${
    timezoneOffset > 0 ? "+" : ""
  }${formattedTimezoneOffset}`;
};
