import React from "react";
import { useLocation } from "react-router-dom";
import "./api/firebase"; // Start analytics
import { AppRoutes } from "./Routes";

export const App = () => {
  const location = useLocation();

  return (
    <>
      <AppRoutes location={location} />
    </>
  );
};
