import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { IoEllipsisHorizontal } from "react-icons/io5";

import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

import { supabase } from "../../api/supabase";
import { Loader } from "../../components/Loader";
import { Navbar } from "../../components/Navbar";
import { colors } from "../../theme/colors";
import { styles } from "../../theme/styles";
import { parseJson } from "../../utils/parseJson";
import { Board } from "../../types/Board";
import { Toggle } from "../../components/Toggle";
import { Bubble } from "../../widgets/Bubble";
import { Spacer } from "../../components/Spacer";
import { Paper } from "../../types/Paper";
import { wait } from "../../utils/wait";
import { Modal } from "../../components/Modal";
import { zoomPlugin } from "@react-pdf-viewer/zoom";

// Import styles
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { capitalize } from "../../utils/capitalize";
const PAGE_SIZE = 10; // Number of items in a page

export const PastPaperResults = () => {
  const [results, setResults] = useState<Paper[][]>([]); // Array of results by page
  const [pages, setPages] = useState(1); // Total number of pages of size PAGE_SIZE
  const [activePage, setActivePage] = useState(0); // Active page
  const [activeTab, setActiveTab] = useState<0 | 1>(0); // Active tab - Question (0) or Answer(1)
  const [loadedPage, setLoadedPage] = useState(false); // Loaded page number from query parameters into state

  const [fetchingPages, setFetchingPages] = useState<Record<number, boolean>>(
    []
  );
  const [fetchedPages, setFetchedPages] = useState<number[]>([]);
  const [boardsList, setBoardsList] = useState<Board[]>([]);

  const { board, subject } = useParams(); // Board and subject to query
  const [searchParams, setSearchParams] = useSearchParams(); // Search params

  const [selectedPaper, setSelectedPaper] = useState<number | null>(null); // Active question ID
  const [selectedPaperData, setSelectedPaperData] = useState<Paper | null>(
    null
  );

  const [completedPapers, setCompletedPapers] = useState<
    Record<string | number, boolean>
  >({}); // Map of completed questions
  const [paperCompleted, setPaperCompleted] = useState(false); // The question's completion status

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const [tabPages, setTabPages] = useState<[number, number]>([0, 0]);

  const [questionPages, setQuestionPages] = useState<number[]>([]);
  const [answerPages, setAnswerPages] = useState<number[]>([]);

  const [numPagesMarkscheme, setNumPagesMarkscheme] = useState(0);

  const [indexingPages, setIndexingPages] = useState(false);
  const [questionPaperLoaded, setQuestionPaperLoaded] = useState(false);
  const [markschemeLoaded, setMarkschemeLoaded] = useState(false);
  const [indexingDocument, setIndexingDocument] = useState<0 | 1 | null>(null);

  const [showResourcesModal, setShowResourcesModal] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = `Markhint | ${capitalize(
      board?.replace("-", " ") ?? ""
    )} ${subject} Past Papers`;
  });

  // When results are fetched, set the active question to the first result
  useEffect(() => {
    if (results.length && results[0]?.length) {
      setSelectedPaper(results[0][0].id);
      console.log("Setting active tab");
      setActiveTab(0);
      // setIndexingPages(true);
      setQuestionPaperLoaded(false);
      setMarkschemeLoaded(false);
    }
  }, [results]);

  useEffect(() => {
    if (!answerPages.length && numPagesMarkscheme) {
      // (async function () {
      //   await wait(2000);
      //   setTabPages([0, 1]);
      //   await wait(2000);
      //   console.log("Done indexing wait");
      //   setActiveTab(0);
      //   setTabPages([1, 0]);
      //   // await wait(3000);
      //   // setIndexingPages(false);
      // })();
    }
  }, [answerPages, numPagesMarkscheme]);

  useEffect(() => {
    (async function () {
      if (!indexingPages) return;

      if (markschemeLoaded && numPagesMarkscheme) {
        // setIndexingDocument(1);
        // setActiveTab(0);
        // setTabPages([0, numPagesMarkscheme - 2]);
        // await wait(1000);
        // setActiveTab(1);
        // await wait(1000);
        // setTabPages([0, 0]);
        // setActiveTab(0);
        // await wait(1000);
        // setIndexingDocument(null);
        // setIndexingPages(false);
      }
    })();
  }, [
    indexingPages,
    questionPaperLoaded,
    markschemeLoaded,
    indexingDocument,
    numPagesMarkscheme,
  ]);

  // Runs when user selects a new question
  // Sets accompanying details - selectedQuestionImage, selectedQuestionAnswer and selectedQuestionType
  useEffect(() => {
    if (selectedPaper) {
      if (results[activePage]) {
        const result = results[activePage].find(
          (result) => result.id === selectedPaper
        );
        if (result) {
          setSelectedPaperData(result);
        }
      }
    }
  }, [selectedPaper, activePage, results]);

  // Changes the URL when the active page changes
  useEffect(() => {
    if (searchParams.get("page") !== String(activePage)) {
      const newSearchParams: Record<string, any> = {};

      for (const [key, value] of Array.from(searchParams.entries())) {
        newSearchParams[key] = value;
      }

      newSearchParams.page = activePage;

      setSearchParams(newSearchParams);
    }
  }, [activePage, searchParams, setSearchParams]);

  // Checks if the URL specifies a start page
  useEffect(() => {
    const page = searchParams.get("page");
    if (page && !loadedPage) {
      setActivePage(Number(page));
      setLoadedPage(true);
    } else if (!page && !loadedPage) {
      setLoadedPage(true);
    }
  }, [searchParams, loadedPage]);

  // Fetches questions from the database according to the query. Typically runs on page load and when the user selects a new page.
  useEffect(() => {
    (async function () {
      // If the requested page has already been fetched, read from memory.
      if (results[activePage] && !results[activePage]?.length) return;
      if (results[activePage]?.length) {
        setSelectedPaper(results[activePage][0].id);
        return;
      }
      if (!loadedPage) return;
      if (fetchedPages.includes(activePage)) return;
      if (fetchingPages[activePage]) return;

      setFetchingPages((fetchingPages) => ({
        ...fetchingPages,
        [activePage]: true,
      }));

      try {
        // Get the search parameters
        const [paper, year, season, variant] = [
          searchParams.get("paper") || "",
          searchParams.get("year") || "",
          searchParams.get("season") || "",
          searchParams.get("variant") || "",
        ];

        let boards: Board[] = boardsList;

        if (!boards.length) {
          const { data } = await supabase.from("boards").select("name,slug");

          boards = data as Board[];
          setBoardsList(boards);
        }

        const boardName = (
          boards || [
            { slug: "a-levels", name: "A Levels" },
            { slug: "igcse", name: "IGCSE" },
            { slug: "ib-diploma", name: "IB Diploma" },
            { slug: "edexcel-a-levels", name: "Edexcel A Levels" },
          ]
        ).find((el) => el.slug === board)?.name;

        // Start constructing a query for all questions where board=board and subject=subject, including the total number (for pagination)
        let query = supabase
          .from("papers")
          .select("*", { count: "exact" })
          .eq("board", boardName)
          .eq("subject", subject);

        if (paper) {
          query = query.eq("paper_number", paper); // Add paper numbers as a filter if they have been selected
        }
        if (variant) {
          query = query.eq("variant", variant);
        }
        if (year) {
          // Add years as a filter if they have been selected
          query = query.eq("year", Number(year));
        }
        if (season.length) {
          query = query.eq("season", season);
        }

        // Sort the query in descending order of year
        query = query
          .order("year", { ascending: false })
          .order("season", { ascending: false })
          .order("paper_number")
          .order("variant");

        // Get the questions and count, for the selected page

        const { data = [], count } = await query.range(
          PAGE_SIZE * activePage,
          PAGE_SIZE * (activePage + 1)
        );

        const numPages = Math.ceil(count! / PAGE_SIZE);

        let newResults: Paper[][] = [];
        if (results.length === 0) {
          newResults = Array.from({ length: numPages }).fill(null) as Paper[][];
          newResults[activePage] = data as Paper[];
        } else {
          newResults = [...results];
          newResults[activePage] = data as Paper[];
        }

        // Add the results to state
        setResults(newResults);
        if (count) {
          // Set the number of total pages
          setPages(numPages);
        }
      } catch (e) {
        let newResults: Paper[][] = [];
        if (results.length === 0) {
          newResults = Array.from({ length: activePage + 1 }).fill(
            null
          ) as Paper[][];
          newResults[activePage] = [];
        } else {
          newResults = [...results];
          newResults[activePage] = [] as Paper[];
        }

        setResults(newResults);
      } finally {
        setFetchedPages((fetchedPages) => [...fetchedPages, activePage]);
        setFetchingPages((fetchingPages) => ({
          ...fetchingPages,
          [activePage]: false,
        }));
      }
    })();
  }, [
    activePage,
    board,
    results,
    searchParams,
    subject,
    loadedPage,
    fetchedPages,
    boardsList,
    fetchingPages,
  ]);

  // When a new page is fetched, set the selected question to the first question from that page
  useEffect(() => {
    if (results[results.length - 1]?.length) {
      setSelectedPaper(results[results.length - 1][0].id);
    }
  }, [results]);

  // When a new question is selected, select the Question tab and update the completion status
  useEffect(() => {
    // setActiveTab(0);

    if (selectedPaper) {
      const completedPapers: Record<string | number, boolean> = parseJson(
        localStorage.getItem("completedPapers") || "{}"
      );

      if (completedPapers[selectedPaper]) {
        setPaperCompleted(true);
      } else {
        setPaperCompleted(false);
      }

      setCompletedPapers(completedPapers);
    }
  }, [selectedPaper]);

  // Mark question as completed
  const completePaper = useCallback(
    (id: number | string, newState: boolean) => {
      const completedPapers = parseJson(
        localStorage.getItem("completedPapers") || "{}"
      );
      completedPapers[id] = newState;
      localStorage.setItem("completedPapers", JSON.stringify(completedPapers));
      setPaperCompleted(newState);
    },
    [setPaperCompleted]
  );

  // Implements keyboard-based navigation of questions: Tab to go to the next question and Shift+Tab to go to the previous question
  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.key === "Tab" || evt.key === "Enter") {
        evt.preventDefault();

        if (evt.key === "Enter" && selectedPaper) {
          completePaper(selectedPaper, true);
        }

        const increment = evt.shiftKey ? -1 : 1; // If shift key is pressed, decrement the selected question, otherwise, increment

        const page = results[activePage];

        const index = page.findIndex(
          (question) => question.id === selectedPaper
        );

        const newIndex = index + increment;

        if (newIndex >= 0 && newIndex < page.length) {
          // If the incremented index is within page array bounds, set the selected question to that
          setSelectedPaper(page[newIndex].id);
        } else if (newIndex >= 0 && activePage < pages - 1) {
          // If the incremented index is above page array bounds, increment the page and set the selected question to the first question from that page
          setActivePage(activePage + 1);
          setSelectedPaper(results[activePage + 1][0].id);
        } else if (newIndex < page.length && activePage >= 1) {
          // If the incremented index is below page array bounds, decrement the page and set the selected question to the first question from that page
          setActivePage(activePage - 1);
          setSelectedPaper(results[activePage - 1][0].id);
        }
      } else if (evt.key === "q" || evt.key === "a") {
        setActiveTab(evt.key === "q" ? 0 : 1); // Switch tabs to/from question/answer
      } else if (evt.key === "Escape") {
        setShowResourcesModal(false);
      } else if (evt.key === "?" || (evt.key === "/" && evt.shiftKey)) {
        setShowResourcesModal(true);
      }
    },
    [results, selectedPaper, activePage, setSelectedPaper, pages, completePaper]
  );

  // Attach listener for keydown (keyboard-based navigation)
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const findPage = (span: HTMLSpanElement) => {
    try {
      let parent: HTMLDivElement = span.parentNode as HTMLDivElement;

      while (
        !!parent &&
        !/Page [0-9]{1,}/g.test(
          parent.attributes.getNamedItem("aria-label")?.value || ""
        )
      ) {
        parent = parent.parentNode as HTMLDivElement;
      }

      return (
        Number(
          parent.attributes.getNamedItem("aria-label")!.value.split(" ")[1]
        ) - 1
      );
    } catch (e) {
      console.log(e);
      return 0;
    }
  };
  const zoomPluginInstance = zoomPlugin();
  // IMPORTANT replace indexing
  return (
    <Main sidebarVisible={sidebarVisible}>
      <Modal
        title="Additional Resources"
        show={showResourcesModal}
        setShow={setShowResourcesModal}
        actions={[
          { label: "Close", onClick: () => setShowResourcesModal(false) },
        ]}
      >
        <Spacer size={20} direction="bottom" />
        <ResourcesContainer>
          <Resource>
            <ResourceThumbnail src="https://qnpumhfafqmcymbxzuvc.supabase.co/storage/v1/object/public/assets/mf19_thumbnail.png" />
            <Spacer size={10} direction="bottom" />
            <ResourceLabel
              href="https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=9709_mf_19&type=qp"
              target="_blank"
            >
              MF19 (9709/9231 Mathematics)
            </ResourceLabel>
          </Resource>
          <Resource>
            <ResourceThumbnail src="https://qnpumhfafqmcymbxzuvc.supabase.co/storage/v1/object/public/assets/9701_data_booklet_thumbnail.png" />
            <Spacer size={10} direction="bottom" />
            <ResourceLabel
              href="https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=9701_data_booklet&type=qp"
              target="_blank"
            >
              Chemistry Data Booklet (9701 Chemistry)
            </ResourceLabel>
          </Resource>
        </ResourcesContainer>
      </Modal>
      {board === "a-levels" && (
        <ResourcesButton onClick={() => setShowResourcesModal(true)}>
          <IoEllipsisHorizontal
            color={colors.text.hex}
            size={40}
            opacity={0.7}
          />
        </ResourcesButton>
      )}
      {indexingPages && (
        <LoadingOverlay>
          <Loader white />
          <LoadingText>Indexing...</LoadingText>
        </LoadingOverlay>
      )}
      <Navbar />
      <Content>
        <Overlay
          onClick={() => {
            setSidebarVisible((sidebarVisible) => !sidebarVisible);
          }}
          show={sidebarVisible}
        ></Overlay>
        <NavRow>
          <BackContainer onClick={() => navigate("/papers")}>
            <BackIcon />
          </BackContainer>
          <MobileMenuContainer
            onClick={() => {
              setSidebarVisible((sidebarVisible) => !sidebarVisible);
            }}
            style={{ marginLeft: "5rem" }}
          >
            {sidebarVisible ? <CloseIcon /> : <MenuIcon />}
          </MobileMenuContainer>
        </NavRow>
        <PagesContainer>
          {new Array(pages).fill(null).map((_, page) => (
            <Page
              active={activePage === page}
              onClick={() => setActivePage(page)}
            >
              {page + 1}
            </Page>
          ))}
        </PagesContainer>
        <Row>
          <SidebarContainer show={sidebarVisible}>
            <ListContainer>
              {!results || (!results[activePage] && <Loader />)}
              {results &&
                results[activePage] &&
                !results[activePage].length && (
                  <NoResults>No results!</NoResults>
                )}
              {(results[activePage] || []).map((paper) => (
                <ListItem
                  key={paper.id}
                  selected={paper.id === selectedPaper}
                  completed={completedPapers[paper.id]}
                  onClick={() => {
                    setSelectedPaper(paper.id);
                    setQuestionPages([]);
                    setAnswerPages([]);
                    setNumPagesMarkscheme(0);
                    setActiveTab(0);
                    // setIndexingPages(true);
                    setQuestionPaperLoaded(false);
                    setMarkschemeLoaded(false);
                  }}
                >
                  <div>
                    <ListTitle>
                      {paper.year} {paper.season} Paper {paper.paper_number}
                      {paper.variant}
                    </ListTitle>
                    <BubbleContainer>
                      <Bubble>{paper.season}</Bubble>
                      <Bubble>{paper.year}</Bubble>
                      <Bubble>
                        {paper.paper_number}
                        {paper.variant}
                      </Bubble>
                    </BubbleContainer>
                  </div>
                  {/* <Thumbnail
                    style={{
                      backgroundImage: `url('${
                        "https://qnpumhfafqmcymbxzuvc.functions.supabase.co/get-image?url=" +
                        question.images[0].replace(
                          "https://www.exam-mate.com/",
                          ""
                        )
                      }')`,
                    }}
                    show={question.id !== selectedPaper}
                  /> */}
                </ListItem>
              ))}
            </ListContainer>
          </SidebarContainer>
          <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
            <QuestionContainer>
              <TabsContainer>
                <div style={{ display: "flex" }}>
                  <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                    Question Paper
                  </Tab>
                  <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                    Mark Scheme
                  </Tab>
                </div>
                <ToggleContainer>
                  <Toggle
                    active={paperCompleted}
                    onToggle={() => {
                      if (selectedPaper) {
                        completePaper(selectedPaper, !paperCompleted);
                      }
                    }}
                    onLabel="Completed"
                    offLabel="Not Completed"
                  />
                </ToggleContainer>
              </TabsContainer>
              <Spacer size={-10} direction="bottom" />
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-evenly",
                }}
              >
                <NewTabButton
                  onClick={() => {
                    window.open(`/papers/pdf/${selectedPaperData?.slug}/qp`);
                  }}
                >
                  Open Question Paper
                </NewTabButton>
                <NewTabButton
                  onClick={() => {
                    window.open(`/papers/pdf/${selectedPaperData?.slug}/ms`);
                  }}
                >
                  Open Markscheme
                </NewTabButton>
              </div>
              <Spacer size={10} direction="bottom" />
              <div
                style={{
                  overflow: `scroll`,
                  display: `${activeTab == 0 ? "inherit" : "none"}`,
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer
                    plugins={[zoomPluginInstance]}
                    fileUrl={`https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=${selectedPaperData?.slug}&type=qp`}
                    onDocumentLoad={async (e) => {
                      setQuestionPaperLoaded(true);
                      console.log("new");
                    }}
                    onPageChange={(page) => {
                      var spanElements = document.getElementsByTagName("span");
                      if (spanElements.length) {
                        const pages = [];
                        for (var i = 0; i < spanElements.length; i++) {
                          let element = spanElements[i];
                          if (
                            parseFloat(element.style.left) <= 10 &&
                            element.innerText.length < 3 &&
                            !isNaN(parseFloat(element.innerText))
                          ) {
                            element.style.background = "black";
                            element.style.cursor = "pointer";
                            element.onclick = () => {
                              const questionNumber = Number(element.innerText);
                              setTabPages((tabPages) => [
                                tabPages[0],
                                answerPages.length === 0
                                  ? 0
                                  : answerPages.length < questionNumber - 1
                                  ? answerPages[answerPages.length - 1]
                                  : answerPages[questionNumber - 1],
                              ]);
                              setActiveTab(1);
                            };
                            pages.push(findPage(element) || 0);
                          }
                        }
                        setQuestionPages(pages);
                      }

                      setTabPages((tabPages) => {
                        const newTabPages = [...tabPages];
                        newTabPages[0] = page.currentPage;
                        return newTabPages as [number, number];
                      });
                    }}
                    initialPage={tabPages[0]}
                  />
                </Worker>
              </div>
              <div
                style={{
                  overflow: `scroll`,
                  display: `${activeTab == 1 ? "inherit" : "none"}`,
                }}
              >
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                  <Viewer
                    plugins={[zoomPluginInstance]}
                    fileUrl={`https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=${selectedPaperData?.slug}&type=ms`}
                    onDocumentLoad={async (e) => {
                      if (!numPagesMarkscheme) {
                        setNumPagesMarkscheme(e.doc.numPages);
                        console.log(
                          `https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=${selectedPaperData?.slug}&type=ms`
                        );
                      }

                      setMarkschemeLoaded(true);
                    }}
                    onPageChange={(page) => {
                      var spanElements = document.getElementsByTagName("span");
                      if (spanElements.length) {
                        const pages = [];

                        for (var i = 0; i < spanElements.length; i++) {
                          let element = spanElements[i];
                          if (
                            parseInt(element.style.left) <= 13 &&
                            parseInt(element.style.left) >= 12 &&
                            ((element.innerText.length < 3 &&
                              !isNaN(parseFloat(element.innerText))) ||
                              (!isNaN(parseFloat(element.innerText)) &&
                                parseFloat(element.innerText) < 100) ||
                              /[0-9]{1,2}[(]a[)][(]i[)]/.test(
                                element.innerText
                              ))
                          ) {
                            element.style.background = "black";
                            element.style.cursor = "pointer";
                            element.onclick = () => {
                              setActiveTab(1);
                            };
                            pages.push(findPage(element) || 0);
                          }
                        }
                        setAnswerPages(pages);
                      }

                      setTabPages((tabPages) => {
                        const newTabPages = [...tabPages];
                        newTabPages[1] = page.currentPage;
                        return newTabPages as [number, number];
                      });
                    }}
                    initialPage={tabPages[1]}
                  />
                </Worker>
              </div>
              {/* <iframe
                src={`https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=${
                  selectedPaperData?.slug
                }&type=${activeTab == 0 ? "qp" : "ms"}`}
                height="100%"
              ></iframe> */}
              {/* {(activeTab === 0
                ? selectedQuestionImage
                : selectedQuestionAnswer
              ).map((image, index) =>
                selectedQuestionType === QuestionType.MCQ && activeTab === 1 ? (
                  <MCQOptions key={Math.random().toString(36)}>
                    {MCQ_OPTIONS.map((option) => (
                      <MCQOption
                        key={Math.random().toString(36)}
                        active={
                          selectedQuestionAnswer[0].toUpperCase() === option
                        }
                      >
                        {option}
                      </MCQOption>
                    ))}
                  </MCQOptions>
                ) : (
                  <div key={Math.random().toString(36)}>
                    <QuestionImage
                      src={
                        "https://qnpumhfafqmcymbxzuvc.functions.supabase.co/get-image?url=" +
                        image.replace("https://www.exam-mate.com/", "")
                      }
                    />
                  </div>
                )
              )} */}
            </QuestionContainer>
          </div>
        </Row>
      </Content>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow-y: ${({ sidebarVisible }: { sidebarVisible: boolean }) =>
    sidebarVisible ? "hidden" : "scroll"};
  padding-top: 7rem;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 5rem;
  gap: 2rem;
  height: calc(100% - 10rem);
`;

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  width: 30vw;
  max-height: calc(100% - 5rem);
  min-height: calc(100% - 5rem);
  overflow-y: scroll;
  background-color: #fff;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 59.375em) {
    max-width: 30vw;
    min-width: 30rem;
  }
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${({
    selected,
    completed,
  }: {
    selected?: boolean;
    completed?: boolean;
  }) => (selected ? colors.primary.hex : completed ? "#b5e48c" : "#fff")};
  padding: 1rem;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? "#fff" : colors.text.hex};
  cursor: pointer;
  transition: background-color 0.5s, filter 0.5s;

  &:hover {
    ${({ selected, completed }: { selected?: boolean; completed?: boolean }) =>
      selected
        ? "background-color: " + colors.primary.hex
        : completed
        ? "filter: brightness(0.8)"
        : "background-color: " + colors.white.hex};
  }
`;

const ListTitle = styled.h3`
  font-weight: 600;
  font-size: 1.6rem;
  transition: color 0.5s;
`;

const BubbleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const QuestionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  padding: 4rem;
  padding-top: 2.5rem;
  overflow-y: scroll;
  padding-bottom: 4rem;
`;

const PagesContainer = styled.div`
  position: relative;
  width: 30vw;
  min-height: 3rem;
  overflow-x: scroll;
  align-self: flex-start;
  /* position: fixed;
  bottom: 0;
  left: 0; */
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: flex-start;
  padding: 0 1rem;
  gap: 0.5rem;
  margin-left: 5rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  z-index: 10;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 59.375em) {
    align-self: center;
    margin-left: 0;
    margin-top: 0.5rem;
    min-width: 30rem;
  }
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: ${({ active }: { active?: boolean }) => (active ? 600 : 600)};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : "transparent"};
  border-radius: 0.5rem;
  min-width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${({ active }: { active?: boolean }) =>
      active ? colors.primary.hex : colors.white.hex};
  }
`;

const BackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  align-self: flex-start;
  margin-left: 5rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${colors.white.hex};

    & > img {
      opacity: 50%;
    }
  }

  @media only screen and (max-width: 59.375em) {
    display: none;
  }
`;

const MobileMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.5s, opacity 1s;
  margin: 0.5rem 0;
  z-index: 10;

  &:hover {
    background-color: ${colors.white.hex};

    & > img {
      opacity: 50%;
    }
  }

  @media only screen and (min-width: 59.375em) {
    display: none;
  }
`;

const BackIcon = styled.img.attrs({
  src: "/assets/icons/down-chevron.png",
})`
  width: 2rem;
  height: 2rem;
  transform: rotate(90deg);
  opacity: 70%;
  transition: opacity 0.5s;
`;

const CloseIcon = styled.img.attrs({
  src: "/assets/icons/close.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const MenuIcon = styled.img.attrs({
  src: "/assets/icons/menu.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 27.5em) {
    flex-direction: column;
  }
`;

const Tab = styled.div`
  padding: 1rem 2rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.text.hex : "transparent"};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 1rem;
`;

const NavRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const NewTabButton = styled.button`
  background-color: transparent;
  font-size: 1.5rem;
  width: fit-content;
  height: fit-content;
  margin-right: 5rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  color: ${colors.primary.hex};
  /* text-decoration: underline; */
  font-weight: 600;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 59.375em) {
    position: absolute;
    top: 0;
    left: 5rem;
    z-index: 10;
    height: calc(80vh - 1rem);
    opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
    transform: ${({ show }: { show: boolean }) =>
      show ? "translate(0)" : "translate(-100%)"};
    transition: opacity 1s, transform 1s;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  width: 100vw;
  height: 100vh;
  z-index: 9;
  display: ${({ show }: { show: boolean }) => (show ? "block" : "none")};
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 27.5em) {
    align-items: center;
  }
`;

const NoResults = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  background-color: ${colors.text.toRGBA(0.5)};
`;

const LoadingText = styled.h3`
  font-size: 1.6rem;
  color: ${colors.white.hex};
  font-weight: 600;
  text-align: center;
  margin-top: 9rem;
`;

const ResourcesButton = styled.div`
  position: fixed;
  top: 0;
  left: 15vw;
  height: 7.5rem;
  width: 7.5rem;
  background-color: #bebfc5;
  border-radius: 0 0 10rem 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transform: translateY(-1rem);
  transition: all 0.3s cubic-bezier(0.17, 0.67, 0.48, 1.4);

  &:hover {
    transform: translateY(-0.2rem);
  }
`;

const Resource = styled.div`
  width: 40%;
`;

const ResourceLabel = styled.a`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${colors.primary.hex};
  text-decoration: underline;
  cursor: pointer;
`;

const ResourceThumbnail = styled.img`
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  box-shadow: ${styles.boxShadow};
`;

const ResourcesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;
