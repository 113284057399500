import { PastPaperBoard } from "./years";

export const pastPaperSeasons: Record<
  PastPaperBoard,
  Record<string, string[]>
> = {
  "A Levels": {
    "9706": ["May/June", "Oct/Nov", "Feb/March"],
    "9700": ["May/June", "Oct/Nov", "Feb/March"],
    "9701": ["May/June", "Oct/Nov", "Feb/March"],
    "9702": ["May/June", "Oct/Nov", "Feb/March"],
    "9608": ["May/June", "Oct/Nov"],
    "9618": ["May/June", "Oct/Nov"],
    "9708": ["May/June", "Oct/Nov", "Feb/March"],
    "9231": ["May/June", "Oct/Nov"],
    "9709": ["May/June", "Oct/Nov", "Feb/March"],
    "9990": ["May/June", "Oct/Nov", "Feb/March"],
  },
  "IB Diploma": {},
  IGCSE: {},
  "Edexcel A Levels": {},
};
