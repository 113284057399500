import React from "react";
import { Routes, Route, Location } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { LandingPage } from "./pages/landing/landing.page";
import { Result } from "./pages/search/Result";
import { Topical } from "./pages/topical/Topical";
import { Results } from "./pages/topical/Results";
import { Builder } from "./pages/topical/builder/Builder";
import { MCQTestPage } from "./pages/mcq-test/mcq-test.page";
import { ResultsPage } from "./pages/mcq-test/results.page";
import { LoginPage } from "./pages/authentication/login.page";
import { SignupPage } from "./pages/authentication/signup.page";
import { ConfirmEmailPage } from "./pages/authentication/confirm-email";
import { NotFoundPage } from "./not-found.page";
import { Search } from "./pages/search/Search";
import { WithSidebar } from "./WithSidebar";
import { PastPapers } from "./pages/past-papers/PastPapers";
import { KeywordSearch } from "./pages/keyword/KeywordSearch";
import { KeywordResult } from "./pages/keyword/KeywordResult";
import { PastPaperResults } from "./pages/past-papers/PastPaperResults";
import { PdfPage } from "./pages/pdf/pdf.page";
import { DashboardPage } from "./pages/dashboard/home/home.page";

export const AppRoutes = ({ location }: { location: Location }) => {
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/dashboard" element={<DashboardPage />}></Route>
        <Route path="/papers/pdf/:slug/:type" element={<PdfPage />}></Route>
        <Route
          path="/search"
          element={
            <WithSidebar navbar>
              <Search />
            </WithSidebar>
          }
        ></Route>
        <Route path="/keyword" element={<KeywordSearch/>}/>
        <Route path="/keyword/results/:query" element={<KeywordResult/>}/>
        <Route
          path="/search/results/:board/:subject/:slug"
          element={<Result />}
        ></Route>
        <Route
          path="/papers"
          element={
            <WithSidebar navbar>
              <PastPapers />
            </WithSidebar>
          }
        ></Route>
        <Route
          path="/papers/results/:board/:subject"
          element={<PastPaperResults />}
        ></Route>
        <Route
          path="/topical"
          element={
            <WithSidebar navbar>
              <Topical />
            </WithSidebar>
          }
        ></Route>
        <Route
          path="/topical/:board/:subject/results"
          element={<Results />}
        ></Route>
        <Route
          path="/topical/:board/:subject/builder"
          element={<Builder />}
        ></Route>
        <Route path="/tests/:id" element={<MCQTestPage />}></Route>
        <Route path="/tests/:id/results" element={<ResultsPage />}></Route>
        <Route path="/auth/login" element={<LoginPage />}></Route>
        <Route path="/auth/signup" element={<SignupPage />}></Route>
        <Route
          path="/auth/signup/verify-email"
          element={<ConfirmEmailPage />}
        ></Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>
    </AnimatePresence>
  );
};
