import React from "react";
import styled from "styled-components";
import {
  IoMdBook as BookIcon,
  IoMdSearch as SearchIcon,
  IoIosPaper as PaperIcon,
  IoMdHome as HomeIcon,
} from "react-icons/io";

import { styles } from "../theme/styles";
import { colors } from "../theme/colors";
import { Color } from "../utils/Color";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ReduxState } from "../redux/store";

export const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(
    (state: ReduxState) => state.auth?.authenticated ?? false
  );

  const path = location.pathname;

  return (
    <Container>
      {isAuthenticated ? (
        <NavItem
          label="Dashboard"
          active={path === "/dashboard"}
          pill={false}
          onClick={() => navigate("/dashboard")}
        >
          <HomeIcon
            size={30}
            color={colors.white.toRGBA(path === "/learn" ? 0.7 : 0.5)}
          />
        </NavItem>
      ) : null}
      <NavItem
        label="Topical"
        active={path === "/topical"}
        onClick={() => navigate("/topical")}
      >
        <BookIcon
          size={30}
          color={colors.white.toRGBA(path === "/topical" ? 0.7 : 0.5)}
        />
      </NavItem>
      <NavItem
        label="Search"
        active={path === "/search"}
        onClick={() => navigate("/search")}
      >
        <SearchIcon
          size={30}
          color={colors.white.toRGBA(path === "/search" ? 0.7 : 0.5)}
        />
      </NavItem>
      <NavItem
        label="Papers"
        active={path === "/papers"}
        onClick={() => navigate("/papers")}
      >
        <PaperIcon
          size={30}
          color={colors.white.toRGBA(path === "/papers" ? 0.7 : 0.5)}
        />
      </NavItem>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  left: 0;
  top: 0;

  display: flex;
  flex-direction: column;

  width: fit-content;
  min-height: 100%;
  background-color: ${colors.text.hex};
  box-shadow: ${styles.boxShadow};

  @media only screen and (max-width: 46.875em) {
    display: none;
  }
`;

const NavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 6rem;
  height: 6rem;
  cursor: pointer;
  transition: all 0.5s;
  background-color: ${({ active }: { active?: boolean; pill?: boolean }) =>
    active ? colors.primary.hex : "transparent"};

  &:hover {
    background-color: ${({ active }: { active?: boolean; pill?: boolean }) =>
      active
        ? colors.primary.hex
        : Color.fromHex(colors.white.hex).toRGBA(0.1)};
  }

  &::after {
    content: "${({
      label,
    }: {
      label: string;
      active?: boolean;
      pill?: boolean;
    }) => label}";
    display: ${({
      active,
      pill = true,
    }: {
      active?: boolean;
      pill?: boolean;
    }) => (pill ? (active ? "block" : "none") : "none")};
    position: absolute;
    top: 3rem;
    left: 7rem;
    transform: translateY(-50%);
    padding: 0.5rem 1.5rem;
    background-color: ${colors.primary.hex};
    color: #fff;
    font-size: 1.2rem;
    border-radius: 2rem;
  }

  &:hover::before {
    content: "${({ label }: { label: string; active?: boolean }) => label}";
    position: absolute;
    top: 3rem;
    left: 7rem;
    transform: translateY(-50%);
    padding: 0.5rem 1.5rem;
    background-color: ${colors.white.hex};
    color: ${colors.text.hex};
    font-size: 1.2rem;
    border-radius: 2rem;
  }
`;
