import styled from "styled-components";
import { colors } from "../theme/colors";

export const Bubble = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  font-size: 1.4rem;
  background-color: ${colors.secondary.hex};
  color: #fff;
  width: fit-content;
  font-weight: 500;
`;
