import React, { useRef } from "react";
import styled from "styled-components";
import { colors } from "../theme/colors";
import { Row } from "../widgets/Row";

export const FileUpload = ({
  file,
  setFile,
  label,
}: {
  file: File | null;
  setFile: (file: File | null) => void;
  label: string;
}) => {
  const id = useRef<string>(Math.random().toString(36));

  return (
    <Container>
      <Label>{label}</Label>
      <Row gap={20} style={{ alignItems: "center" }}>
        <Button htmlFor={id.current}>Upload file</Button>
        <FileName>{file ? file.name : "No file selected"}</FileName>
      </Row>
      <input
        type="file"
        id={id.current}
        style={{ display: "none" }}
        accept="image/png,image/jpeg"
        onChange={(evt) => {
          const files = evt.target.files;
          if (files?.length === 0 || !files) return;

          const imageFile = files[0];

          setFile(imageFile);
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
`;

const FileName = styled.label`
  font-size: 1.3rem;
  font-weight: 500;
`;

const Button = styled.label`
  width: fit-content;
  background-color: ${colors.white.hex};
  padding: 1.5rem 2rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  font-weight: 500;
  outline: none;
  border: none;
  cursor: pointer;
`;
