import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// Only using Firebase for analytics
const firebaseConfig = {
  apiKey: "AIzaSyAGWheN55kdlkw317tCDqVMPVOQaTqayag",
  authDomain: "markhint-finder.firebaseapp.com",
  projectId: "markhint-finder",
  storageBucket: "markhint-finder.appspot.com",
  messagingSenderId: "569935556470",
  appId: "1:569935556470:web:a97b28201d169349071457",
  measurementId: "G-F35V0EMY0L",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
