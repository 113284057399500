import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Joyride from "react-joyride";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { motion } from "framer-motion";

import { Dropdown } from "./Dropdown";
import { TOPICS } from "../../data/topics";
import { colors } from "../../theme/colors";
import { styles } from "../../theme/styles";
import { PAPERS } from "../../data/papers";
import { YEARS } from "../../data/years";
import { supabase } from "../../api/supabase";
import { Subject } from "../../types/Subject";
import { Topic } from "../../types/Topic";
import { Board } from "../../types/Board";

import Alert from "../../components/Alert";
import { Toggle } from "../../components/Toggle";
import { TextInput } from "../../components/TextInput";
import { Spacer } from "../../components/Spacer";
import { Row } from "../../widgets/Row";
import { Bubble } from "../../widgets/Bubble";
import { ReduxState } from "../../redux/store";
import { Test } from "../../types/Test";
import { Loader } from "../../components/Loader";
import { Theme } from "../../redux/slices/theme.slice";
import { parseJson } from "../../utils/parseJson";
import { IoMdHelp } from "react-icons/io";

export const Topical = () => {
  const [board, setBoard] = useState<string[]>([]);

  const [subject, setSubject] = useState<string[]>([]);
  const [subjectCode, setSubjectCode] = useState("");

  const [paper, setPaper] = useState<string[]>([]);

  const [topics, setTopics] = useState<string[]>([]);

  const [years, setYears] = useState<string[]>([]);

  const [difficulty, setDifficulty] = useState<string[]>([]);

  const [sessions, setSessions] = useState<string[]>([]);

  const [variants, setVariants] = useState<string[]>([]);

  const [levels, setLevels] = useState<string[]>([]);

  const [unit, setUnit] = useState<string[]>([]);

  const [mcqAlert, setMCQAlert] = useState(false);

  const [boardsOptions, setBoardsOptions] = useState<string[]>([]);
  const [subjectsOptions, setSubjectsOptions] = useState<
    { name: string; code: string; papers: string[]; units: string[] }[]
  >([]);
  const [paperOptions, setPaperOptions] = useState<string[]>([]);
  const [topicOptions, setTopicOptions] = useState<
    { name: string; id: number; category?: string }[]
  >([]);

  const [mcqPapers, setMcqPapers] = useState<Record<string, string[]>>({});

  const [showModal, setShowModal] = useState(false);

  const [runTutorial, setRunTutorial] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Markhint | Topical Questions";
  }, []);

  useEffect(() => {
    (async function () {
      const { data: boards = [] } = await supabase.from("boards").select();

      setBoardsOptions(boards!.map((board: any) => (board as Board).name));
    })();
  }, []);

  useEffect(() => {
    const board = localStorage.getItem("board");

    if (!!board) setBoard([board]);

    const variants = parseJson(localStorage.getItem("variants"), []);

    if (variants.length) setVariants(variants);

    const years = parseJson(localStorage.getItem("years"), []);

    if (years.length) setYears(years);

    const difficulty = parseJson(localStorage.getItem("difficulty"), []);

    if (difficulty.length) setDifficulty(difficulty);

    const sessions = parseJson(localStorage.getItem("sessions"), []);

    if (sessions.length) setSessions(sessions);

    // const subject = localStorage.getItem("subject"),
    //   subjectCode = localStorage.getItem("subjectCode");

    // if (subject && subjectCode) {
    //   setSubject([subject]);
    //   setSubjectCode(subjectCode);
    // }

    // const paper = localStorage.getItem("paper");
    // if (paper) {
    //   setPaper(paper.split(";"));
    // }

    // const topics = localStorage.getItem("topics");
    // if (topics) {
    //   setTopics(topics.split(";"));
    // }

    // const years = localStorage.getItem("years");
    // if (years) {
    //   setYears(years.split(";"));
    // }
  }, []);

  useEffect(() => {
    (async function () {
      console.log({
        papers: paper.length ? paper : paperOptions,
        topic: topics.length
          ? topics
          : topicOptions.map((option) => option.name),
        subject_code: subjectCode,
        years: [2022],
        num_questions: 10,
      });

      // console.log(
      //   await supabase.rpc("generate_mcq_questions_list", {
      //     papers: [1],
      //     topic: topics.length
      //       ? topics
      //       : topicOptions.map((option) => option.name),
      //     subject_code: subjectCode,
      //     years: [2022],
      //     num_questions: 10,
      //   })
      // );
    })();
  }, [subjectCode, board, subject, topics, paper, topicOptions, paperOptions]);

  useEffect(() => {
    if (board[0]) localStorage.setItem("board", board[0]);
  }, [board]);

  useEffect(() => {
    if (subject[0] && subjectCode) {
      localStorage.setItem("subject", subject[0]);
      localStorage.setItem("subjectCode", subjectCode);
    }
  }, [subject, subjectCode]);

  useEffect(() => {
    if (paper.length) localStorage.setItem("paper", JSON.stringify(paper));
  }, [paper]);

  useEffect(() => {
    if (variants.length)
      localStorage.setItem("variants", JSON.stringify(variants));
  }, [variants]);

  useEffect(() => {
    if (topics.length) {
      localStorage.setItem("topics", JSON.stringify(topics));
      localStorage.setItem("topicsSubject", subjectCode);
    }
  }, [subjectCode, topics]);

  useEffect(() => {
    if (years.length) localStorage.setItem("years", JSON.stringify(years));
  }, [years]);

  useEffect(() => {
    if (difficulty.length)
      localStorage.setItem("difficulty", JSON.stringify(difficulty));
  }, [difficulty]);

  useEffect(() => {
    if (sessions.length)
      localStorage.setItem("sessions", JSON.stringify(sessions));
  }, [sessions]);

  useEffect(() => {
    setTopics([]);
    setPaper([]);
  }, [board, subject]);

  useEffect(() => {
    setSubject([]);
  }, [board]);

  useEffect(() => {
    (async function () {
      if (board.length) {
        const { data: subjects = [] } = await supabase
          .from("subjects")
          .select()
          .filter("board", "eq", board[0])
          .order("name");

        setSubjectsOptions(
          subjects!.map((subject: any) => ({
            name: (subject as Subject).name,
            code: (subject as Subject).code,
            papers: (subject as Subject).papers,
            units: (subject as Subject)?.units || [],
          }))
        );
        const storedSubject = localStorage.getItem("subject"),
          storedSubjectCode = localStorage.getItem("subjectCode");
        if (storedSubject) setSubject([storedSubject]);
        if (storedSubjectCode) setSubjectCode(storedSubjectCode);

        setMcqPapers(
          (subjects as Subject[])!.reduce(
            (acc: Record<string, string[]>, subject: Subject) => {
              return {
                ...acc,
                [subject.code]: subject.mcq_papers,
              };
            },
            {} as Record<string, string[]>
          )
        );
      }
    })();
  }, [board]);

  useEffect(() => {
    (async function () {
      if (subject.length) {
        const option = subjectsOptions.find((i) => i.name === subject[0])!;
        setPaperOptions(option?.units?.length ? option.units : option.papers);

        const storedPapers = parseJson(localStorage.getItem("paper"), []);
        if (storedPapers.length) setPaper(storedPapers);

        setSubjectCode(option.code);

        const { data: topics = [] } = await supabase
          .from("topics")
          .select()
          .filter("subject", "eq", option.code)
          .order("topic_number");

        setTopics([]);
        setTopicOptions(
          topics!.map((topic: any) => ({
            name: (topic as Topic).name,
            id: (topic as Topic).id,
            category: (topic as Topic).category,
          }))
        );

        const storedSubjectCode = localStorage.getItem("topicsSubject");

        if (storedSubjectCode === option.code) {
          const storedTopics = parseJson(localStorage.getItem("topics"), []);
          if (storedTopics.length) setTopics(storedTopics);
        }
      }
    })();
  }, [subject, subjectsOptions]);

  // useEffect(() => {
  //   const topicIds: string[] = [];

  //   topics.forEach((topic) => {
  //     const option = topicOptions.find((option) => option.name === topic)!;
  //     topicIds.push(String(option.id));
  //   });

  //   setTopicIds(topicIds);
  // }, [topics]);

  const clearFilters = useCallback(() => {
    setBoard([]);
    setSubject([]);
    setSubjectCode("");
    setPaper([]);
    setTopics([]);
    setYears([]);
    setVariants([]);
    setDifficulty([]);
    setSessions([]);
    localStorage.removeItem("board");
    localStorage.removeItem("subject");
    localStorage.removeItem("subjectCode");
    localStorage.removeItem("paper");
    localStorage.removeItem("topics");
    localStorage.removeItem("years");
    localStorage.removeItem("variants");
    localStorage.removeItem("difficulty");
    localStorage.removeItem("sessions");
  }, []);

  const topicOptionsArr = (
    topicOptions.length
      ? topicOptions.map((option) => ({
          label: option.name,
          value: option.name,
          category: option.category,
        }))
      : board[0] in TOPICS && subject[0] in TOPICS[board[0]]
      ? TOPICS[board[0]][subject[0]]
          .map((topic) => topic.name)
          .sort()
          .map((topic) => ({
            label: topic,
            value: topic,
            category: null,
          }))
      : []
  ).sort((a, b) =>
    a.category! < b.category! ? -1 : a.category! > b.category! ? 1 : 0
  );

  const usedCategories: Record<string, boolean> = {};

  for (let i = 0; i < topicOptionsArr.length; i++) {
    if (
      topicOptionsArr[i].category &&
      !usedCategories[topicOptionsArr[i].category!]
    ) {
      usedCategories[topicOptionsArr[i].category!] = true;
    } else {
      topicOptionsArr[i].category = null;
    }
  }

  const generateMcqTest = useCallback(() => {
    if (!board.length || !subject.length) return;

    let selectedPapers = paper.map((el) => el.split(" (")[0]);

    if (selectedPapers.length) {
      if (
        selectedPapers.filter((el) => !mcqPapers[subjectCode].includes(el))
          .length
      ) {
        return setMCQAlert(true);
      }
    } else {
      selectedPapers = mcqPapers[subjectCode];
    }

    setShowModal(true);

    // navigate(
    //   `/test/${board[0]
    //     .toLowerCase()
    //     .replace(
    //       / /g,
    //       "-"
    //     )}/${subjectCode}/results?papers=${selectedPapers.join(
    //     ";"
    //   )}&topics=${topics
    //     .map((topic) => encodeURIComponent(topic))
    //     .join(";")}&years=${years.join(";")}&page=0`
    // );
  }, [mcqPapers, board, subject, paper, subjectCode]);

  return (
    <Main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* <Navbar /> */}
      <Joyride
        steps={[
          {
            target: "#topical-title",
            content: "Use this feature to filter through past paper questions.",
          },
          { target: "#board-dropdown", content: "Select a board." },
          { target: "#subject-dropdown", content: "Select a subject." },
          { target: "#topics-dropdown", content: "Select a topic." },
          {
            target: "#get-questions-button",
            content: "Click to get questions.",
          },
          {
            target: "#create-test-button",
            content: "Or, click to create a self-marked online MCQ test.",
          },
        ]}
        run={runTutorial}
        continuous
        hideCloseButton
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            zIndex: 10,
          },
        }}
        disableOverlayClose
      />
      <HelpIconContainer
        onClick={() => {
          setRunTutorial(false);
          setRunTutorial(true);
        }}
      >
        <IoMdHelp size={25} color="#fff" />
      </HelpIconContainer>
      <TestModal
        subject={subject[0]}
        show={showModal}
        setShow={setShowModal}
        papers={paper}
        board={board}
        years={years}
        topics={topics}
        subjectCode={subjectCode}
        mcqPapers={mcqPapers}
        setShowAlert={setMCQAlert}
      />

      <Content>
        {mcqAlert && (
          <Alert
            message="Please select MCQ papers only"
            isShow={mcqAlert}
            setIsShow={setMCQAlert}
          />
        )}
        <Heading id="topical-title">Topical Questions</Heading>
        <Dropdowns>
          <Dropdown
            id="board-dropdown"
            label="Board"
            values={board}
            onChange={setBoard}
            options={
              boardsOptions.length
                ? boardsOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))
                : [
                    { label: "IGCSE", value: "IGCSE" },
                    { label: "A Levels", value: "A Levels" },
                    { label: "IB Diploma", value: "IB Diploma" },
                  ]
            }
            disabled={!boardsOptions.length}
            // tutorialActive={tutorialIndex === 1}
          />
          <Dropdown
            id="subject-dropdown"
            label="Subject"
            values={subject}
            onChange={setSubject}
            options={
              subjectsOptions.length
                ? subjectsOptions.map((option) => ({
                    label: option.name,
                    value: option.name,
                  }))
                : board[0] in TOPICS
                ? Object.keys(TOPICS[board[0]])
                    .sort()
                    .map((subject) => ({
                      label: subject,
                      value: subject,
                    }))
                : []
            }
            disabled={!board.length || !subjectsOptions.length}
          />
          <Dropdown
            id="paper-dropdown"
            label={board[0] === "Edexcel A Levels" ? "Unit" : "Paper"}
            values={board[0] === "Edexcel A Levels" ? unit : paper}
            onChange={board[0] === "Edexcel A Levels" ? setUnit : setPaper}
            options={
              paperOptions.length
                ? paperOptions.map((option) => ({
                    label: option,
                    value: option,
                  }))
                : board[0] in PAPERS && subject[0] in PAPERS[board[0]]
                ? PAPERS[board[0]][subject[0]].map((paper) => ({
                    value: paper,
                    label: paper,
                  }))
                : []
            }
            multi
            disabled={!board.length || !subject.length || !paperOptions.length}
          />
          <Dropdown
            id="variant-dropdown"
            label="Variant"
            values={variants}
            onChange={setVariants}
            options={[
              { value: "1", label: "1" },
              { value: "2", label: "2" },
              { value: "3", label: "3" },
            ]}
            multi
            disabled={!board.length || !subject.length || !paperOptions.length}
          />
          {board[0] === "IB Diploma" && (
            <Dropdown
              id="level-dropdown"
              label="Level"
              values={levels}
              onChange={setLevels}
              options={[
                { value: "SL", label: "SL" },
                { value: "HL", label: "HL" },
              ]}
              multi
              disabled={
                !board.length || !subject.length || !paperOptions.length
              }
            />
          )}
          <Dropdown
            id="topics-dropdown"
            label="Topics"
            values={topics}
            onChange={setTopics}
            options={topicOptionsArr}
            multi
            disabled={!board.length || !subject.length || !topicOptions.length}
          />
          <Dropdown
            id="session-dropdown"
            label="Session"
            values={sessions}
            onChange={setSessions}
            options={[
              { value: "May/June", label: "May/June" },
              { value: "Oct/Nov", label: "Oct/Nov" },
            ]}
            multi
            disabled={!board.length || !subject.length}
          />
          <Dropdown
            id="years-dropdown"
            label="Years"
            values={years}
            onChange={setYears}
            options={
              board[0] in YEARS && subject[0] in YEARS[board[0]]
                ? YEARS[board[0]][subject[0]].map((year) => ({
                    value: year,
                    label: year,
                  }))
                : []
            }
            multi
            disabled={!board.length || !subject.length}
          />
          <Dropdown
            id="difficulty-dropdown"
            label="Difficulty"
            values={difficulty}
            onChange={setDifficulty}
            multi
            options={[
              { label: "Easy", value: "1" },
              { label: "Medium-easy", value: "2" },
              { label: "Medium", value: "3" },
              { label: "Medium-high", value: "4" },
              { label: "High", value: "5" },
            ]}
            disabled={!board.length || !subject.length}
          />
          {/* <Dropdown
          title={"Subject"}
          options={getSubjects(Board[0])}
          multi={false}
          selected={Subject}
          update={setSubject}
        />
        <Dropdown
          title={"Paper"}
          options={["Physics", "Chemistry", "Math"]}
          multi={true}
          selected={Paper}
          update={setPaper}
        />
        <Dropdown
          title={"Topics"}
          options={["Physics", "Chemistry", "Math"]}
          multi={true}
          selected={Topics}
          update={setTopics}
        />
        <Dropdown
          title={"Years"}
          options={["Physics", "Chemistry", "Math"]}
          multi={true}
          selected={Years}
          update={setYears}
        /> */}
        </Dropdowns>
        <ClearFilters onClick={clearFilters}>Clear Filters</ClearFilters>
        <BtnContainer>
          <SearchButton
            id="create-test-button"
            negative
            active={!!board.length && !!subject.length}
            onClick={generateMcqTest}
          >
            Create Test
          </SearchButton>
          <SearchButton
            id="get-questions-button"
            active={!!board.length && !!subject.length}
            onClick={() =>
              navigate(
                `/topical/${board[0]
                  .toLowerCase()
                  .replace(
                    / /g,
                    "-"
                  )}/${subjectCode}/results?papers=${paper.join(
                  ";"
                )}&topics=${topics
                  .map((topic) => encodeURIComponent(topic))
                  .join(";")}&years=${years.join(";")}&sessions=${sessions.join(
                  ";"
                )}&variants=${variants.join(";")}&levels=${levels.join(
                  ";"
                )}&units=${unit.join(";")}&difficulty=${difficulty.join(
                  ";"
                )}&page=0`
              )
            }
          >
            Get Questions
          </SearchButton>
        </BtnContainer>
      </Content>
    </Main>
  );
};

const TestModal = ({
  subject,
  show,
  setShow,
  papers,
  board,
  subjectCode,
  years,
  topics,
  mcqPapers,
  setShowAlert,
}: {
  subject: string;
  show: boolean;
  setShow: (show: boolean) => void;
  papers: string[];
  board: string[];
  subjectCode: string;
  years: string[];
  topics: string[];
  mcqPapers: Record<string, string[]>;
  setShowAlert: (show: boolean) => void;
}) => {
  const [testName, setTestName] = useState("");
  const [totalMarks, setTotalMarks] = useState(0);
  const [duration, setDuration] = useState(0);
  const [saveResults, setSaveResults] = useState(true);
  const [emailResults, setEmailResults] = useState(true);
  const [timed, setTimed] = useState(true);

  const [loading, setLoading] = useState(false);

  const authenticated = useSelector(
    (state: ReduxState) => state.auth.authenticated
  );
  const user = useSelector((state: ReduxState) => state.auth.user);

  const navigate = useNavigate();

  const onKeyDown = useCallback((evt: KeyboardEvent) => {
    if (evt.key == "Escape") setShow(false);
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  const getQuestions = useCallback(
    async (papers: string[]) => {
      let query = supabase
        .from("questions")
        .select("id", { count: "exact" })
        .eq("board", board[0])
        .eq("subject", subjectCode);

      if (papers.length) {
        query = query.in(
          "paper_number",
          papers.map((paper) => paper.split("(")[0])
        ); // Add paper numbers as a filter if they have been selected
      }
      if (topics.length) {
        // Add topics as a filter if they have been selected
        let or: string[] = [];
        topics.forEach((topic) => {
          or.push(`topics.cs.{${topic.replace(",", " ")}}`);
        });
        query = query.or(or.join(","));
      }
      if (years.length) {
        // Add years as a filter if they have been selected
        query = query.in(
          "year",
          years.map((year) => Number(year))
        );
      }

      // Sort the query in descending order of year
      query = query
        .order("year", { ascending: false })
        .order("season", { ascending: false })
        .order("question_number", { ascending: true })
        .limit(totalMarks);

      const { count } = await query;
      const offset = Math.floor((Math.random() * count!) / (totalMarks - 1));

      const { data } = await query.range(
        offset * (totalMarks - 1),
        (offset + 1) * (totalMarks - 1)
      );

      return data!.map((question) => Number(question.id));
    },
    [board, subjectCode, years, topics, totalMarks]
  );

  return (
    <Overlay show={show}>
      <Modal>
        <TopRow>
          <ModalTitle>{testName || "Untitled test"}</ModalTitle>
          <CloseButton
            src="/assets/icons/close.svg"
            onClick={() => {
              setShow(false);
            }}
          />
        </TopRow>
        <Row gap={5}>
          <Bubble>{subject}</Bubble>
          <Bubble>{totalMarks} marks</Bubble>
          <Bubble>{duration ? duration + " minutes" : "Untimed"}</Bubble>
          <Bubble>
            {papers.length
              ? "Paper" + (papers.length > 1 ? "s " : " ") + papers.join(", ")
              : "All papers"}
          </Bubble>
        </Row>
        <Spacer size={10} direction="bottom" />
        <FormContainer>
          <TextInput
            label="Test Name"
            placeholder="Enter test name"
            value={testName}
            onChange={setTestName}
          />
          <TextInput
            type="number"
            label="Total Marks"
            placeholder="Enter total marks"
            value={totalMarks}
            onChange={setTotalMarks}
          />
          {timed && (
            <Row style={{ width: "100%", justifyContent: "space-between" }}>
              <TextInput
                type="number"
                label="Duration (minutes)"
                placeholder="Enter duration"
                value={duration}
                onChange={setDuration}
                style={{ flex: 2 }}
              />
            </Row>
          )}
          {authenticated && (
            <Row gap={20}>
              <Toggle
                active={saveResults}
                onToggle={() => setSaveResults((saveResults) => !saveResults)}
                onLabel="Save results"
                offLabel="Don't save results"
              />
              <Toggle
                active={emailResults}
                onToggle={() =>
                  setEmailResults((emailResults) => !emailResults)
                }
                onLabel="Email results"
                offLabel="Don't email results"
              />
            </Row>
          )}
          <Toggle
            active={timed}
            onToggle={() => {
              setTimed((timed) => !timed);
              setDuration(0);
            }}
            onLabel="Timed"
            offLabel="Untimed"
          />
          <SearchButton
            active={!!totalMarks && (!!duration || !timed)}
            style={{ marginTop: "2rem" }}
            onClick={async () => {
              try {
                setLoading(true);
                let selectedPapers = papers.map((el) => el.split(" (")[0]);

                if (selectedPapers.length) {
                  if (
                    selectedPapers.filter(
                      (el) => !mcqPapers[subjectCode].includes(el)
                    ).length
                  ) {
                    return setShowAlert(true);
                  }
                } else {
                  selectedPapers = mcqPapers[subjectCode];
                }

                const questions = await getQuestions(selectedPapers);

                const id = uuidv4();

                const responses = questions.reduce(
                  (acc, b) => ({ ...acc, [b]: null }),
                  {}
                );

                const test: Partial<Test> = {
                  id,
                  created_at: new Date(),
                  name: testName || "Untitled test",
                  total_marks: totalMarks,
                  duration: duration,
                  completed: false,
                  questions: questions,
                  responses,
                  board: board[0],
                  subject: subjectCode,
                };

                if (user?.id) {
                  test.user_id = user?.id;
                  test.guest_user = false;
                } else test.guest_user = true;

                await supabase.from("tests").insert(test);
                navigate(`/tests/${id}`);
              } catch (e) {
                alert("There was an error!");
              } finally {
                setLoading(false);
              }
            }}
          >
            Create Test
            {loading && <Loader />}
          </SearchButton>
        </FormContainer>
      </Modal>
    </Overlay>
  );
};

const BtnContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const Main = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Heading = styled.div`
  font-size: 7rem;
  margin-bottom: 6rem;
  font-weight: 500;
  text-align: center;

  @media only screen and (max-width: 46.875em) {
    font-size: 6rem;
  }

  @media only screen and (max-width: 35em) {
    font-size: 5rem;
    margin-bottom: 2rem;
  }
`;

const Dropdowns = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 75vw;
  justify-content: center;
  gap: 3rem;
  @media only screen and (max-width: 700px) {
    gap: 1.5rem;
  }
`;

const Content = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SearchButton = styled.button`
  padding: 1.5rem 2rem;
  background-color: ${({
    negative,
  }: {
    active?: boolean;
    negative?: boolean;
  }) => (negative ? "#fff" : colors.primary.hex)};
  border-radius: 0.75rem;
  font-size: 1.6rem;
  color: ${({ negative }: { negative?: boolean }) =>
    negative ? colors.primary.hex : "#fff"};
  font-weight: 600;
  margin-top: 5rem;
  transition: all 0.5s;
  opacity: ${({ active }: { active?: boolean }) => (active ? 1 : 0.7)};
  cursor: ${({ active }: { active?: boolean }) =>
    active ? "pointer" : "not-allowed"};
  outline: none;
  border: none;

  &:hover {
    transform: ${({
      active,
      negative,
    }: {
      active?: boolean;
      negative?: boolean;
    }) => (active && !negative ? "translateY(-10%)" : "none")};
    box-shadow: ${({
      active,
      negative,
    }: {
      active?: boolean;
      negative?: boolean;
    }) => (active && !negative ? styles.boxShadow : "none")};
    filter: brightness(1.05);
  }

  &:active {
    transform: ${({ active }: { active?: boolean }) =>
      active ? "translateY(-5%)" : "none"};
    transition: all 0.2;
  }
`;

const ClearFilters = styled.h3`
  font-size: 1.5rem;
  color: ${colors.primary.hex};
  font-weight: 600;
  margin-top: 1rem;
  cursor: pointer;
  opacity: 0.75;
`;

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: ${({ show = false }: { show?: boolean }) =>
    show ? "flex" : "none"};
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  z-index: 5;
  background-color: #1d1d1da8;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  padding: 3rem;
  min-width: 45vw;
  max-width: 45vw;
  max-height: 70vh;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: ${styles.boxShadow};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.text.hex};
  font-weight: 600;
`;

const CloseButton = styled.img`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const HelpIconContainer = styled.div`
  cursor: pointer;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  background-color: ${colors.primary.hex};
  padding: 1rem;
  border-radius: 50%;
`;
