import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Session } from "@supabase/supabase-js";

import { ReactChildren } from "./types/ReactChildren";
import { supabase } from "./api/supabase";
import {
  setAuthenticatedStatus,
  setSignupCompletedStatus,
  setUser,
  setUserDetails,
} from "./redux/slices/auth.slice";
import { UserDetails } from "./types/UserDetails";
import { useNavigate } from "react-router-dom";
import { Loader } from "./components/Loader";

export const AuthenticationManager = ({
  children,
}: {
  children: ReactChildren;
}) => {
  const [session, setSession] = useState<Session | null>(null);
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      setSession(session);
    })();
  }, []);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    (async function () {
      if (session) {
        dispatch(setAuthenticatedStatus(true));
        dispatch(setUser(session.user));

        const { data: userData, error } = await supabase
          .from("users")
          .select("*")
          .eq("id", session.user.id);

        if (userData) {
          dispatch(setUserDetails(userData[0] as any as UserDetails));
          dispatch(setSignupCompletedStatus(true));
        } else if (!error) {
          dispatch(setSignupCompletedStatus(false));
          navigate("/auth/signup/details", { replace: true });
        }
      } else {
        dispatch(setAuthenticatedStatus(false));
        dispatch(setUser(null));
        dispatch(setUserDetails(null));
        dispatch(setSignupCompletedStatus(false));
      }

      setLoaded(true);
    })();
  }, [session, dispatch, navigate]);

  return <>{loaded ? children : <Loader />}</>;
};
