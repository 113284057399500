import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { Sidebar } from "../../../components/sidebar.component";
import { styles, successSnackbarStyles } from "../../../theme/styles";
import { colors } from "../../../theme/colors";
import { Spacer } from "../../../components/Spacer";
import { Tasks } from "./components/tasks.component";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../redux/store";
import { capitalize } from "../../../utils/capitalize";
import { Board } from "../../../types/Board";
import { supabase } from "../../../api/supabase";
import { Modal } from "../../../components/Modal";
import { setUserDetails } from "../../../redux/slices/auth.slice";
import { TextInput } from "../../../components/TextInput";
import { Task } from "../../../types/Task";
import { Navbar } from "../../../components/Navbar";
import { formatDateTime } from "../../../utils/formatDateTime";
import { useNavigate } from "react-router-dom";

export const DashboardPage = () => {
  const [openSuccessSnackbar] = useSnackbar(successSnackbarStyles);

  const navigate = useNavigate();

  const userDetails = useSelector(
    (reduxState: ReduxState) => reduxState.auth.userDetails
  );
  const authenticated = useSelector(
    (state: ReduxState) => state.auth?.authenticated
  );
  const dispatch = useDispatch();

  const [newBoard, setNewBoard] = useState("");

  const [boards, setBoards] = useState<Board[]>([]);

  const [tasks, setTasks] = useState<Task[]>([]);

  useEffect(() => {
    (async function () {
      const { data: boards } = await supabase.from("boards").select("name");
      setBoards((boards as Board[]) || []);
    })();
  }, []);

  useEffect(() => {
    if (!authenticated) navigate("/topical");
  }, [authenticated]);

  useEffect(() => {
    (async function () {
      if (!userDetails) return;

      const todayDate = new Date();
      todayDate.setHours(0);
      todayDate.setMinutes(0);
      todayDate.setSeconds(0);
      todayDate.setMilliseconds(0);

      const { data: rawTasks } = await supabase
        .from("tasks")
        .select("*")
        .eq("user", userDetails.id)
        .gte("start_time", formatDateTime(todayDate));

      const tasks = (rawTasks || []).map((task) => ({
        ...task,
        start_time: new Date(task.start_time),
        end_time: new Date(task.end_time),
      })) as Task[];

      const nonCompleteTasks = tasks.filter((task) => !task.complete);
      const completeTasks = tasks.filter((task) => task.complete);

      setTasks([
        ...nonCompleteTasks.sort((a, b) => +a.start_time! - +b.start_time!),
        ...completeTasks.sort((a, b) => +a.start_time! - +b.start_time!),
      ]);
    })();
  }, [userDetails]);

  const nPendingTasks = tasks.filter((task) => !task.complete).length;

  return (
    <Container>
      <Modal
        title="Select a Board"
        show={authenticated && !!userDetails && !userDetails.default_board}
        setShow={() => null}
        actions={[
          {
            label: "Save",
            onClick: async () => {
              if (!userDetails) return;
              if (newBoard === "") return alert("Select a board");

              await supabase
                .from("users")
                .update({ default_board: newBoard })
                .eq("id", userDetails.id);
              openSuccessSnackbar("Default board updated!");
              dispatch(
                setUserDetails({ ...userDetails, default_board: newBoard })
              );
            },
          },
        ]}
      >
        <TextInput
          type="dropdown"
          label="Board"
          placeholder="Select Board"
          value={newBoard}
          onChange={setNewBoard}
          options={boards.map((board) => ({
            label: board.name,
            value: board.name,
          }))}
        />
      </Modal>
      <Sidebar />
      <Navbar />
      <Content>
        <Title>
          Hello, {capitalize((userDetails?.name ?? "User").split(" ")[0])}.
        </Title>
        <Paragraph>
          {tasks.length === 0
            ? "You have no tasks scheduled for today. Start by adding a new task."
            : nPendingTasks > 0
            ? `You have ${nPendingTasks} pending task${
                nPendingTasks > 1 ? "s" : ""
              } today. Get to work!`
            : "You have completed all of today's tasks. Good job!"}
        </Paragraph>
        <Spacer direction="bottom" size={20} />
        <Tasks
          tasks={tasks}
          onTaskCreated={(task: Task) => setTasks((tasks) => [...tasks, task])}
          onTaskDeleted={(id: string) =>
            setTasks((tasks) => tasks.filter((task) => task.id !== id))
          }
          completeTask={async (id) => {
            const newTasks = tasks.map((task) =>
              task.id === id ? { ...task, complete: !task.complete } : task
            );

            setTasks(newTasks);

            await supabase
              .from("tasks")
              .update({
                complete: newTasks.find((task) => task.id === id)!.complete,
              })
              .eq("id", id);
          }}
        />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 4rem 10rem;

  width: 100vw;
  height: 100vh;
`;

const Content = styled.div`
  height: 100%;
  flex: 1;
`;

const Card = styled.div`
  padding: 2rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  width: fit-content;
`;

const Title = styled.h1`
  font-size: 5rem;
  color: ${colors.text.hex};
  font-weight: 500;
`;

const Paragraph = styled.p`
  font-size: 2rem;
  color: ${colors.text.hex};
  font-weight: 400;
`;
