import React from "react";
import styled from "styled-components";
import { sluggify } from "../utils/sluggify";
import { Spacer } from "./Spacer";

export const TextInput = ({
  type = "text",
  label,
  placeholder,
  value,
  onChange,
  style = {},
  options,
}: {
  label: string;
  placeholder: string;
  value: string | number;
  onChange:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<number>>
    | ((value: any) => void);
  type?: React.HTMLInputTypeAttribute | "dropdown";
  style?: React.CSSProperties;
  options?: { value: string | number; label: string }[];
}) => {
  const id = sluggify(label, {
    suffix: String(Math.random().toString(36).split(".")[1]),
  });

  return (
    <Container style={style}>
      <Label htmlFor={id}>{label}</Label>
      {type === "dropdown" ? (
        <Select
          defaultValue=""
          placeholder={placeholder}
          onChange={(evt) => onChange(evt.target.value)}
        >
          <option value="" disabled>
            {placeholder}
          </option>
          {options?.map((option) => (
            <option value={option.value}>{option.label}</option>
          ))}
        </Select>
      ) : (
        <Input
          id={id}
          {...{ type, placeholder, value }}
          onChange={(evt) =>
            onChange(
              (type === "number"
                ? Number(evt.target.value)
                : evt.target.value) as any
            )
          }
        />
      )}
      <Spacer size={15} direction="bottom" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  font-family: Lato, "Be Vietnam", "Noto Sans", "Core Sans", sans-serif;
  font-size: 1.6rem;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 1rem;
  outline: none;
`;

const Select = styled.select`
  font-family: Lato, "Be Vietnam", "Noto Sans", "Core Sans", sans-serif;
  font-size: 1.6rem;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ddd;
  padding: 0.5rem 1rem;
  outline: none;
`;

const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
`;
