import { interpolate } from "./interpolate";

export class Color {
  constructor(
    private r: number,
    private g: number,
    private b: number,
    private a = 1
  ) {}

  get red() {
    return this.r;
  }
  get green() {
    return this.g;
  }
  get blue() {
    return this.b;
  }
  get opacity() {
    return this.a;
  }

  static interpolate(start: Color, end: Color, progress: number) {
    return new Color(
      interpolate(start.r, end.r, progress),
      interpolate(start.g, end.g, progress),
      interpolate(start.b, end.b, progress),
      start.a && end.a ? interpolate(start.a, end.a, progress) : 1
    );
  }

  static fromHex(hex: string) {
    const formattedHex = hex.replace("#", "");

    const [r, g, b] = [
      parseInt(formattedHex.slice(0, 2), 16),
      parseInt(formattedHex.slice(2, 4), 16),
      parseInt(formattedHex.slice(4, 6), 16),
    ];

    return new Color(+r, +g, +b);
  }

  withOpacity(opacity: number) {
    return new Color(this.r, this.g, this.b, opacity);
  }

  toRGBA(a = 1) {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${a})`;
  }

  get hex() {
    return `#${this.r.toString(16).padStart(2, "0")}${this.g
      .toString(16)
      .padStart(2, "0")}${this.b.toString(16).padStart(2, "0")}${Math.floor(
      this.a * 255
    )
      .toString(16)
      .padStart(2, "0")}`;
  }

  toString() {
    return this.hex;
  }
}
