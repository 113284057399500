import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";

import { styles } from "../../theme/styles";
import { Row } from "../../widgets/Row";
import { Column } from "../../widgets/Column";
import { Spacer } from "../../components/Spacer";
import { TextInput } from "../../components/TextInput";
import { supabase } from "../../api/supabase";
import { colors } from "../../theme/colors";
import { Link, useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";
import { ReduxState } from "../../redux/store";

export const SignupPage = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const [openErrorSnackbar] = useSnackbar(errorSnackbarStyles);

  const navigate = useNavigate();

  const authenticated = useSelector(
    (state: ReduxState) => state.auth.authenticated
  );

  const signup = useCallback(async () => {
    if (password !== confirmPassword)
      return openErrorSnackbar("The two passwords do not match!");
    if (password.length < 6)
      return openErrorSnackbar(
        "Password must have a minimum of six characters!"
      );

    setLoading(true);

    try {
      const { data: user, error } = await supabase.auth.signUp({
        email,
        password,
        options: {
          data: {
            name,
          },
        },
      });

      if (error || !user || !user.user)
        return openErrorSnackbar("There was an error!");

      const { error: databaseError } = await supabase.from("users").insert({
        id: user.user.id,
        email_confirmed: false,
        email,
        phone: null,
        name,
      });

      if (databaseError)
        return openErrorSnackbar("There was an error creating an account!");

      navigate(`/auth/signup/verify-email?email=${email}`, { replace: true });
    } catch (e) {
      return openErrorSnackbar("There was an error!");
    } finally {
      setLoading(false);
    }
  }, [name, email, password, confirmPassword, navigate, openErrorSnackbar]);

  useEffect(() => {
    if (authenticated) navigate("/topical", { replace: true });
  }, [authenticated, navigate]);

  return (
    <Container>
      {loading && <Loader />}
      <Logo src="/assets/images/logo.svg" />
      <Spacer size={50} direction="bottom" />
      <LoginContainer>
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <Column>
            <Title>Get Started</Title>
            <Paragraph>
              Get started with Markhint: the first step to academic excellence.
            </Paragraph>
          </Column>
        </Row>
        <Spacer size={20} direction="bottom" />
        <TextInput
          label="Name"
          placeholder=""
          value={name}
          onChange={setName}
        />
        <TextInput
          label="Email Address"
          placeholder=""
          value={email}
          onChange={setEmail}
        />
        <TextInput
          type="password"
          label="Password"
          placeholder="Set a password"
          value={password}
          onChange={setPassword}
        />
        <TextInput
          type="password"
          label="Confirm Password"
          placeholder="Re-enter your password"
          value={confirmPassword}
          onChange={setConfirmPassword}
        />
        <Spacer size={20} direction="bottom" />
        <LoginButton
          disabled={!email || !password || password.length < 8}
          onClick={signup}
        >
          Get Started
        </LoginButton>
        <Spacer size={10} direction="bottom" />
        <SignupLink>
          Already have an account? <Link to="/auth/login">Log in now</Link>
        </SignupLink>
      </LoginContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  padding: 3rem 4rem;
  min-width: 35vw;
`;

const Logo = styled.img`
  height: 3rem;
  opacity: 75%;
  margin-top: -7.2rem;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
`;

const Paragraph = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  max-width: 750%;
`;

const LoginButton = styled.button`
  position: relative;
  border-radius: 0.5rem;
  padding: 1.5rem 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  cursor: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "not-allowed" : "pointer"};
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.75 : 1)};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    z-index: -1;
    background-image: ${styles.gradients.primary};
    transition: all 0.5s;
  }

  ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "" : "&:hover::before {transform: translateX(-25%);}"};
`;

const errorSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.secondary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};

const SignupLink = styled.span`
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;

  & a {
    color: ${colors.primary.hex};
    text-decoration: none !important;
  }
`;
