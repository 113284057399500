import { createSlice } from "@reduxjs/toolkit";
import { User } from "@supabase/supabase-js";
import { UserDetails } from "../../types/UserDetails";

export interface AuthState {
  authenticated: boolean;
  user: null | User;
  userDetails: null | UserDetails;
  signupCompleted: boolean;
}

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    authenticated: false,
    user: null as null | User,
    userDetails: null as null | UserDetails,
    signupCompleted: false,
  },
  reducers: {
    setAuthenticatedStatus: (state, action: { payload: boolean }) => {
      state.authenticated = action.payload;
    },
    setSignupCompletedStatus: (state, action: { payload: boolean }) => {
      state.signupCompleted = action.payload;
    },
    setUser: (state, action: { payload: User | null }) => {
      state.user = action.payload;
    },
    setUserDetails: (state, action: { payload: UserDetails | null }) => {
      state.userDetails = action.payload;
    },
    addRatedQuestion: (state, action: { payload: number }) => {
      if (!state.userDetails) return;

      const ratedQuestionsSet = new Set([
        ...(state.userDetails.rated_questions || []),
        action.payload,
      ]);
      const ratedQuestions: number[] = [];
      ratedQuestionsSet.forEach((question) => ratedQuestions.push(question));

      state.userDetails.rated_questions = ratedQuestions;
    },
  },
});

export const {
  setAuthenticatedStatus,
  setSignupCompletedStatus,
  setUser,
  setUserDetails,
  addRatedQuestion,
} = authSlice.actions;

export default authSlice.reducer;
