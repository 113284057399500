import React, { useCallback } from "react";
import styled from "styled-components";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";

import { Dropdown } from "../topical/Dropdown";
import { Subject } from "../../types/Subject";
import { supabase } from "../../api/supabase";
import { Board } from "../../types/Board";
import { TOPICS } from "../../data/topics";
import { colors } from "../../theme/colors";
import { styles } from "../../theme/styles";
import { API_BASE_URL } from "../../api/api";
import { Loader } from "../../components/Loader";
import { createNonNullExpression } from "typescript";

export const Search = () => {
  const [subject, setSubject] = useState<string[]>([]);
  const [subjectCode, setSubjectCode] = useState("");
  const [subjectsOptions, setSubjectsOptions] = useState<
    { name: string; code: string; papers: string[] }[]
  >([]);
  const [loading, setLoading] = useState(false);

  const [board, setBoard] = useState<string[]>([]);
  const [boardsOptions, setBoardsOptions] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useState("");

  const [queryParams] = useSearchParams();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Markhint | Paper Search";
  }, []);

  useEffect(() => {
    if (queryParams.get("query") && !searchQuery) {
      setSearchQuery(queryParams.get("query")!);
    }
  }, [queryParams, searchQuery]);

  // Fetch board options
  useEffect(() => {
    (async function () {
      const { data: boards = [] } = await supabase.from("boards").select();

      setBoardsOptions(
        boards!
          .filter((board) => (board as Board).search_implemented)
          .map((board: any) => (board as Board).name)
      );
    })();
  }, []);

  // Fetch subject options
  useEffect(() => {
    (async function () {
      if (board.length) {
        const { data: subjects = [] } = await supabase
          .from("subjects")
          .select()
          .filter("board", "eq", board[0])
          .order("name");

        setSubjectsOptions(
          subjects!
            .filter((subject: any) => (subject as Subject).search_implemented)
            .map((subject: any) => ({
              name: (subject as Subject).name,
              code: (subject as Subject).code,
              papers: (subject as Subject).papers,
            }))
        );
      }
    })();
  }, [board]);

  // Set subject code on subject selection
  useEffect(() => {
    const subjectOption = subjectsOptions.find((el) => el.name === subject[0]);
    if (!subjectOption) return;

    const subjectCode = subjectOption.code;

    setSubjectCode(subjectCode);
  }, [subject, subjectsOptions]);

  // Fetch results
  const getResults = useCallback(async () => {
    setLoading(true);
    try {
      const requestBody = {
        data: searchQuery,
        board: board[0] === "IGCSE" ? "IGCSE" : "ALevels",
        subject: subject[0],
      };

      const response = await fetch(`${API_BASE_URL}/pastPaperSearchText`, {
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
        mode: "cors",
        body: JSON.stringify(requestBody),
      });
      const result: { data: string } = await response.json();

      const slug = result.data;

      navigate(
        `/search/results/${board[0]
          .toLowerCase()
          .replace(/ /g, "-")}/${subjectCode}/${slug.replace(".pdf", "")}`
      );
    } catch (e) {
      alert("There was an error!");
    } finally {
      setLoading(false);
    }
  }, [board, subject, searchQuery, navigate, subjectCode]);

  const loadImage = async (imageFile: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = function () {
        resolve(reader.result as string);
      };

      reader.readAsDataURL(imageFile);
    });
  };

  const onImageInput = useCallback(
    async (evt: React.ChangeEvent<HTMLInputElement>) => {
      setLoading(true);
      try {
        if (!board.length || !subject.length) return;

        const files = evt.target.files;
        if (files?.length === 0 || !files) return;

        const imageFile = files[0];
        const imageBase64 = await loadImage(imageFile);

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const body = JSON.stringify({
          subject: subject[0],
          data: imageBase64,
          board: board[0] === "A Levels" ? "ALevels" : board[0],
        });

        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: body,
          redirect: "follow" as RequestRedirect,
        };

        const response = await fetch(
          `${API_BASE_URL}/pastPaperSearchImage`,
          requestOptions
        );
        const { data: slug } = await response.json();
        navigate(
          `/search/results/${board[0]
            .toLowerCase()
            .replace(/ /g, "-")}/${subjectCode}/${slug.replace(".pdf", "")}`
        );
      } catch (e) {
        alert("There was an error");
      } finally {
        setLoading(false);
      }
    },
    [subject, board, subjectCode]
  );

  return (
    <Main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          justifyContent: "center",
        }}
      >
        <Heading>Past Paper Search</Heading>
        <Main2>
          <DropdownsContainer>
            <Dropdown
              label="Board"
              values={board}
              options={
                boardsOptions.length
                  ? boardsOptions.map((option) => ({
                      label: option,
                      value: option,
                    }))
                  : [
                      { label: "IGCSE", value: "IGCSE" },
                      { label: "A Levels", value: "A Levels" },
                    ]
              }
              onChange={setBoard}
              disabled={!boardsOptions.length}
            />
            <Dropdown
              label="Subject"
              values={subject}
              options={
                subjectsOptions.length
                  ? subjectsOptions.map((option) => ({
                      label: option.name,
                      value: option.name,
                    }))
                  : board[0] in TOPICS
                  ? Object.keys(TOPICS[board[0]])
                      .sort()
                      .map((subject) => ({
                        label: subject,
                        value: subject,
                      }))
                  : []
              }
              onChange={setSubject}
              disabled={!subjectsOptions.length}
            />
          </DropdownsContainer>
          <Input
            placeholder="Type a query"
            value={searchQuery}
            onChange={(evt: any) =>
              setSearchQuery((evt.target as HTMLInputElement)!.value)
            }
          />
          {(subject.length && board.length && (
            <input
              type="file"
              style={{ display: "none" }}
              id="image"
              onInput={onImageInput}
              accept="image/png,image/jpeg"
            />
          )) ||
            null}
          <UploadImage
            disabled={!subject.length || !board.length}
            htmlFor="image"
          >
            Upload an image
          </UploadImage>
          <SearchButton
            active={
              !!board.length &&
              !!subject.length &&
              searchQuery.length >= 5 &&
              !loading
            }
            onClick={getResults}
          >
            {loading && <Loader />}
            Search
          </SearchButton>
        </Main2>
      </div>
    </Main>
  );
};
const Main = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const Heading = styled.div`
  font-size: 7rem;
  margin-bottom: 6rem;
  font-weight: 500;

  @media only screen and (max-width: 700px) {
    font-size: 6rem;
    margin-bottom: 4rem;
  }

  @media only screen and (max-width: 560px) {
    font-size: 4rem;
    margin-bottom: 2.5rem;
  }

  @media only screen and (max-width: 390px) {
    font-size: 3rem;
    margin-bottom: 1.8rem;
  }

  @media only screen and (max-width: 300px) {
    font-size: 2rem;
    margin-bottom: 0.9rem;
  }
`;

const Main2 = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DropdownsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  align-items: flex-start;
  justify-content: center;

  & > * {
    flex: 1;
  }

  @media only screen and (max-width: 560px) {
    flex-direction: column;
  }
`;

const Input = styled.textarea`
  border: 0;
  width: 100%;
  height: 30vh;
  margin-top: 2rem;
  box-shadow: #1d1d1d14 2px 2px 30px;
  font-family: Lato, "Be Vietnam", "Noto Sans", sans-serif;

  display: flex;
  text-align: top;
  padding: 2rem;

  transition: transform 0.5s;

  &:focus {
    outline: none;
    border: none;
    transform: translateY(-0.25rem);
  }
`;

const SearchButton = styled.button`
  padding: 1.5rem 2rem;
  background-color: ${colors.primary.hex};
  border-radius: 0.75rem;
  font-size: 1.6rem;
  color: #fff;
  font-weight: 600;
  margin-top: 3rem;
  transition: all 0.5s;
  opacity: ${({ active }: { active?: boolean }) => (active ? 1 : 0.7)};
  cursor: ${({ active }: { active?: boolean }) =>
    active ? "pointer" : "not-allowed"};
  outline: none;
  border: none;
  width: 100%;

  &:hover {
    transform: ${({ active }: { active?: boolean }) =>
      active ? "translateY(-10%)" : "none"};
    box-shadow: ${({ active }: { active?: boolean }) =>
      active ? styles.boxShadow : "none"};
  }

  &:active {
    transform: ${({ active }: { active?: boolean }) =>
      active ? "translateY(-5%)" : "none"};
    transition: all 0.2;
  }
`;

const UploadImage = styled.label`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.primary.hex};
  cursor: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "not-allowed" : "pointer"};
  margin-top: 2rem;
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.75 : 1)};
`;
