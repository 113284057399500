import React from "react";
import styled from "styled-components";
import { Navbar } from "./components/Navbar";
import { Sidebar } from "./components/sidebar.component";

export const WithSidebar = ({
  children,
  navbar = false,
}: {
  navbar?: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Container>
      <Sidebar />
      {navbar && <Navbar />}
      {children}
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  min-height: 100vh;
  height: fit-content;
  overflow: scroll;
  overflow-x: hidden;
`;
