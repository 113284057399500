import React, { useCallback, useEffect, useRef } from "react";
import { supabase } from "./api/supabase";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "./redux/store";
import { setUserDetails } from "./redux/slices/auth.slice";

const UPDATE_INTERVAL = 15000; // Update analytics data every 15 seconds

export const AnalyticsManager = () => {
  const dispatch = useDispatch();

  const interval = useRef<NodeJS.Timer | null>(null);

  const userDetails = useSelector(
    (state: ReduxState) => state.auth?.userDetails || null
  );

  const intervalCallback = useCallback(async () => {
    if (!userDetails || !document.hasFocus()) return;

    const sessionDurations = { ...userDetails.session_durations };
    const date = new Date().toLocaleDateString().replace(/[/]/g, "-");

    sessionDurations[date] =
      (sessionDurations[date] || 0) + UPDATE_INTERVAL / 1000;

    await supabase
      .from("users")
      .update({ session_durations: sessionDurations })
      .eq("id", userDetails.id);

    dispatch(
      setUserDetails({ ...userDetails, session_durations: sessionDurations })
    );
  }, [userDetails, dispatch]);

  useEffect(() => {
    if (interval.current) {
      clearInterval(interval.current);
      interval.current = setInterval(intervalCallback, UPDATE_INTERVAL);
    } else if (userDetails) {
      interval.current = setInterval(intervalCallback, UPDATE_INTERVAL);
    }
  }, [userDetails, interval, intervalCallback]);

  return <></>;
};
