import React from "react";
import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";
export const FeaturesSection = () => {
  const navigate = useNavigate();
  return (
    <Container>
      <Row>
        <MainCard>
          Our Features
          <br />
          <span>Designed for you</span>
        </MainCard>
        <Card
          onClick={() => {
            navigate("/search");
          }}
        >
          <Icon src="/assets/icons/search-outline.svg" />
          <div>
            <CardTitle>Past paper search</CardTitle>
            <CardContent>
              Search for the answers to any past paper question, ensuring no
              doubt of yours ever goes unanswered.
            </CardContent>
          </div>
        </Card>
      </Row>
      <Row>
        <Card
          onClick={() => {
            navigate("/topical");
          }}
        >
          <Icon src="/assets/icons/question.svg" />
          <div>
            <CardTitle>Topical questions</CardTitle>
            <CardContent>
              Practice past paper questions by topic and practice the topics you
              need to ace your exams.
            </CardContent>
          </div>
        </Card>
        <Card>
          <Icon src="/assets/icons/paper.svg" />
          <div>
            <CardTitle>Past papers</CardTitle>
            <CardContent>
              Practice entire past papers from our complete collection of past
              papers.
            </CardContent>
          </div>
        </Card>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 10rem;
  padding: 0rem 10rem;
  width: 100%;

  @media only screen and (max-width: 28.125em) {
    padding: 0 7rem;
  }

  @media only screen and (max-width: 25em) {
    padding: 0 5rem;
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  padding: 4rem;
  flex: 1;
  box-shadow: #1d1d1d14 0px 0px 30px;
  justify-content: space-between;
  height: 30rem;
  transition: all 0.2s;
  min-width: 30rem;
  gap: 2rem;
  justify-content: space-between;
  // align-items: center;

  &:hover {
    transform: scale(1.02);
  }
`;

const MainCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 2rem;
  padding: 4rem;
  flex: 1;
  box-shadow: #1d1d1d14 0px 0px 30px;
  justify-content: space-between;
  height: 30rem;
  background-color: ${colors.text.hex};
  color: #fff;
  font-size: 4rem;
  letter-spacing: 2px;
  font-weight: 600;
  flex: 2;
  justify-content: flex-end;
  box-shadow: none;

  & span {
    display: block;
    font-size: 1.5rem;
    opacity: 50%;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const Icon = styled.img`
  width: 7rem;
  transform: scale(1.3);
  transform-origin: 0 0;
`;

const CardTitle = styled.h3`
  font-size: 2.5rem;
  font-weight: 600;
`;

const CardContent = styled.p`
  font-size: 1.6rem;
  font-weight: 400;
`;
