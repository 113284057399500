import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../api/supabase";
import styled from "styled-components";
import { Question } from "../../types/Question";
import { Navbar } from "../../components/Navbar";
import { parseJson } from "../../utils/parseJson";
import { MCQResponse, Test } from "../../types/Test";
import { Spacer } from "../../components/Spacer";
import { colors } from "../../theme/colors";
import { Modal } from "../../components/Modal";

interface Questions {
  question: Question;
  completed: boolean;
  responseType: string; // '', 'correct', or 'incorrect'
  response: string; // A, B, C or D
}

export const MCQTestPage = () => {
  const [questionList, setQuestionList] = useState<Questions[]>([]);

  // const [currentQuestion, setCurrentQuestion] = useState<Questions | null>(
  //   null
  // );
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [test, setTest] = useState<null | Test>(null);

  const [correct, setCorrect] = useState(0);
  const [incorrect, setIncorrect] = useState(0);
  const [completed, setCompleted] = useState(0);

  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [showStartModal, setShowStartModal] = useState(true);

  const [timeElapsed, setTimeElapsed] = useState(0);

  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  const [nextBtnActive, setNextBtnActive] = useState(false);

  const [timeInterval, setTimeInterval] = useState(60);

  const { id: testId } = useParams();
  // const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const parent = useRef<HTMLDivElement | null>(null);

  const getQuestions = useCallback(
    async (id: string) => {
      const { data = [] } = (await supabase
        .from("tests")
        .select("*")
        .eq("id", id)) as any as { data: Test[] };
      const [test] = data;

      if (test.completed) return navigate(`/tests/${test.id}/results`);

      const { data: questions } = await supabase
        .from("questions")
        .select("*")
        .in("id", test.questions);

      let totalCorrect = 0,
        totalIncorrect = 0,
        totalCompleted = 0,
        firstIncompleteIndex: number | null = null;
      const questionSet = (questions as Question[]).map((q, i) => {
        const completed = test.responses[q.id] !== null;
        const correct =
          test.responses[q.id] ===
          MCQResponse[q.answer[0] as "A" | "B" | "C" | "D"];

        if (completed) totalCompleted++;
        else if (firstIncompleteIndex === null) {
          firstIncompleteIndex = i;
        }

        if (completed && correct) totalCorrect++;
        else if (completed && !correct) totalIncorrect++;

        return {
          question: q,
          completed,
          responseType: completed ? (correct ? "correct" : "incorrect") : "",
          response: test.responses[q.id] ?? "",
        };
      });

      setQuestionList([...(questionSet as any)]);
      setTest(test);
      setShowStartModal(!test.started);
      setCurrentQuestionIndex(firstIncompleteIndex ?? 0);
      setCompleted(totalCompleted);
      setCorrect(totalCorrect);
      setIncorrect(totalIncorrect);

      setTimeInterval(
        Math.floor((test.duration * 60 - (test.time_elapsed || 0)) / 10)
      );

      setTimeElapsed(test.time_elapsed || 0);
    },
    [navigate]
  );

  useEffect(() => {
    if (testId) {
      getQuestions(testId);
    }
  }, [testId, getQuestions]);

  useEffect(() => {
    if (test && test.duration > 0 && test.started) {
      const interval = setInterval(() => {
        setTimeElapsed((timeElapsed) => timeElapsed + 1);
      }, 1000);

      timer.current = interval;

      return () => clearInterval(interval);
    }
  }, [test]);

  useEffect(() => {
    if (timeElapsed / 60 >= (test?.duration || timeElapsed) && timer.current) {
      clearInterval(timer.current);
      setShowSubmitModal(true);
    }
  }, [timeElapsed, test]);

  useEffect(() => {
    (async function () {
      if (test && timeElapsed % timeInterval === 0) {
        await supabase
          .from("tests")
          .update({ time_elapsed: timeElapsed })
          .eq("id", test.id);
      }
    })();
  }, [timeElapsed, test, timeInterval]);

  function season(month: string) {
    if (month === "Oct/Nov") return "w";
    else if (month === "May/Jun") return "s";
    else return "m";
  }

  function handleMCQBoxColor(option: "A" | "B" | "C" | "D") {
    if (
      questionList[currentQuestionIndex]?.completed &&
      +questionList[currentQuestionIndex]?.response === MCQResponse[option]
    ) {
      return colors.primary.hex;
    }
    return "#fff";
  }

  const handleBtnClick = useCallback(
    async (option: "A" | "B" | "C" | "D") => {
      if (!questionList[currentQuestionIndex].completed && test) {
        setCompleted(completed + 1);
        const question = questionList[currentQuestionIndex];
        const arr = questionList;
        const tempQ = arr[currentQuestionIndex];
        tempQ.completed = true;
        tempQ.response = String(MCQResponse[option]);
        if (option === question.question.answer[0]) {
          tempQ.responseType = "correct";
          setCorrect(correct + 1);
        } else {
          tempQ.responseType = "incorrect";
          setIncorrect(incorrect + 1);
        }
        setQuestionList([...arr]);
        setNextBtnActive(true);

        const newResponses = { ...test.responses };

        newResponses[questionList[currentQuestionIndex].question.id] =
          MCQResponse[option as "A" | "B" | "C" | "D"];
        await supabase
          .from("tests")
          .update({
            responses: newResponses,
          })
          .eq("id", test.id);

        setTest({ ...test, responses: newResponses });

        const completedQuestions = parseJson(
          localStorage.getItem("completedQuestions") || "{}"
        );
        completedQuestions[questionList[currentQuestionIndex].question.id] =
          true;
        localStorage.setItem(
          "completedQuestions",
          JSON.stringify(completedQuestions)
        );
      }
    },
    [questionList, currentQuestionIndex, correct, incorrect, test, completed]
  );

  const minutesLeft = (test?.duration || timeElapsed) - timeElapsed / 60;

  return (
    <Parent ref={parent}>
      <Navbar />
      <Modal
        show={showStartModal}
        setShow={setShowStartModal}
        allowClose={false}
        title="Start test"
        actions={[
          {
            label: "Start",
            onClick: async () => {
              if (!parent.current || !test) return;

              if (parent.current.requestFullscreen)
                parent.current.requestFullscreen();
              else if ((parent.current as any).webkitRequestFullscreen)
                (parent.current as any).webkitRequestFullscreen();
              else if ((parent.current as any).msRequestFullscreen)
                (parent.current as any).msRequestFullscreen();

              setShowStartModal(false);

              await supabase
                .from("tests")
                .update({ started: true })
                .eq("id", test.id);
              setTest({ ...test, started: true });
            },
          },
        ]}
      >
        <ModalText>Click start to start the test.</ModalText>
      </Modal>
      <Modal
        show={showSubmitModal}
        setShow={setShowSubmitModal}
        title="Confirm submission"
        actions={[
          {
            label: "Submit",
            onClick: async () => {
              await supabase
                .from("tests")
                .update({
                  completed: true,
                  completed_at: new Date(),
                  obtained_marks: correct,
                })
                .eq("id", test!.id);
              navigate(`/tests/${test!.id}/results`);
            },
          },
        ]}
        allowClose={minutesLeft > 0}
      >
        <ModalText>
          You have completed {completed} questions out of a total of{" "}
          {questionList.length} questions.
        </ModalText>
      </Modal>
      <Spacer size={20} direction="bottom" />
      {test?.duration && test.duration > 0 ? (
        <Timer>
          {Math.floor(minutesLeft)} m{" "}
          {Math.round((minutesLeft - Math.floor(minutesLeft)) * 60)} s left
        </Timer>
      ) : !test ? (
        <Timer>...</Timer>
      ) : (
        <Timer>Untimed</Timer>
      )}
      <Heading>{test?.name || "Test"}</Heading>
      <Main>
        <Left>
          <Header>
            <div>
              Completed: {completed}/{questionList.length}
            </div>
            <div>Correct: {correct}</div>
            <div>Wrong: {incorrect}</div>
          </Header>
          <QuestionList>
            {questionList.map((question, key) => (
              <QuestionBox
                key={question.question.id}
                onClick={() => {
                  setCurrentQuestionIndex(key);
                }}
                background={
                  key === currentQuestionIndex
                    ? "#ddd"
                    : question.completed
                    ? question.responseType === "correct"
                      ? "rgba(25, 222, 104, 0.87)"
                      : "rgba(255, 21, 21, 0.67)"
                    : "#fff"
                }
              >
                <div>Q{key + 1} - </div>
                <div>
                  {season(question.question.season)}
                  {question.question.year.toString().substring(2, 4)} QP
                  {(
                    question.question.unit_type ??
                    question.question.paper_number
                  ).toString()}
                  {question.question.variant?.toString() ?? ""}
                </div>
              </QuestionBox>
            ))}
          </QuestionList>
        </Left>
        <Right>
          <RightTop
            src={questionList[currentQuestionIndex]?.question.images[0]}
          />
          <RightBottom>
            <MCQBoxes>
              {(["A", "B", "C", "D"] as ("A" | "B" | "C" | "D")[]).map(
                (option) => (
                  <MCQBox
                    key={`MCQOption__${option}`}
                    background={handleMCQBoxColor(option)}
                    onClick={() => handleBtnClick(option)}
                  >
                    {option}
                  </MCQBox>
                )
              )}
            </MCQBoxes>
            {nextBtnActive && (
              <NextBtn
                style={{ transform: "translateX(-150%)" }}
                negative
                onClick={() => {
                  if (currentQuestionIndex === questionList.length - 1) {
                    setShowSubmitModal(true);
                  } else {
                    setCurrentQuestionIndex(currentQuestionIndex + 1);
                    setNextBtnActive(false);
                  }
                }}
              >
                Next
              </NextBtn>
            )}
            <NextBtn
              onClick={() => {
                setShowSubmitModal(true);
              }}
            >
              Submit
            </NextBtn>
          </RightBottom>
        </Right>
      </Main>
    </Parent>
  );
};

const Parent = styled.div`
  &::backdrop {
    background-color: rgba(255, 255, 255, 0);
  }
`;

const NextBtn = styled.button`
  background: ${({ negative }: { negative?: boolean }) =>
    negative ? "#fff" : colors.primary.hex};
  box-shadow: ${({ negative }: { negative?: boolean }) =>
    negative ? "none" : "0px 4px 10px rgba(0, 0, 0, 0.25)"};
  border-radius: 0.5rem;
  font-size: 1.6rem;
  color: ${({ negative }: { negative?: boolean }) =>
    negative ? colors.primary.hex : "#fff"};
  padding: 1.5rem 2.5rem;
  position: absolute;
  margin-top: 2rem;
  right: 4rem;
  font-weight: 600;
`;

const MCQBoxes = styled.div`
  display: flex;
  gap: 2rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
  justify-content: center;
`;

interface MCQBstyled {
  background: string;
}

const MCQBox = styled("div")<MCQBstyled>`
  padding: 10px 20px;
  text-align: center;
  display: grid;
  place-items: center;
  font-size: 18px;
  box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.15);
  border-radius: 5px;
  transition: all 300ms;
  &:hover {
    background-color: ${({ background }: { background: string }) =>
      background !== "#fff" ? background : "#fafafa"};
    cursor: pointer;
  }
  background: ${({ background }: { background: string }) => background};
`;

// const MCQBox2 = styled.div`
//   padding: 10px 20px;
//   text-align: center;
//   display: grid;
//   place-items: center;
//   font-size: 18px;
//   box-shadow: 0px 2px 20px rgba(51, 51, 51, 0.15);
//   border-radius: 5px;
//   transition: all 300ms;
//   &:hover {
//     background-color: #fafafa;
//     cursor: pointer;
//   }
// `;

interface QBstyled {
  background: string;
}

const RightTop = styled.img`
  max-width: 100%;
  max-height: 70%;
  margin-top: 20px;
`;

const RightBottom = styled.div`
  border-top: solid 1px #ddd;
  width: 100%;
  height: 90px;
`;

// const QuestionBox = styled.div`
//   border: 1px solid #333333;
//   border-radius: 5px;
//   font-size: 16px;
//   margin: 0 20px;
//   padding: 5px 10px;
//   transition: all 300ms;
//   display: flex;
//   align-items: flex-start;
//   gap: 3px;
//   &:hover {
//     cursor: pointer;
//     background-color: #f5f5f5;
//   }
// `;

const QuestionBox = styled("div")<QBstyled>`
  background: ${({ background }: { background: string }) => background};
  border: 1px solid #333333;
  border-radius: 5px;
  font-size: 16px;
  margin: 0 20px;
  padding: 5px 10px;
  transition: all 300ms;
  display: flex;
  align-items: flex-start;
  gap: 3px;
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 25px;
  font-size: 16px;
`;

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
  margin-left: 40px;
  margin-right: 40px;
`;

const Left = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: calc(100vw / 3);
  height: 70vh;
  overflow-y: scroll;
  /* max-height: 460px; */
`;

const Right = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  width: calc(100vw - 100vw / 3);
  height: 70vh;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* max-height: 460px; */
`;

const Heading = styled.div`
  font-size: 5rem;
  margin-top: 1rem;
  margin-bottom: 3rem;
  font-weight: 500;
  text-align: center;

  @media only screen and (max-width: 46.875em) {
    font-size: 4rem;
  }

  @media only screen and (max-width: 35em) {
    font-size: 3rem;
    margin-bottom: 2rem;
  }
`;

const Timer = styled.div`
  position: absolute;
  top: 4rem;
  left: 4rem;
  padding: 1rem 3rem;
  border-radius: 20rem;
  font-size: 2rem;
  font-weight: 500;
  background-color: ${colors.primary.hex};
  color: #fff;
`;

const ModalText = styled.p`
  font-size: 1.5rem;
`;
