import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import { colors } from "../../theme/colors";
import { supabase } from "../../api/supabase";
import { Board } from "../../types/Board";
import { Subject } from "../../types/Subject";
import { Spacer } from "../../components/Spacer";
import { PastPaperBoard, pastPaperYears } from "./years";
import { pastPaperSeasons } from "./seasons";
import { pastPaperCodes } from "./papers";
import { styles } from "../../theme/styles";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "../topical/Dropdown";

export const PastPapers = () => {
  const [board, setBoard] = useState<PastPaperBoard | "">("");
  const [year, setYear] = useState("");
  const [subject, setSubject] = useState<{
    name: string;
    code: string;
    papers: string[];
    units: string[];
  } | null>(null);
  const [season, setSeason] = useState("");
  const [paperCode, setPaperCode] = useState("");
  const [variant, setVariant] = useState("");
  const [focusedFilter, setFocusedFilter] = useState(0);

  const [boardsOptions, setBoardsOptions] = useState<PastPaperBoard[]>([]);
  const [subjectsOptions, setSubjectsOptions] = useState<
    { name: string; code: string; papers: string[]; units: string[] }[]
  >([]);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Markhint | Past Papers";
  });

  useEffect(() => {
    (async function () {
      const { data: boards = [] } = await supabase
        .from("boards")
        .select()
        .eq("past_papers", true);
      const boardNames = boards!.map((board: any) => (board as Board).name);

      setBoardsOptions(boardNames as PastPaperBoard[]);
      setBoard(boardNames[0] as PastPaperBoard);
    })();
  }, []);

  useEffect(() => {
    (async function () {
      if (!board) return;

      setSubjectsOptions([]);
      const { data: subjects = [] } = await supabase
        .from("subjects")
        .select()
        .filter("board", "eq", board)
        .order("name");

      setSubjectsOptions(
        subjects!.map((subject: any) => ({
          id: (subject as Subject).code,
          name: `${(subject as Subject).name} (${(subject as Subject).code})`,
          code: (subject as Subject).code,
          papers: (subject as Subject).papers,
          units: (subject as Subject)?.units || [],
        }))
      );
    })();
  }, [board]);

  return (
    <Main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Content>
        <Heading>Past Papers</Heading>
        <BoardsContainer>
          {boardsOptions.map((option, i) => (
            <BoardOption
              key={`Option__${i}`}
              active={board === option}
              onClick={() => {
                setBoard(option);
              }}
            >
              {option}
            </BoardOption>
          ))}
        </BoardsContainer>
        <Spacer size={40} direction="bottom" />
        <FiltersContainer>
          <Dropdown
            label="Subject"
            values={subject ? [subject.name] : []}
            onChange={(value) =>
              setSubject(
                subjectsOptions.find((subject) => subject.name === value[0])!
              )
            }
            options={subjectsOptions.map((option) => ({
              value: option.name,
              label: option.name,
            }))}
            searchable
          />
          <Dropdown
            label="Year"
            values={year ? [year] : []}
            onChange={(newYear) => setYear(newYear[0])}
            options={
              board && subject
                ? pastPaperYears[board][subject.code as any]?.map((year) => ({
                    value: year,
                    label: year,
                  })) ?? []
                : []
            }
            disabled={!board || !subject}
          />
          <Dropdown
            label="Season"
            values={season ? [season] : []}
            onChange={(newSeason) => setSeason(newSeason[0])}
            options={
              board && subject
                ? pastPaperSeasons[board][subject.code as any]?.map(
                    (season) =>
                      ({
                        value: season,
                        label: season,
                      } ?? [])
                  )
                : []
            }
            disabled={!board || !subject}
          />
          <Dropdown
            label="Paper"
            values={paperCode ? [paperCode] : []}
            onChange={(newPaperCode) => setPaperCode(newPaperCode[0])}
            options={
              board && subject
                ? [
                    ...new Set(
                      pastPaperCodes[board][subject.code as any]?.map(
                        (code) => code[0]
                      )
                    ),
                  ].map((code) => ({
                    label: code,
                    value: code,
                  })) ?? []
                : []
            }
            disabled={!board || !subject}
          />
          <Dropdown
            label="Variant"
            values={variant ? [variant] : []}
            onChange={(newVariant) => setVariant(newVariant[0])}
            options={["1", "2", "3"].map((el) => ({ value: el, label: el }))}
            disabled={!board || !subject}
          />
        </FiltersContainer>
        <Spacer size={20} direction="bottom" />
        <SearchButton
          active={!!board && !!subject}
          onClick={() =>
            navigate(
              `/papers/results/${board?.toLowerCase().replace(/ /g, "-")}/${
                subject?.code
              }?year=${year}&season=${
                season.split(" ")[0]
              }&paper=${paperCode}&variant=${variant}`
            )
          }
        >
          Get Paper{year && season && paperCode && variant ? "" : "s"}
        </SearchButton>
      </Content>
    </Main>
  );
};

const Main = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Heading = styled.div`
  font-size: 7rem;
  margin-bottom: 6rem;
  font-weight: 500;
  text-align: center;

  @media only screen and (max-width: 46.875em) {
    font-size: 6rem;
  }

  @media only screen and (max-width: 35em) {
    font-size: 5rem;
    margin-bottom: 2rem;
  }
`;

const Content = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BoardOption = styled.div`
  padding: 1.5rem 3rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : colors.white.hex};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  font-size: 1.8rem;
  border-radius: 5rem;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: ${({ active }: { active?: boolean }) =>
      active ? colors.primary.hex : "#eee"};
  }
`;

const BoardsContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

const FilterContainer = styled.div`
  min-width: 30vw;
`;

const FiltersContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
`;

const filterStyle = {
  height: "44px",
  border: "none",
  borderRadius: "5px",
  backgroundColor: colors.white.hex,
  boxShadow: "0px 0px 0px rgba(0,0,0,0)",
  hoverBackgroundColor: "#eee",
  color: "#212121",
  fontSize: "1.5rem",
  fontFamily:
    '"Poppins", "Roboto", "Core Sans", Lato, "Be Vietnam", "Noto Sans", sans-serif',
  iconColor: "grey",
  lineColor: "rgb(232, 234, 237)",
  placeholderColor: "grey",
  clearIconMargin: "3px 14px 0 0",
  searchIconMargin: "0 0 0 16px",
};

const SearchButton = styled.button`
  padding: 1.5rem 2rem;
  background-color: ${({
    negative,
  }: {
    active?: boolean;
    negative?: boolean;
  }) => (negative ? "#fff" : colors.primary.hex)};
  border-radius: 0.75rem;
  font-size: 1.6rem;
  color: ${({ negative }: { negative?: boolean }) =>
    negative ? colors.primary.hex : "#fff"};
  font-weight: 600;
  margin-top: 5rem;
  transition: all 0.5s;
  opacity: ${({ active }: { active?: boolean }) => (active ? 1 : 0.7)};
  cursor: ${({ active }: { active?: boolean }) =>
    active ? "pointer" : "not-allowed"};
  outline: none;
  border: none;

  &:hover {
    transform: ${({
      active,
      negative,
    }: {
      active?: boolean;
      negative?: boolean;
    }) => (active && !negative ? "translateY(-10%)" : "none")};
    box-shadow: ${({
      active,
      negative,
    }: {
      active?: boolean;
      negative?: boolean;
    }) => (active && !negative ? styles.boxShadow : "none")};
    filter: brightness(1.05);
  }

  &:active {
    transform: ${({ active }: { active?: boolean }) =>
      active ? "translateY(-5%)" : "none"};
    transition: all 0.2;
  }
`;
