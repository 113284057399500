import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Navbar } from "../../components/Navbar";
import { useParams } from "react-router";
import { Subject } from "../../types/Subject";
import { supabase } from "../../api/supabase";
import { Loader } from "../../components/Loader";

export const Result = () => {
  const { board, subject, slug } = useParams();

  const [subjectDetails, setSubjectDetails] = useState<Subject | null>(null);

  useEffect(() => {
    (async function () {
      const { data: subjects } = await supabase
        .from("subjects")
        .select("*")
        .eq("code", subject);

      if (subjects?.length) {
        setSubjectDetails((subjects as Subject[])[0]);
      }
    })();
  }, [subject]);

  return (
    <Main>
      <Navbar />
      <Slug>
        {slug?.replaceAll("_", " ").replace("qp", "").replace(".pdf", "")}
      </Slug>
      <DisplayWindows>
        {!subjectDetails && <Loader />}
        {subjectDetails && (
          <DisplayWindow
            src={generatePdfLink(
              slug!,
              board!,
              subjectDetails.syllabus_name,
              subject!
            )}
          />
        )}
        {subjectDetails && (
          <DisplayWindow
            src={generatePdfLink(
              slug!,
              board!,
              subjectDetails.syllabus_name,
              subject!
            ).replace("qp", "ms")}
          />
        )}
      </DisplayWindows>
    </Main>
  );
};

function generatePdfLink(
  slug: string,
  board: string,
  subject: string,
  subjectCode: string
) {
  let pdfLink = "https://papers.gceguide.com/";

  // Add Board
  if (board === "a-levels") {
    pdfLink += "A%20Levels/";
  } else if (board === "igcse") {
    pdfLink += "IGCSE/";
  }

  pdfLink += subject; // Add syllabus name
  pdfLink += `%20(${subjectCode})`; // Add syllabus code
  pdfLink += `/20${slug.split("_")[1].slice(1)}/`; // Add year
  pdfLink += slug + ".pdf"; // Add slug

  return pdfLink;
}

const DisplayWindow = styled.iframe`
  width: 40vw;
  height: 80vh;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Slug = styled.div`
  font-size: 4rem;
`;

const DisplayWindows = styled.div`
  display: flex;
  gap: 4rem;
`;
