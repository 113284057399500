import React from "react";
import styled from "styled-components";
import { styles } from "../../../../theme/styles";
import { colors } from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";

export const SchoolFeaturesSection = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Chip>For Schools/Teachers</Chip>
      <Row>
        <ImageContainer>
          <Image src="/assets/images/topical-worksheet.png" />
        </ImageContainer>
        <ContentContainer>
          <Title>Automatic worksheet generator</Title>
          <Paragraph>
            Generate PDF worksheets by topic, after filtering through our
            topical database to accelerate paper-generation for classrooms.
          </Paragraph>
          <Link onClick={() => navigate("/topical")}>Generate Now</Link>
        </ContentContainer>
      </Row>
      <Row reverse>
        <ImageContainer>
          <Image src="/assets/images/mcq-test.png" />
        </ImageContainer>
        <ContentContainer>
          <Title>Self-corrected online tests</Title>
          <Paragraph>
            AI-corrected online tests for both MCQ and long-answer questions
            that are automatically marked and detailed feedback and analysis is
            sent to students.
          </Paragraph>
          <Link onClick={() => navigate("/topical")}>Create a test now</Link>
        </ContentContainer>
      </Row>
      <Row>
        <ContentContainer>
          <Chip style={{ transform: "scale(0.8)", marginBottom: "2rem" }}>
            Coming soon!
          </Chip>
          <Title>Progress-tracking analytics dashboard</Title>
          <Paragraph>
            Track indivdual student progress by topic and view AI-recommended
            personalised teaching plans and resources.
          </Paragraph>
        </ContentContainer>
        <ContentContainer>
          <Chip style={{ transform: "scale(0.8)", marginBottom: "2rem" }}>
            Coming soon!
          </Chip>
          <Title>Detailed video explanations</Title>
          <Paragraph>
            Video explanations for every question. Go beyond the markscheme and
            make sure students have understood a question completely.
          </Paragraph>
        </ContentContainer>
      </Row>
      <Row>
        <ContentContainer>
          <Chip style={{ transform: "scale(0.8)", marginBottom: "2rem" }}>
            Coming soon!
          </Chip>
          <Title>AI-assisted question explanations</Title>
          <Paragraph>
            Get detailed and personalised explanations for past paper questions.
            Clarify student doubts and to learn efficiently.
          </Paragraph>
        </ContentContainer>
        <ContentContainer>
          <Chip style={{ transform: "scale(0.8)", marginBottom: "2rem" }}>
            Coming soon!
          </Chip>
          <Title>Auto-corrected structured questions</Title>
          <Paragraph>
            Get students' answers to long-answer structured questions corrected
            automatically along with detailed, personalised feedback on how to
            write better answers.
          </Paragraph>
        </ContentContainer>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  margin: 10rem 0;
  padding: 0 10rem;

  @media only screen and (max-width: 33.125em) {
    padding: 0 5rem;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${({ reverse }: { reverse?: boolean }) =>
    reverse ? "row-reverse" : "row"};
  padding: 0 5rem;
  align-items: center;
  margin-bottom: 4rem;
  gap: 4rem;

  @media only screen and (max-width: 40.625em) {
    padding: 0;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  min-width: 40rem;

  @media only screen and (max-width: 28.125em) {
    min-width: 100vw;
  }
`;

const Image = styled.img`
  border-radius: 1rem;
  width: 25vw;
  box-shadow: ${styles.boxShadow};
`;

const ContentContainer = styled.div`
  flex: 1;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40rem;

  @media only screen and (max-width: 28.125em) {
    min-width: 50vw;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
`;

const Link = styled.h3`
  position: relative;
  font-size: 1.7rem;
  color: ${colors.primary.hex};
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: ${({ active }: { active?: boolean }) => (active ? "100%" : "0")};
    transition: width 0.5s;
    height: 2px;
    border-radius: 3px;
    background-color: ${colors.primary.hex};
  }

  &:hover::after {
    width: 100% !important;
  }
`;

const Chip = styled.div`
  width: fit-content;
  padding: 1.5rem 2.5rem;
  font-size: 1.5rem;
  background-color: ${colors.white.hex};
  border-radius: 10rem;
  font-weight: 600;
  margin-bottom: 5rem;
`;
