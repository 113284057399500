import React from "react";
import styled from "styled-components";
import { colors } from "../theme/colors";
import { Column } from "../widgets/Column";

export const Toggle = ({
  active,
  onToggle,
  onLabel,
  offLabel,
  style = {},
}: {
  active: boolean;
  onToggle: () => void;
  onLabel: string;
  offLabel: string;
  style?: React.CSSProperties;
}) => {
  return (
    <Column style={style}>
      <ToggleInput active={active} onClick={onToggle}>
        <ToggleCircle active={active} />
      </ToggleInput>
      <ToggleLabel>{active ? onLabel : offLabel}</ToggleLabel>
    </Column>
  );
};

const ToggleInput = styled.div`
  position: relative;
  display: flex;
  padding: 0.5rem;
  width: 5.5rem;
  height: 3rem;
  cursor: pointer;
  border-radius: 10rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? "#4d47ff48" : colors.white.hex};
  transition: all 0.5s;
  transform: scale(0.9);
`;

const ToggleCircle = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%)
    translateX(
      ${({ active }: { active?: boolean }) => (active ? "2.5rem" : "0")}
    );
  width: 2rem;
  height: 2rem;
  background-color: ${colors.primary.hex};
  border-radius: 50%;
  transition: all 0.2s;
`;

const ToggleLabel = styled.label`
  font-size: 1.3rem;
  color: ${colors.text.hex};
  font-weight: 600;
`;
