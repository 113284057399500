import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import { TOPICS } from "../../../../data/topics";
import { useNavigate } from "react-router-dom";

const BOARDS = ["IGCSE", "A Levels"];

export const TopicalSection = () => {
  const [activeBoard, setActiveBoard] = useState(0);
  const [activeSubject, setActiveSubject] = useState<string>("");

  const navigate = useNavigate();

  useEffect(() => {
    setActiveSubject(Object.keys(TOPICS[BOARDS[activeBoard]]).sort()[0]);
  }, [activeBoard]);

  return (
    <Container>
      <SubjectsContainer>
        {BOARDS.map((board, i) => (
          <Subject
            key={Math.random().toString(36)}
            active={activeBoard === i}
            onClick={() => setActiveBoard(i)}
          >
            {board}
          </Subject>
        ))}
      </SubjectsContainer>
      <SubjectsContainer>
        {Object.keys(TOPICS[BOARDS[activeBoard]])
          .sort()
          .map((subject) => (
            <Subject
              active={activeSubject === subject}
              key={Math.random().toString(36)}
              onClick={() => setActiveSubject(subject)}
            >
              {subject}
            </Subject>
          ))}
      </SubjectsContainer>
      <Row>
        <TopicsContainer>
          {(TOPICS[BOARDS[activeBoard]][activeSubject] || []).map(
            (topic, i) => (
              <Topic key={topic._id}>{topic.name}</Topic>
            )
          )}
        </TopicsContainer>
        <ContentContainer>
          {/* <Image src="/assets/images/analysis-dashboard-screenshot.png" /> */}
          <Title className="topical-title">
            A complete collection of past paper questions
          </Title>
          <Paragraph>
            With our topical question collection, practice for exams with ease.
            Our questions are sorted by subject and topic, allowing you to work
            on specific areas and analyse results. With our dashboard, work on
            weak points and ace your exams with ease.
          </Paragraph>
          <Link onClick={() => navigate("/topical")}>
            Explore topical questions
          </Link>
          {/* <QuestionContainer>
            <img
              src="https://www.exam-mate.com//questions/2387/21709/2387_q_21709_1_1.png"
              alt="Question"
            />
          </QuestionContainer>
          <QuestionContainer>
            <img
              src="https://www.exam-mate.com//questions/2387/21738/2387_q_21738_30_1.png"
              alt="Question"
            />
          </QuestionContainer>
          <QuestionContainer>
            <img
              src="https://www.exam-mate.com//questions/2404/25960/2404_q_25960_1_1.png"
              alt="Question"
            />
          </QuestionContainer>
          <QuestionContainer>
            <img
              src="https://www.exam-mate.com//questions/2438/26066/2438_q_26066_1_1.png"
              alt="Question"
            />
          </QuestionContainer> */}
        </ContentContainer>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  margin: 10rem 0;
  padding: 0 10rem;

  @media only screen and (max-width: 28.125em) {
    padding: 0 7rem;
  }

  @media only screen and (max-width: 25em) {
    padding: 0 5rem;
  }
`;

const SubjectsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 1rem;
`;

const Subject = styled.div`
  padding: 1.5rem 2rem;
  border-radius: 1rem;
  font-size: 1.5rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : "transparent"};
  color: ${({ active }: { active?: boolean }) =>
    active ? colors.white.hex : colors.text.hex};
  font-weight: ${({ active }: { active?: boolean }) => (active ? 600 : 500)};
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 2rem;
  margin-top: 4rem;
`;

const TopicsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: #1d1d1d14 0px 0px 30px;
  flex: 1;
  max-height: 55rem;
  overflow-y: scroll;
  min-width: 30rem;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 30rem;

  /* gap: 1rem; */
`;

const Topic = styled.div`
  font-size: 1.7rem;
  font-weight: 500;
  border-bottom: 1px solid ${colors.white.hex};
  text-align: center;
  background-color: ${colors.white.hex};
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
`;

const Paragraph = styled.p`
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  margin-top: 1rem;
`;

const Link = styled.h3`
  position: relative;
  font-size: 1.7rem;
  color: ${colors.primary.hex};
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
  cursor: pointer;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: ${({ active }: { active?: boolean }) => (active ? "100%" : "0")};
    transition: width 0.5s;
    height: 2px;
    border-radius: 3px;
    background-color: ${colors.primary.hex};
  }

  &:hover::after {
    width: 100% !important;
  }
`;

// const Image = styled.img`
//   width: 75%;
//   border-radius: 1.5rem;
//   margin-bottom: 3rem;
//   box-shadow: #1d1d1d14 0px 0px 30px;
//   transition: transform 0.4s ease-out;

//   &:hover {
//     transform: translateY(-1rem);
//   }
// `;
