import React from "react";
import styled from "styled-components";
import { Spacer } from "../../components/Spacer";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ConfirmEmailPage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  return (
    <Container>
      <Logo onClick={() => navigate("/")} src="/assets/images/logo.svg" />
      <Icon src="/assets/icons/email.png" alt="Email icon" />
      <Spacer size={10} direction="bottom" />
      <Title>Verify your email address</Title>
      <Spacer size={5} direction="bottom" />
      <Paragraph>
        We have sent you a verification email on{" "}
        <span>{searchParams.get("email") ?? "your email address"}</span>. Please
        click the link in the email to verify your account and sign in.
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Logo = styled.img`
  position: absolute;
  top: 2rem;
  left: 50%;
  height: 3rem;
  opacity: 75%;
  cursor: pointer;

  transform: translateX(-50%);
`;

const Icon = styled.img`
  width: 15rem;
`;

const Title = styled.h1`
  font-size: 3.5rem;
  font-weight: 600;
`;

const Paragraph = styled.p`
  font-size: 1.4rem;
  font-weight: 400;

  & span {
    font-weight: 600;
  }
`;
