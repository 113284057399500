import React from "react";
import styled from "styled-components";
import { colors } from "../theme/colors";
import { Spacer } from "./Spacer";

export const Checkboxes = ({
  options,
  label,
  values,
  setValues,
}: {
  options: { label: string; value: string }[];
  label: string;
  values: string[];
  setValues: (values: string[]) => void;
}) => {
  return (
    <CheckboxesContainer>
      <Label>{label}</Label>
      {options.map(({ label, value }) => (
        <CheckboxContainer
          key={Math.random().toString(36)}
          onClick={() => {
            const index = values.findIndex((el) => el === value);
            const newValues = [...values];
            if (index > -1) {
              newValues.splice(index, 1);
            } else {
              newValues.push(value);
            }

            setValues(newValues);
          }}
        >
          <Checkbox checked={values.includes(value)}>
            {values.includes(value) && <CheckIcon />}
          </Checkbox>
          <Spacer size={5} direction="left" />
          <CheckboxLabel>{label}</CheckboxLabel>
        </CheckboxContainer>
      ))}
    </CheckboxesContainer>
  );
};

const CheckboxesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
`;

const CheckboxContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
  align-items: center;
  user-select: none;
  margin-bottom: 0.5rem;
`;

export const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 0.5rem;
  background-color: ${({ checked }: { checked: boolean }) =>
    checked ? colors.primary.hex : colors.white.hex};
`;

const CheckboxLabel = styled.label`
  font-size: 1.3rem;
  font-weight: 400;
  cursor: pointer;
`;

export const CheckIcon = styled.img.attrs({
  src: "/assets/icons/checkmark.svg",
})`
  height: 1rem;
`;
