import React, { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { styles } from "../theme/styles";
import { colors } from "../theme/colors";
import { Row } from "../widgets/Row";
import { Spacer } from "./Spacer";

export const Modal = ({
  children,
  title,
  show,
  setShow,
  actions = [],
  allowClose = true,
}: {
  children: ReactNode;
  title: string;
  show: boolean;
  setShow: (show: boolean) => void;
  actions?: { label: string; onClick: () => void }[];
  allowClose?: boolean;
}) => {
  useEffect(() => {
    const handler = (evt: KeyboardEvent) => {
      if (evt.key === "Escape") setShow(false);
    };

    document.addEventListener("keydown", handler);

    return () => document.removeEventListener("keydown", handler);
  });

  return (
    <Overlay show={show}>
      <ModalContainer>
        <TopRow>
          <ModalTitle>{title}</ModalTitle>
          {allowClose && (
            <CloseButton
              src="/assets/icons/close.svg"
              onClick={() => {
                setShow(false);
              }}
            />
          )}
        </TopRow>
        {children}
        {!!actions.length ? <Spacer size={20} direction="top" /> : <></>}
        <Row>
          {actions.map((action) => (
            <ActionButton
              key={Math.random().toString(36)}
              onClick={action.onClick}
            >
              {action.label}
            </ActionButton>
          ))}
        </Row>
      </ModalContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: ${({ show = false }: { show?: boolean }) =>
    show ? "flex" : "none"};
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  z-index: 15;
  background-color: #1d1d1da8;
`;

const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 3rem;
  min-width: 45vw;
  max-width: 45vw;
  max-height: 70vh;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: ${styles.boxShadow};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ModalTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.text.hex};
  font-weight: 600;
`;

const CloseButton = styled.img`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

const ActionButton = styled.button`
  background: #4e47ff;
  border-radius: 0.5rem;
  font-size: 1.6rem;
  color: #fff;
  padding: 1.25rem 2.5rem;
  font-weight: 600;
`;
