import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../../../../theme/colors";
import { styles } from "../../../../../theme/styles";
import { Spacer } from "../../../../../components/Spacer";
import { ReduxState } from "../../../../../redux/store";
import { supabase } from "../../../../../api/supabase";

export const Navbar = () => {
  const [scrollY, setScrollY] = useState(0);
  const [showMenu, setShowMenu] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const authState = useSelector((state: ReduxState) => state.auth);

  const navigate = useNavigate();

  const onScroll = useCallback((e: Event) => {
    setScrollY(Math.abs(document.body.getBoundingClientRect().top));
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("onscroll", onScroll);
    };
  }, [onScroll]);

  return (
    <NavbarContainer showBackground={scrollY >= window.innerHeight}>
      <MenuOverlay show={showMenu}></MenuOverlay>
      <MenuToggleContainer onClick={() => setShowMenu((showMenu) => !showMenu)}>
        {showMenu ? <CloseIcon /> : <MenuIcon />}
      </MenuToggleContainer>
      <MobileMenuContainer show={showMenu}>
        <div
          style={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <AccountContainer
            onClick={() => {
              if (authState.authenticated) {
                setShowDropdown((showDropdown) => !showDropdown);
              } else {
                navigate("/auth/login");
              }
            }}
          >
            <Thumbnail src="/assets/images/avatar.jpg" alt="Avatar" />
            <Spacer size={2} direction="left" />
            <Open
              src="/assets/icons/down-chevron.png"
              onClick={() => {
                if (!authState.authenticated) {
                  setShowDropdown((showDropdown) => !showDropdown);
                }
              }}
            />
          </AccountContainer>
          <DropdownContainer show={showDropdown}>
            {/* <DropdownSectionTitle>My Profile</DropdownSectionTitle>
          <DropdownItem onClick={() => navigate("/profile")}>
            Dashboard
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/profile/tests")}>
            My Tests
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/profile/settings")}>
            Profile Settings
          </DropdownItem> */}
            {/* <DropdownSectionTitle>Markhint</DropdownSectionTitle>
            <DropdownItem onClick={() => navigate("/search")}>
              Search
            </DropdownItem>
            <DropdownItem onClick={() => navigate("/topical")}>
              Topical Questions
            </DropdownItem> */}
            <DropdownSectionTitle>Account</DropdownSectionTitle>
            <Logout
              onClick={async () => {
                if (authState.authenticated) {
                  const confirmed = window.confirm(
                    "Are you sure you want to log out?"
                  );
                  if (confirmed) {
                    await supabase.auth.signOut();
                  }
                } else {
                  navigate("/auth/login");
                }
              }}
            >
              Log {authState.authenticated ? "out" : "in"}
            </Logout>
          </DropdownContainer>
        </div>
        <MobileLink active>Home</MobileLink>
        <MobileLink onClick={() => navigate("/topical")}>
          Topical Questions
        </MobileLink>
        <MobileLink onClick={() => navigate("/search")}>
          Past Paper Search
        </MobileLink>
        <MobileLink onClick={() => navigate("/papers")}>Past Papers</MobileLink>
        <Spacer size={20} direction="bottom" />
        <SignupCTA
          onClick={() => navigate("/signup")}
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
        >
          Get started
        </SignupCTA>
        <SignupCTA
          onClick={() => navigate("/signup")}
          style={{ paddingLeft: "5rem", paddingRight: "5rem" }}
          negative
        >
          Login
        </SignupCTA>
      </MobileMenuContainer>
      <NavbarLinks>
        <NavbarLink onClick={() => navigate("/topical")}>
          Topical Questions
        </NavbarLink>
        <NavbarLink onClick={() => navigate("/search")}>
          Paper Search
        </NavbarLink>
        <NavbarLink onClick={() => navigate("/papers")}>Past Papers</NavbarLink>
      </NavbarLinks>
      <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
        <Logo src={"/assets/images/logo.svg"} alt="Logo" />
      </div>
      {authState.authenticated ? (
        <AccountParent>
          <AccountContainer
            onClick={() => {
              if (authState.authenticated) {
                setShowDropdown((showDropdown) => !showDropdown);
              } else {
                navigate("/auth/login");
              }
            }}
          >
            <Thumbnail src="/assets/images/avatar.jpg" alt="Avatar" />
            <Spacer size={2} direction="left" />
            <Open
              src="/assets/icons/down-chevron.png"
              onClick={() => {
                if (!authState.authenticated) {
                  setShowDropdown((showDropdown) => !showDropdown);
                }
              }}
            />
          </AccountContainer>
          <DropdownContainer show={showDropdown}>
            {/* <DropdownSectionTitle>My Profile</DropdownSectionTitle>
          <DropdownItem onClick={() => navigate("/profile")}>
            Dashboard
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/profile/tests")}>
            My Tests
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/profile/settings")}>
            Profile Settings
          </DropdownItem> */}
            {/* <DropdownSectionTitle>Markhint</DropdownSectionTitle>
            <DropdownItem onClick={() => navigate("/topical")}>
              Topical Questions
            </DropdownItem>
            <DropdownItem onClick={() => navigate("/search")}>
              Search
            </DropdownItem> */}
            <DropdownSectionTitle>Account</DropdownSectionTitle>
            <Logout
              onClick={async () => {
                if (authState.authenticated) {
                  const confirmed = window.confirm(
                    "Are you sure you want to log out?"
                  );
                  if (confirmed) {
                    await supabase.auth.signOut();
                  }
                } else {
                  navigate("/auth/login");
                }
              }}
            >
              Log {authState.authenticated ? "out" : "in"}
            </Logout>
          </DropdownContainer>
        </AccountParent>
      ) : (
        <CTAContainer>
          <LoginCTA onClick={() => navigate("/auth/login")}>Login</LoginCTA>
          <SignupCTA onClick={() => navigate("/auth/signup")}>
            Get started
          </SignupCTA>
        </CTAContainer>
      )}
    </NavbarContainer>
  );
};

const NavbarContainer = styled.nav`
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  padding: 2rem 10rem;

  background-color: ${({ showBackground }: { showBackground: boolean }) =>
    showBackground ? "rgba(245, 245, 245, 0.5)" : "transparent"};
  backdrop-filter: blur(8px);
`;

const NavbarLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 4rem;
  flex: 1;

  @media only screen and (max-width: 62.5em) {
    display: none;
  }
`;

const NavbarLink = styled.div`
  position: relative;
  font-size: 1.5rem;
  font-weight: ${({ active }: { active?: boolean }) =>
    active ? "600" : "500"};
  color: ${colors.text.hex};
  cursor: pointer;
  height: fit-content;

  &::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: ${({ active }: { active?: boolean }) => (active ? "100%" : "0")};
    transition: width 0.5s;
    height: 2px;
    border-radius: 3px;
    background-color: ${colors.primary.hex};
  }

  &:hover::after {
    width: 100% !important;
  }
`;

const Logo = styled.img`
  height: 3rem;
`;

const CTAContainer = styled.div`
  display: flex;
  gap: 4rem;
  flex: 1;
  justify-content: center;

  @media only screen and (max-width: 62.5em) {
    display: none;
  }
`;

const LoginCTA = styled.button`
  color: ${colors.text.hex};
  font-size: 1.5rem;
  font-weight: 500;
  background-color: transparent;
`;

const SignupCTA = styled.button`
  padding: 1.5rem 2rem;
  background-color: ${({ negative }: { negative?: boolean }) =>
    negative ? "#fff" : colors.text.hex};
  color: ${({ negative }) => (negative ? colors.text.hex : colors.white.hex)};
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 10rem;
`;

const MobileMenuContainer = styled.div`
  display: ${({ show }: { show?: boolean }) => (show ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  padding: 2rem;
  z-index: 20;

  opacity: ${({ show }: { show?: boolean }) => (show ? "1" : "0")};

  transition: opacity 1s;
`;

const MenuOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 19;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(16px);
  display: ${({ show }: { show?: boolean }) => (show ? "block" : "none")};
`;

const MobileLink = styled.h3`
  font-size: 1.7rem;
  font-weight: ${({ active }: { active?: boolean }) => (active ? 600 : 500)};
  padding: 1.5rem 3rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : "#fff"};
  box-shadow: ${styles.boxShadow};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  border-radius: 10rem;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${({ active }: { active?: boolean }) =>
      active ? colors.primary.hex : colors.white.hex};
  }
`;

const MenuToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.5s, opacity 1s;
  z-index: 21;

  transform: scale(1.2);

  position: fixed;
  top: 2rem;
  left: 2rem;

  &:hover {
    background-color: ${colors.white.hex};

    & > img {
      opacity: 50%;
    }
  }

  @media only screen and (min-width: 62.5em) {
    display: none;
  }
`;
const CloseIcon = styled.img.attrs({
  src: "/assets/icons/close.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const MenuIcon = styled.img.attrs({
  src: "/assets/icons/menu.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const AccountParent = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;

  @media only screen and (max-width: 62.5em) {
    display: none;
  }
`;

const AccountContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
`;

const Open = styled.img`
  width: 2rem;
`;

const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  top: 6.5rem;
  padding: 1.5rem;
  width: fit-content;
  min-width: 30rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  z-index: 4;
  transition: all 0.5s;
  transform: scaleY(${({ show }: { show: boolean }) => (show ? "100%" : "0")});
  transform-origin: 50% 0%;
  overflow: hidden;

  @media only screen and (max-width: 62.5em) {
    /* left: -50%; */
  }
`;

const DropdownSectionTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid ${colors.white.hex};
  width: 100%;
  padding: 0.5rem 1rem;
`;

const DropdownItem = styled.div`
  width: 100%;
  padding: 0.5rem 1.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${colors.white.hex};
  }
`;

const Logout = styled(DropdownItem)`
  background-color: ${colors.secondary.hex};
  color: ${colors.white.hex};
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;

  &:hover {
    background-color: #e3950a;
  }
`;
