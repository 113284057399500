export const generateYears = (start: number, end: number) => {
  const years = [];

  for (let i = start; i <= end; i++) {
    years.push(String(i));
  }

  return years.reverse();
};

export const FINAL_YEAR = 2023;

export const YEARS: {
  [board: string]: {
    [subject: string]: string[];
  };
} = {
  IGCSE: {
    Biology: generateYears(2009, FINAL_YEAR),
    Chemistry: generateYears(2009, FINAL_YEAR),
    Economics: generateYears(2009, FINAL_YEAR),
    Mathematics: generateYears(2009, FINAL_YEAR),
    Physics: generateYears(2009, FINAL_YEAR),
    "Additional Mathematics": generateYears(2009, FINAL_YEAR),
    "International Mathematics": generateYears(2009, FINAL_YEAR),
  },
  "A Levels": {
    Biology: generateYears(2009, FINAL_YEAR),
    Chemistry: generateYears(2009, FINAL_YEAR),
    "Computer Science": generateYears(2009, FINAL_YEAR),
    Economics: generateYears(2009, FINAL_YEAR),
    Mathematics: generateYears(2009, FINAL_YEAR),
    Physics: generateYears(2009, FINAL_YEAR),
    Psychology: generateYears(2009, FINAL_YEAR),
    "Further Mathematics": generateYears(2009, FINAL_YEAR),
  },
  "Edexcel A Levels": {
    "Physics (WPH01)": generateYears(2009, FINAL_YEAR),
    "Physics (WPH11)": generateYears(2009, FINAL_YEAR),
    "Biology (WBI01)": generateYears(2009, FINAL_YEAR),
    "Chemistry (WCH01)": generateYears(2009, FINAL_YEAR),
    "Core Mathematics (WMA01)": generateYears(2009, FINAL_YEAR),
    "Core Mathematics (WMA02)": generateYears(2009, FINAL_YEAR),
    "Further Pure Mathematics (WFM01)": generateYears(2009, FINAL_YEAR),
    "Mathematics M1,M2,M3 (WME01)": generateYears(2009, FINAL_YEAR),
    "Biology (WBI11)": generateYears(2009, FINAL_YEAR),
    "Chemistry (WCH11)": generateYears(2009, FINAL_YEAR),
    "Pure Mathematics (WMA11)": generateYears(2009, FINAL_YEAR),
  },
  "IB Diploma": {
    Biology: generateYears(2009, FINAL_YEAR),
    Chemistry: generateYears(2009, FINAL_YEAR),
    Physics: generateYears(2009, FINAL_YEAR),
    "Mathematics AA": generateYears(2009, FINAL_YEAR),
    "Mathematics AI": generateYears(2009, FINAL_YEAR),
    Economics: generateYears(2009, FINAL_YEAR),
  },
};
