export interface Question {
  id: number;
  created_at: Date;
  images: string[];
  answer: string[];
  question_type: number;
  subject: string;
  board: string;
  season: string;
  year: number;
  variant: string;
  paper_number: string;
  question_number: number;
  topics: string[];
  level?: "SL" | "HL";
  average_difficulty: number | null;
  num_ratings: number;
  unit_type: string | null;
}

export enum QuestionType {
  MCQ = 0,
  THEORY = 1,
}
