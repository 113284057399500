import { colors } from "./colors";

export const styles = {
  boxShadow: "#1d1d1d14 0px 0px 30px",
  gradients: {
    primary:
      "linear-gradient(135deg, hsla(38, 91%, 68%, 1) 0%, hsla(289, 18%, 59%, 1) 54%, hsla(242, 85%, 60%, 1) 100%)",
  },
};

export const successSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.primary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};

export const errorSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.secondary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};
