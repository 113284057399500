import React from "react";

import styled from "styled-components";

interface AlertProps {
  message: string;
  isShow: boolean;
  setIsShow: (data: boolean) => void;
}

export default function Alert(props: AlertProps) {
  return <AlertContainer>{props.message}</AlertContainer>;
}

const AlertContainer = styled.div`
  position: relative;
  padding: 10px 20px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
`;
