import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import update from "immutability-helper";
import styled from "styled-components";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { StandardFonts, PDFDocument, PDFPage, degrees } from "pdf-lib";
import ReactCrop, { type Crop } from "react-image-crop";

import { supabase } from "../../../api/supabase";
import { Navbar } from "../../../components/Navbar";
import { colors } from "../../../theme/colors";
import { styles } from "../../../theme/styles";
import { Question, QuestionType } from "../../../types/Question";
import { generateSlug } from "../../../utils/generateSlug";
import { parseJson } from "../../../utils/parseJson";
import { QuestionCard } from "./QuestionCard";
import { capitalize } from "../../../utils/capitalize";
import { Spacer } from "../../../components/Spacer";
import { TextInput } from "../../../components/TextInput";
import {
  CheckIcon,
  Checkbox,
  Checkboxes,
} from "../../../components/Checkboxes";
import { FileUpload } from "../../../components/FileUpload";
import { Row } from "../../../widgets/Row";
import { ReduxState } from "../../../redux/store";
import { useSelector } from "react-redux";
import { wait } from "../../../utils/wait";

const MCQ_OPTIONS = ["A", "B", "C", "D"]; // Options for MCQs

enum PdfType {
  MARKSCHEME,
  WORKSHEET,
}

type WorksheetOptions = { title: string } & Partial<{
  logo: File;
  instructions: string[];
  examDate: string;
  subjectCode: string;
  questionDetails: boolean;
}>;

enum ModalType {
  QUESTION_VIEW,
  ADD_QUESTION,
  EDIT_QUESTION,
}

export const Builder = () => {
  const [questions, setQuestions] = useState<Question[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null); // 0 - question view; 1 - add question; null - modal not visible
  const [activeQuestion, setActiveQuestion] = useState<Question | null>(null);
  const [addingQuestions, setAddingQuestions] = useState(false);

  const [generatingWorksheet, setGeneratingWorksheet] = useState(false);
  const [generatingMarkscheme, setGeneratingMarkscheme] = useState(false);
  const [worksheetProgress, setWorksheetProgress] = useState(0);
  const [markschemeProgress, setMarkschemeProgress] = useState(0);
  const [crop, setCrop] = useState<Crop>();

  const userDetails = useSelector(
    (state: ReduxState) => state.auth?.userDetails || null
  );

  const [excludedImages, setExcludedImages] = useState<Record<string, boolean>>(
    {}
  );

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const { board, subject } = useParams();

  // Intramodal state
  const [activeTab, setActiveTab] = useState(0);

  const keyboardListener = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape" || event.key === "Esc") {
      setShowModal(false);
      setModalType(null);
    }
  }, []);

  const fetchQuestions = useCallback(
    async (ids: string[]) => {
      const currentIds = questions.map((question) => question.id);
      const newIds = ids.filter((id) => !currentIds.includes(Number(id)));

      if (newIds.length) {
        const { data: newQuestions } = await supabase
          .from("questions")
          .select("*")
          .in("id", newIds);

        setQuestions([...questions, ...(newQuestions as Question[])]);

        return newQuestions;
      }
    },
    [questions]
  );

  const persistQuestions = useCallback((newQuestions: Question[]) => {
    if (board && subject) {
      let storedQuestions = parseJson(
        localStorage.getItem("questions") || "{}"
      );

      storedQuestions = {
        ...storedQuestions,
        [board]: {
          ...(storedQuestions[board] || {}),
          [subject]: newQuestions.map((question) => question.id),
        },
      };

      localStorage.setItem("questions", JSON.stringify(storedQuestions));
    }
  }, []);

  const removeQuestion = useCallback(
    (id: number) => {
      const newQuestions = questions.filter((question) => question.id !== id);

      setQuestions(newQuestions);
      persistQuestions(newQuestions);
    },
    [questions]
  );

  useEffect(() => {
    // setQuestions([]);

    if (board && subject) {
      const ids = parseJson(localStorage.getItem("questions") || "{}");

      fetchQuestions((ids[board] || {})[subject] || []);
    }
  }, [board, subject]);

  useEffect(() => {
    document.addEventListener("keydown", keyboardListener);

    return () => {
      document.removeEventListener("keydown", keyboardListener);
    };
  }, []);

  useEffect(() => {
    if (!showModal) setModalType(null);
  }, [showModal]);

  useEffect(() => {
    (async function () {
      if (!showModal && addingQuestions) {
        setAddingQuestions(false);

        if (board && subject) {
          const ids = parseJson(localStorage.getItem("questions") || "{}");
          const selectedIds = (ids[board] || {})[subject] || [];

          if (selectedIds.length > questions.length) {
            fetchQuestions(
              selectedIds.slice(questions.length, selectedIds.length + 1)
            );
          }
        }
      }
    })();
  }, [showModal, addingQuestions]);

  useEffect(() => {
    if (modalType === ModalType.ADD_QUESTION) {
      setAddingQuestions(true);
    } else {
      setAddingQuestions(false);
    }
  }, [modalType]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setQuestions((prevQuestions) =>
      update(prevQuestions, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevQuestions[dragIndex]],
        ],
      })
    );
  }, []);
  var title: any;
  const addCoverPage = useCallback(
    async (
      documentType: PdfType,
      pdfDoc: PDFDocument,
      page: PDFPage,
      numPages: number,
      options?: WorksheetOptions
    ) => {
      let y = 1123 - 100;
      const margin = 64;

      // Add Markhint logo
      const imageBytes = await fetch("/assets/images/logo.png").then((res) =>
        res.arrayBuffer()
      );
      const image = await pdfDoc.embedPng(imageBytes);
      const imageDims = {
        width: 160,
        height: (160 / image.scale(1).width) * image.scale(1).height,
      };
      let logoMaxHeight = 0;

      if (options?.logo) {
        const customLogoImage =
          options.logo.type === "image/png"
            ? await pdfDoc.embedPng(await options.logo.arrayBuffer())
            : options.logo.type === "image/jpg" ||
              options.logo.type === "image/jpeg"
            ? await pdfDoc.embedJpg(await options.logo.arrayBuffer())
            : null;
        if (customLogoImage === null)
          alert("This image type is not supported!");
        else {
          const customLogoImageDims = {
            width: 160,
            height: (160 / image.scale(1).width) * image.scale(1).height,
          };
          logoMaxHeight = customLogoImageDims.height;

          page.drawImage(customLogoImage, {
            x: 630 - margin,
            y,
            width: customLogoImageDims.width,
            height: customLogoImageDims.height,
            opacity: 0.7,
          });
        }
      }

      page.drawImage(image, {
        x: margin,
        y,
        width: imageDims.width,
        height: imageDims.height,
        opacity: 0.7,
      });
      y -= Math.max(logoMaxHeight, imageDims.height);
      y -= 35;

      // Add Title
      const helvetica = await pdfDoc.embedFont(StandardFonts.Helvetica),
        helveticaBold = await pdfDoc.embedFont(StandardFonts.HelveticaBold);

      title =
        options?.title ??
        prompt(
          "Enter a title for the " +
            (documentType === PdfType.WORKSHEET ? "worksheet" : "markscheme"),
          documentType === PdfType.WORKSHEET
            ? "Topical Worksheet"
            : "Markscheme"
        ) ??
        (documentType === PdfType.WORKSHEET
          ? "Topical Worksheet"
          : "Markscheme");

      page.drawText(title, {
        font: helveticaBold,
        x: margin,
        y,
        size: 45,
      });
      y -= 65;

      // Add chapters
      page.drawText(
        `This document consists of ${numPages + 1} printed pages.`,
        {
          font: helveticaBold,
          x: margin,
          y,
          size: 21,
        }
      );
      y -= 40;
      if (options?.subjectCode && options?.examDate) {
        page.drawText(
          `Subject Code: ${options.subjectCode}          Exam Date: ${new Date(
            options.examDate
          ).toLocaleDateString("en-IN")}`,
          {
            font: helveticaBold,
            x: margin,
            y,
            size: 21,
          }
        );
        y -= 40;
      }

      page.drawText("Topics Included", {
        font: helveticaBold,
        x: margin,
        y,
        size: 21,
      });
      y -= 26;

      const chaptersSet = new Set(
        questions.reduce((acc, b) => [...acc, ...b.topics], new Array<string>())
      );
      const chapters: string[] = [];

      chaptersSet.forEach((chapter) => {
        chapters.push(
          capitalize(chapter.toLowerCase().trim()).replace("Ch", "CH")
        );
      });

      chapters.sort(
        (a, b) =>
          Number(a.split(" - ")[0].split(" ")[1]) -
          Number(b.split(" - ")[0].split(" ")[1])
      );

      chapters.forEach((chapter, i) => {
        page.drawText(`${chapter}`, {
          font: helvetica,
          x: margin + 20,
          y,
          size: 18,
        });
        y -= 23;
      });

      // Add instructions
      y -= 35;

      if (documentType === PdfType.WORKSHEET) {
        page.drawText("Instructions", {
          font: helveticaBold,
          x: margin,
          y,
          size: 21,
        });
        y -= 26;

        const instructions = options?.instructions ?? [
          "Answer all questions",
          "Write your answer to each question in the space provided",
          "Use a black or dark blue pen",
          "Write your name, grade, division, and roll number in the space given",
          "Do not use an erasable pen or correction fluid",
        ];
        instructions.forEach((instruction) => {
          page.drawText("•", {
            font: helveticaBold,
            x: margin + 20,
            y,
            size: 18,
          });
          page.drawText(
            ` ${instruction}${instruction.endsWith(".") ? "" : "."}`,
            {
              font: helvetica,
              x: margin + 30,
              y,
              size: 18,
            }
          );
          y -= 23;
        });
        y -= 35;
        // Add student details
        page.drawText("Student Details", {
          font: helveticaBold,
          x: margin,
          y,
          size: 22,
        });
        page.drawText("(must be filled)", {
          font: helvetica,
          x: margin + 165,
          y,
          size: 22,
        });
        y -= 32;
        page.drawText("Name", {
          font: helveticaBold,
          x: margin,
          y,
          size: 19,
        });
        page.drawText("Roll No", {
          font: helveticaBold,
          x: margin + 300,
          y,
          size: 19,
        });
        page.drawText("Grade/Div", {
          font: helveticaBold,
          x: 600,
          y,
          size: 19,
        });
        y -= 24;
        page.drawText("________________", {
          font: helvetica,
          x: margin,
          y,
          size: 19,
        });
        page.drawText("_______", {
          font: helvetica,
          x: margin + 300,
          y,
          size: 19,
        });
        page.drawText("______/_____", {
          font: helvetica,
          x: 600,
          y,
          size: 19,
        });

        y -= 70;
      }

      const numQuestions = questions.length;

      page.drawText("Marking", {
        font: helveticaBold,
        x: margin,
        y,
        size: 22,
      });
      documentType === PdfType.WORKSHEET &&
        page.drawText("(for teachers only)", {
          font: helvetica,
          x: margin + 90,
          y,
          size: 22,
        });
      y -= 32;

      if (numQuestions <= 12 && documentType === PdfType.WORKSHEET) {
        for (let i = 0; i < numQuestions; i++) {
          if (i % 4 === 0 && i !== 0) {
            y -= 58;
          }
          page.drawText(`Question ${i + 1}`, {
            font: helveticaBold,
            x: margin + 187 * (i % 4),
            y,
            size: 19,
          });
          page.drawText(`____/____`, {
            font: helvetica,
            x: margin + 187 * (i % 4),
            y: y - 24,
            size: 19,
          });
        }
      } else if (documentType === PdfType.MARKSCHEME || numQuestions > 12) {
        page.drawText(`This worksheet includes ${numQuestions} questions.`, {
          font: helveticaBold,
          x: margin,
          y,
          size: 19,
        });
        y += 60;
      }

      if (documentType === PdfType.WORKSHEET) {
        y -= 100;
        page.drawText(`Total`, {
          font: helveticaBold,
          x: margin,
          y,
          size: 19,
        });
        page.drawText(`Grade`, {
          font: helveticaBold,
          x: margin + 150,
          y,
          size: 19,
        });
        y -= 24;
        page.drawText(`____/____`, {
          font: helvetica,
          x: margin,
          y,
          size: 19,
        });
        page.drawText(`____`, {
          font: helvetica,
          x: margin + 150,
          y,
          size: 19,
        });
        y -= 50;

        page.drawText(`Signature`, {
          font: helveticaBold,
          x: margin,
          y,
          size: 19,
        });
        page.drawText(`Date Completed`, {
          font: helveticaBold,
          x: margin + 150,
          y,
          size: 19,
        });
        y -= 48;
        page.drawText(`__________`, {
          font: helvetica,
          x: margin,
          y,
          size: 19,
        });
        page.drawText(`___/___/______`, {
          font: helvetica,
          x: margin + 150,
          y,
          size: 19,
        });
      }

      return { title };
    },
    [questions]
  );

  const generatePdf = useCallback(
    async (type: PdfType, options?: WorksheetOptions) => {
      if (type === PdfType.WORKSHEET) {
        setGeneratingWorksheet(true);
        setWorksheetProgress(0);
      } else {
        setGeneratingMarkscheme(true);
        setMarkschemeProgress(0);
      }
      try {
        const imagesList = questions.reduce(
          (list, question, questionNumber) => {
            const images: {
              url: string;
              id: string;
              question: number;
              mcq: boolean;
              slug: string;
            }[] = [];

            (type === PdfType.WORKSHEET
              ? question.images
              : question.answer
            ).forEach((image, i) => {
              images.push({
                url:
                  (question.question_type === QuestionType.MCQ &&
                  type === PdfType.MARKSCHEME
                    ? ""
                    : "https://qnpumhfafqmcymbxzuvc.functions.supabase.co/get-image?url=") +
                  image,
                id: String(question.id) + "_" + i,
                question: questionNumber,
                mcq: question.question_type === QuestionType.MCQ,
                slug: generateSlug(question),
              });
            });

            return [...list, ...images];
          },
          [] as {
            url: string;
            id: string;
            question: number;
            mcq: boolean;
            slug: string;
          }[]
        );
        if (type === PdfType.WORKSHEET) {
          setWorksheetProgress(1 / (imagesList.length + 3));
        } else {
          setMarkschemeProgress(1 / (imagesList.length + 3));
        }

        const pdfDoc = await PDFDocument.create();

        const helveticaFont = await pdfDoc.embedFont(StandardFonts.Helvetica);

        let numPages = 1;

        const coverPage = pdfDoc.addPage();
        coverPage.setSize(794, 1123);

        const page = pdfDoc.addPage();
        page.setSize(794, 1123);

        let yPosition = 1123 - 20;
        const images: Record<string, Record<string, any>> = {};

        let fetchedImages = 0;

        await Promise.all(
          imagesList.map(async ({ url, id, mcq }) => {
            console.log(excludedImages);
            console.log(excludedImages[url.split("url=")[1]]);

            if (mcq && type === PdfType.MARKSCHEME) {
              images[id] = {
                image: url,
                dims: { width: 0, height: 0 },
                mcq: true,
              };
            } else if (!excludedImages[url.split("url=")[1]]) {
              const imageBytes = await fetch(url).then((res) =>
                res.arrayBuffer()
              );
              const image = await pdfDoc.embedPng(imageBytes);
              const imageDims = {
                width: 794 - 140,
                height:
                  ((794 - 80) / image.scale(1).width) * image.scale(1).height,
              };

              images[id] = { image, dims: imageDims, mcq: false };

              if (type === PdfType.WORKSHEET) {
                setWorksheetProgress(++fetchedImages / (imagesList.length + 3));
              } else {
                setMarkschemeProgress(
                  ++fetchedImages / (imagesList.length + 3)
                );
              }
            }
          })
        );

        imagesList.forEach(({ id, question, mcq }) => {
          const image = images[id];

          if (mcq && type === PdfType.MARKSCHEME) {
            yPosition -= 20;
          } else if (image) {
            if (image.dims.height > yPosition) {
              yPosition = 1123 - 20;
              yPosition -= image.dims.height + 20;
              numPages++;
            } else {
              yPosition -= image.dims.height;
            }
          }
        });

        // Add cover page
        const { title } = await addCoverPage(
          type,
          pdfDoc,
          coverPage,
          numPages,
          options
        );

        let activePage = page;

        activePage.drawText("Generated using Markhint (markhint.in)", {
          x: 20,
          y: 10,
          size: 12,
          font: helveticaFont,
        });
        const imageBytes = await fetch("/assets/images/logo.png").then((res) =>
          res.arrayBuffer()
        );
        const logo = await pdfDoc.embedPng(imageBytes);
        const logoDims = {
          width: 160,
          height: (160 / logo.scale(1).width) * logo.scale(1).height,
        };

        yPosition = 1123 - 20;
        const customLogoImage = options?.logo
          ? options.logo.type === "image/png"
            ? await pdfDoc.embedPng(await options.logo.arrayBuffer())
            : options.logo.type === "image/jpg" ||
              options.logo.type === "image/jpeg"
            ? await pdfDoc.embedJpg(await options.logo.arrayBuffer())
            : null
          : null;
        let customLogoImageDims: {
          width: number | null;
          height: number | null;
        } = { width: null, height: null };
        if (customLogoImage !== null) {
          customLogoImageDims = {
            width: 160,
            height:
              (customLogoImage.scale(1).height /
                customLogoImage.scale(1).width) *
              160,
          };
        }

        imagesList.forEach(({ id, question, mcq, slug }) => {
          const image = images[id];

          if (mcq && type === PdfType.MARKSCHEME) {
            activePage.drawText(String(question + 1) + " " + image.image, {
              x: 20,
              y: yPosition - 20,
              font: helveticaFont,
            });
            yPosition -= 20;
          } else if (image) {
            if (image.dims.height > yPosition) {
              yPosition = 1123 - 20;
              activePage.drawImage(customLogoImage ?? logo, {
                x: 397 - (customLogoImageDims.width || logoDims.width) / 2,
                y: 561 - (customLogoImageDims.height || logoDims.height) / 2,
                width: customLogoImageDims.width || logoDims.width,
                height: customLogoImageDims.height || logoDims.height,
                opacity: 0.3,
                rotate: degrees(45),
              });
              activePage = pdfDoc.addPage();
              activePage.drawText("Generated using Markhint (markhint.in)", {
                x: 20,
                y: 10,
                size: 12,
                font: helveticaFont,
              });
              activePage.setSize(794, 1123);
              id.split("_")[1] === "0" &&
                activePage.drawText(String(question + 1), {
                  x: 20,
                  y: yPosition - 20,
                  font: helveticaFont,
                });
              activePage.drawImage(image.image, {
                x: 60,
                y: yPosition - image.dims.height,
                width: image.dims.width,
                height: image.dims.height,
              });
              options?.questionDetails &&
                activePage.drawText(slug, {
                  x: 794 - 40,
                  y: yPosition - 20,
                  font: helveticaFont,
                  rotate: degrees(-90),
                  opacity: 0.6,
                  size: 8,
                });

              yPosition -= image.dims.height + 20;
            } else if (image) {
              id.split("_")[1] === "0" &&
                activePage.drawText(String(question + 1), {
                  x: 20,
                  y: yPosition - 20,
                  font: helveticaFont,
                });
              activePage.drawImage(image.image, {
                x: 60,
                y: yPosition - image.dims.height,
                width: image.dims.width,
                height: image.dims.height,
              });
              options?.questionDetails &&
                activePage.drawText(slug, {
                  x: 794 - 40,
                  y: yPosition - 20,
                  font: helveticaFont,
                  rotate: degrees(-90),
                  opacity: 0.6,
                  size: 8,
                });

              yPosition -= image.dims.height;
            }
          }
        });

        activePage.drawImage(customLogoImage ?? logo, {
          x: 397 - (customLogoImageDims.width || logoDims.width) / 2,
          y: 561 - (customLogoImageDims.height || logoDims.height) / 2,
          width: customLogoImageDims.width || logoDims.width,
          height: customLogoImageDims.height || logoDims.height,
          opacity: 0.3,
          rotate: degrees(45),
        });

        const pdfBytes = await pdfDoc.save();

        const blob = new Blob([pdfBytes], {
          type: "application/pdf",
        });
        // const link = document.createElement("a");
        // link.target = "_blank";
        // link.href = window.URL.createObjectURL(blob);
        // const fileName = `${board}_${subject}_${
        //   type === PdfType.WORKSHEET ? "QP" : "MS"
        // }_${new Date().toISOString()}.pdf`;
        // link.download = title;
        // link.click();
        const isSafari = navigator.userAgent.indexOf("Safari") != -1;
        isSafari && alert("Opening PDF");
        window.open(window.URL.createObjectURL(blob), "_blank");
      } catch (e) {
        console.log(e);

        alert("There was an error!");
        alert((e as any)?.message);
      } finally {
        if (type === PdfType.WORKSHEET) {
          setGeneratingWorksheet(false);
          setWorksheetProgress(0);
        } else {
          setGeneratingMarkscheme(false);
          setMarkschemeProgress(0);
        }
      }
    },
    [questions, excludedImages]
  );

  return (
    <Main>
      <SidebarOverlay
        show={sidebarVisible}
        onClick={() => setSidebarVisible((sidebarVisible) => !sidebarVisible)}
      ></SidebarOverlay>
      <Overlay show={showOptionsModal}>
        <OptionsModal
          setShowModal={setShowOptionsModal}
          generatePdf={generatePdf}
          worksheetProgress={worksheetProgress}
          markschemeProgress={markschemeProgress}
          generatingWorksheet={generatingWorksheet}
          generatingMarkscheme={generatingMarkscheme}
          subjectCode={subject}
          board={board}
          allowCustomLogo={userDetails?.permissions?.custom_watermark}
        />
      </Overlay>
      <Overlay show={showModal}>
        <Modal
          style={{
            maxHeight: modalType === ModalType.QUESTION_VIEW ? "50vh" : "75vh",
            maxWidth: modalType === ModalType.QUESTION_VIEW ? "45vw" : "60vw",
            minWidth: modalType === ModalType.QUESTION_VIEW ? "45vw" : "60vw",
          }}
        >
          {modalType === ModalType.QUESTION_VIEW && (
            <>
              <TopRow>
                <QuestionSlug>
                  {generateSlug(activeQuestion ?? ({} as Question))}
                </QuestionSlug>
                <CloseButton
                  src="/assets/icons/close.svg"
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </TopRow>
              <TabsContainer>
                <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                  Question
                </Tab>
                <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                  Answer
                </Tab>
              </TabsContainer>
              {(activeTab === 0
                ? activeQuestion!.images
                : activeQuestion!.answer
              ).map((image, index) =>
                image.length === 1 ? (
                  <MCQOptions>
                    {MCQ_OPTIONS.map((option) => (
                      <MCQOption
                        key={Math.random().toString(36)}
                        active={option === image}
                      >
                        {option}
                      </MCQOption>
                    ))}
                  </MCQOptions>
                ) : (
                  <>
                    <Checkbox
                      style={{ minHeight: "2rem", cursor: "pointer" }}
                      checked={!excludedImages[image]}
                      onClick={() => {
                        const newExcludedImages = { ...excludedImages };
                        newExcludedImages[image] = !newExcludedImages[image];

                        setExcludedImages(newExcludedImages);
                      }}
                    >
                      {!excludedImages[image] && <CheckIcon />}
                    </Checkbox>
                    {index === 0 ? (
                      <ReactCrop
                        crop={crop}
                        onChange={(_, crop) => setCrop(crop)}
                      >
                        <img
                          style={{ width: "10vw" }}
                          src={image}
                          key={Math.random().toString(36)}
                        />
                      </ReactCrop>
                    ) : (
                      <QuestionImage
                        src={image}
                        key={Math.random().toString(36)}
                      />
                    )}
                  </>
                )
              )}
            </>
          )}
          {modalType === ModalType.ADD_QUESTION && (
            <>
              <TopRow>
                <div></div>
                <CloseButton
                  src="/assets/icons/close.svg"
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </TopRow>
              <iframe
                width="100%"
                height="764px"
                src="/topical?iframe=true"
                style={{
                  border: "none",
                  outline: "none",
                  borderRadius: "0.75rem",
                }}
              ></iframe>
            </>
          )}
          {modalType === ModalType.EDIT_QUESTION && (
            <>
              <TopRow>
                <div></div>
                <CloseButton
                  src="/assets/icons/close.svg"
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </TopRow>
            </>
          )}
        </Modal>
      </Overlay>
      <Navbar />
      <ButtonsContainer>
        <DownloadButton
          onClick={() => setShowOptionsModal(true)}
          progress={generatingWorksheet ? worksheetProgress : 0}
          disabled={questions.length === 0}
        >
          Generate Worksheet
        </DownloadButton>
        {/* <DownloadButton
          onClick={() => generatePdf(PdfType.MARKSCHEME)}
          progress={generatingMarkscheme ? markschemeProgress : 0}
          disabled={questions.length === 0}
        >
          Download Markscheme
        </DownloadButton> */}
      </ButtonsContainer>
      <div style={{ width: "100%", display: "flex", paddingLeft: "6rem" }}>
        <MobileMenuContainer
          onClick={() => {
            setSidebarVisible((sidebarVisible) => !sidebarVisible);
          }}
        >
          {sidebarVisible ? <CloseIcon /> : <MenuIcon />}
        </MobileMenuContainer>
      </div>
      <Content>
        <DndProvider backend={HTML5Backend}>
          <SidebarContainer show={sidebarVisible}>
            <ListContainer>
              {questions.map((question, index) => (
                <div
                  key={question.id}
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <QuestionCard
                    onClick={() => {
                      setShowModal(true);
                      setModalType(ModalType.QUESTION_VIEW);
                      setActiveQuestion(question);
                    }}
                    index={index}
                    moveCard={moveCard}
                    question={question}
                  />
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <RemoveButton onClick={() => removeQuestion(question.id)}>
                      <img src="/assets/icons/close.svg" />
                    </RemoveButton>
                    {/* <RemoveButton
                      onClick={() => {
                        setShowModal(true);
                        setModalType(ModalType.EDIT_QUESTION);
                      }}
                    >
                      <img src="/assets/icons/edit.svg" />
                    </RemoveButton> */}
                  </div>
                </div>
              ))}
              <AddButton
                onClick={() => {
                  setShowModal(true);
                  setModalType(ModalType.ADD_QUESTION);
                }}
              >
                Add
              </AddButton>
            </ListContainer>
          </SidebarContainer>
        </DndProvider>

        <PreviewContainer>
          {questions.map((question) =>
            question.images.map((image) => (
              <QuestionImage
                src={
                  "https://qnpumhfafqmcymbxzuvc.functions.supabase.co/get-image?url=" +
                  question.images[0].replace("https://www.exam-mate.com/", "")
                }
                key={Math.random().toString(36)}
              />
            ))
          )}
        </PreviewContainer>
      </Content>
    </Main>
  );
};

const OptionsModal = ({
  setShowModal,
  generatePdf,
  worksheetProgress,
  markschemeProgress,
  generatingWorksheet,
  generatingMarkscheme,
  subjectCode,
  board,
  allowCustomLogo,
}: {
  setShowModal: (show: boolean) => void;
  generatePdf: (type: PdfType, options?: WorksheetOptions) => Promise<void>;
  worksheetProgress: number;
  markschemeProgress: number;
  generatingWorksheet: boolean;
  generatingMarkscheme: boolean;
  subjectCode?: string;
  board?: string;
  allowCustomLogo?: boolean;
}) => {
  const [title, setTitle] = useState("");
  const [coverPageElements, setCoverPageElements] = useState<string[]>([
    "exam-date",
  ]);
  const [customisations, setCustomisations] = useState<string[]>([]);
  const [deleteItems, setDeleteItems] = useState(["delete-items"]);
  const [logo, setLogo] = useState<File | null>(null);
  const [instructions, setInstructions] = useState<string[]>([]);
  const [activeInstruction, setActiveInstruction] = useState("");
  const [examDate, setExamDate] = useState("");

  const isGenerateDisabled =
    (coverPageElements.includes("custom-logo") && !logo) ||
    (coverPageElements.includes("instructions") && !instructions.length) ||
    (coverPageElements.includes("exam-date") && !examDate) ||
    !title;

  return (
    <Modal
      style={{
        maxWidth: "45vw",
        minWidth: "45vw",
      }}
    >
      <TopRow>
        <ModalTitle>Worksheet Options</ModalTitle>
        <CloseButton
          src="/assets/icons/close.svg"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </TopRow>
      <Spacer size={10} direction="bottom" />
      <FormContainer>
        <TextInput
          label="Worksheet Title"
          placeholder="Enter title"
          value={title}
          onChange={setTitle}
        />
        <Checkboxes
          label="Cover page"
          options={[
            ...(allowCustomLogo
              ? [{ label: "Custom Logo", value: "custom-logo" }]
              : []),
            { label: "Custom Instructions", value: "instructions" },
            { label: "Exam Date", value: "exam-date" },
          ]}
          values={coverPageElements}
          setValues={setCoverPageElements}
        />
        <Spacer size={5} direction="bottom" />
        <Checkboxes
          label="Customisations"
          options={[{ label: "Question Details", value: "question-details" }]}
          values={customisations}
          setValues={setCustomisations}
        />
        <Spacer size={5} direction="bottom" />
        {coverPageElements.includes("custom-logo") && (
          <FileUpload file={logo} setFile={setLogo} label="Logo" />
        )}
        <Spacer size={10} direction="bottom" />
        {coverPageElements.includes("instructions") && (
          <>
            <Label>Instructions</Label>
            <ul style={{ marginLeft: "1.5rem" }}>
              {instructions.map((instruction, i) => (
                <Row
                  style={{ alignItems: "center" }}
                  key={Math.random().toString(36)}
                >
                  <Instruction>{instruction}</Instruction>
                  <CloseButton
                    src="/assets/icons/close.svg"
                    style={{ transform: "scale(0.8)" }}
                    onClick={() => {
                      const newInstructions = [...instructions];
                      newInstructions.splice(i, 1);
                      setInstructions(newInstructions);
                    }}
                  />
                </Row>
              ))}
            </ul>
            <Spacer size={20} direction="bottom" />
            <Row gap={20}>
              <TextInput
                label=""
                placeholder="Type instruction"
                value={activeInstruction}
                onChange={setActiveInstruction}
              />
              <AddInstruction
                disabled={!activeInstruction}
                onClick={() => {
                  setInstructions([...instructions, activeInstruction]);
                  setActiveInstruction("");
                }}
              >
                Add Instruction
              </AddInstruction>
            </Row>
          </>
        )}
        <Spacer size={20} direction="bottom" />
        {coverPageElements.includes("exam-date") && (
          <TextInput
            type="date"
            label="Exam Date"
            placeholder="Enter exam date"
            value={examDate}
            onChange={setExamDate}
          />
        )}
        <Spacer size={10} direction="bottom" />
        <Checkboxes
          label=""
          options={[
            {
              label: "Delete questions from list after generation",
              value: "delete-items",
            },
          ]}
          values={deleteItems}
          setValues={setDeleteItems}
        />
        <Spacer size={20} direction="bottom" />
        <DownloadButton
          onClick={async () => {
            const options: WorksheetOptions = { title };
            if (coverPageElements.includes("custom-logo") && logo)
              options.logo = logo;
            if (
              coverPageElements.includes("instructions") &&
              instructions?.length
            )
              options.instructions = instructions;
            if (
              coverPageElements.includes("exam-date") &&
              examDate &&
              !Number.isNaN(+new Date(examDate))
            )
              options.examDate = examDate;
            if (customisations.includes("question-details"))
              options.questionDetails = true;
            if (subjectCode) options.subjectCode = subjectCode;

            await generatePdf(PdfType.WORKSHEET, options);
            options.title += " MS";
            await wait(2000);
            await generatePdf(PdfType.MARKSCHEME, options);

            if (deleteItems.includes("delete-items") && board && subjectCode) {
              const questions = parseJson(localStorage.getItem("questions"));
              questions[board][subjectCode!] = [];

              localStorage.setItem("questions", JSON.stringify(questions));
            }
          }}
          progress={
            generatingWorksheet
              ? worksheetProgress
              : generatingMarkscheme
              ? markschemeProgress
              : 0
          }
          disabled={isGenerateDisabled}
        >
          Generate Worksheet
        </DownloadButton>
      </FormContainer>
    </Modal>
  );
};

const AddInstruction = styled.button`
  font-weight: 600;
  font-size: 1.4rem;
  color: ${colors.primary.hex};
  opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  outline: none;
  border: none;
  background-color: transparent;
  width: fit-content;
`;

const Instruction = styled.li`
  font-size: 1.4rem;
  font-weight: 400;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;

const Heading = styled.div`
  font-size: 6rem;
  margin-bottom: 6rem;
  font-weight: 500;
  text-align: center;
`;

const Content = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`;

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  width: 100%;
  max-height: calc(100vh - 14rem);
  min-height: calc(100vh - 14rem);
  overflow-y: scroll;
  background-color: #fff;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const MCQOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MCQOption = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : colors.white.hex};
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ active }: { active?: boolean }) =>
    active ? colors.white.hex : colors.text.hex};
  animation: ${({ active }: { active?: boolean }) =>
    active ? "pulse 2s both infinite" : "none"};

  &::after {
    content: ${({ active }: { active?: boolean }) => (active ? '"←"' : '""')};
    position: absolute;
    left: 0;
    margin-left: 5rem;
    color: ${colors.primary.hex};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
    40% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(0.95);
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;

  display: ${({ show = false }: { show?: boolean }) =>
    show ? "flex" : "none"};
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  z-index: 5;
  background-color: #1d1d1da8;
`;

const Modal = styled.div`
  display: flex;
  flex-direction: column;

  padding: 2rem;
  min-width: 45vw;
  max-width: 45vw;
  max-height: 80vh;
  overflow-y: scroll;
  background-color: #fff;
  border-radius: 1rem;
  box-shadow: ${styles.boxShadow};

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

const QuestionSlug = styled.h3`
  font-size: 1.5rem;
  color: ${colors.text.hex};
  font-weight: 600;
`;

const ModalTitle = styled.h3`
  font-size: 2rem;
  color: ${colors.text.hex};
  font-weight: 600;
`;

const CloseButton = styled.img`
  width: 3rem;
  height: 3rem;
  cursor: pointer;
`;

const Label = styled.label`
  font-size: 1.5rem;
  font-weight: 500;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

const QuestionImage = styled.img`
  width: 100%;
`;

const AddButton = styled.button`
  padding: 1rem 5rem;
  background-color: ${colors.white.hex};
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: ${colors.text.hex};
  width: fit-content;
  align-self: center;
  margin-top: 2rem;
  outline: none;
  border: none;
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const Tab = styled.div`
  padding: 1rem 2rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.text.hex : "transparent"};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 1rem;
`;

const RemoveButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.25rem;
  height: fit-content;
  background-color: transparent;
  outline: none;
  border: none;

  &:hover {
    background-color: ${colors.white.hex};
  }

  & img {
    width: 2rem;
  }
`;

const DownloadButton = styled.button`
  padding: 1.5rem 2.5rem;
  border-radius: 0.5rem;
  background-color: ${colors.primary.hex};
  font-size: 1.7rem;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  transform: scale(0.8);
  height: fit-content;
  opacity: ${({ disabled }: { disabled: boolean; progress: number }) =>
    disabled ? 0.5 : 1};
  cursor: ${({ disabled }: { disabled: boolean; progress: number }) =>
    disabled ? "not-allowed" : "pointer"};

  &::before {
    content: "";
    background-color: #b5e48c;
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }: { disabled: boolean; progress: number }) =>
      progress * 100}%;
    transition: width 0.01s, border-radius 0.1s;
    height: 100%;
    z-index: -1;
    border-radius: ${({ progress }: { disabled: boolean; progress: number }) =>
      progress < 1 ? "0.5rem 0 0 0.5rem" : "0.5rem"};
  }
`;

const PreviewContainer = styled.div`
  flex: 1;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  margin: 0 2rem;
  max-height: 80%;
  min-height: 80%;
  overflow-y: scroll;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  height: fit-content;
  margin-top: 2rem;
  width: 100vw;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30vw;
  position: relative;
  min-width: 30rem;

  @media only screen and (max-width: 59.375em) {
    position: absolute;
    top: 20rem;
    left: 7rem;
    z-index: 10;
    height: calc(80vh - 1rem);
    opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
    transform: ${({ show }: { show: boolean }) =>
      show ? "translate(0)" : "translate(-100%)"};
    transition: opacity 1s, transform 1s;
  }
`;

const SidebarOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  width: 100vw;
  height: 100vh;
  z-index: 9;
  display: ${({ show }: { show: boolean }) => (show ? "block" : "none")};
`;

const MobileMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.5s, opacity 1s;
  margin: 0.5rem 0;
  z-index: 10;

  &:hover {
    background-color: ${colors.white.hex};

    & > img {
      opacity: 50%;
    }
  }

  @media only screen and (min-width: 59.375em) {
    display: none;
  }
`;

const CloseIcon = styled.img.attrs({
  src: "/assets/icons/close.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const MenuIcon = styled.img.attrs({
  src: "/assets/icons/menu.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;
