import { configureStore } from "@reduxjs/toolkit";
import authReducer, { AuthState } from "./slices/auth.slice";
import themeReducer, { ThemeState } from "./slices/theme.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    theme: themeReducer,
  },
});

export interface ReduxState {
  auth: AuthState;
  theme: ThemeState;
}
