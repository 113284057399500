import React from "react";
import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import { Content } from "./components/content.component";
import { Navbar } from "./components/navbar.component";

// const getRandomScale = () => 0.75 + Math.random() * 0.75;

// const CIRCLES: { x: number; y: number; scale?: number }[] = [
//   { x: 0.15, y: 0.2, scale: getRandomScale() },
//   { x: 0.5, y: 0.15, scale: getRandomScale() },
//   { x: 0.45, y: 0.5, scale: getRandomScale() },
//   { x: 0.75, y: 0.35, scale: getRandomScale() },
//   { x: 0.85, y: 0.7, scale: getRandomScale() },
//   { x: 0.1, y: 0.65, scale: getRandomScale() },
//   { x: 0.4, y: 0.85, scale: getRandomScale() },
// ];

export const HeroSection = () => {
  return (
    <Container>
      {/* {CIRCLES.map(({ x, y, scale = 1 }, i: number) => {
        return (
          <Circle
            key={`Circle__${i}`}
            style={{
              top: y * window.innerHeight,
              left: x * window.innerWidth,
              transform: `scale(${scale})`,
            }}
          ></Circle>
        );
      })} */}
      <Navbar />
      <Content />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  /* background-color: ${colors.primary.hex}; */
  padding: 5rem 10rem;

  @media only screen and (max-width: 48.75em) {
    padding: 5rem;
  }
`;

// const Circle = styled.div`
//   position: absolute;
//   width: 12.5rem;
//   height: 12.5rem;
//   opacity: 5%;
//   border-radius: 50%;
//   background-color: ${colors.primary.hex};
//   z-index: -1;
//   animation: fadeIn 2s;

//   @keyframes fadeIn {
//     0% {
//       opacity: 0;
//     }
//     100% {
//       opacity: 5%;
//     }
//   }
// `;
