import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { Loader } from "../../../../../components/Loader";
import { colors } from "../../../../../theme/colors";
import { useNavigate } from "react-router-dom";

export const Content = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [clicked, setClicked] = useState(false);

  const navigate = useNavigate();

  const onClick = useCallback(() => {
    setClicked(true);

    navigate(`/search?query=${searchQuery}`);
  }, [setClicked, searchQuery, navigate]);

  return (
    <ContentContainer>
      <Title>
        Prepare for exams <span>5&times;</span> faster
      </Title>
      <Paragraph>
        Find answers to any Cambridge past paper question and revise efficiently
        for exams with our topical paper search. Track your progress to improve
        your scores.
      </Paragraph>
      <SearchContainer>
        <form
          action=""
          method=""
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <SearchInput
            placeholder="Type any question"
            value={searchQuery}
            onChange={(evt) => {
              setSearchQuery((evt.target as HTMLInputElement).value);
            }}
          />
          <SearchButton clicked={clicked} onClick={onClick}>
            {clicked && (
              <Loader style={{ width: "3.5rem", height: "3.5rem" }} />
            )}
            Find answer
          </SearchButton>
        </form>
      </SearchContainer>
      <SignupCTA onClick={() => navigate("/topical")}>Get Started</SignupCTA>
    </ContentContainer>
  );
};

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  position: relative;
  font-size: 7rem;
  font-weight: 600;
  text-align: center;
  width: 100%;

  & span {
    color: ${colors.primary.hex};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    animation: unravel 4s forwards;
  }

  @keyframes unravel {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-150%);
    }
  }

  @media only screen and (max-width: 375px) {
    margin-top: 10rem;
  }

  @media only screen and (max-width: 325px) {
    margin-top: 15rem;
  }
`;

const Paragraph = styled.p`
  font-weight: 500;
  font-size: 1.75rem;
  width: 60%;
  text-align: center;
  margin-top: 1rem;
  animation: fadeInUp 2s forwards;

  @media only screen and (max-width: 34.375em) {
    width: 75%;
  }
  @media only screen and (max-width: 28.125em) {
    width: 100%;
  }

  @keyframes fadeInUp {
    0% {
      transform: translateY(10%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
`;

const SearchContainer = styled.div`
  position: relative;
  width: 50%;
  height: fit-content;
  transform: translateY(5rem);

  @media only screen and (max-width: 58.125em) {
    width: 75%;
  }

  @media only screen and (max-width: 28.125em) {
    display: none;
  }
`;

const SearchInput = styled.input`
  background-color: #fff;
  border: 1px solid ${colors.text.hex};
  padding: 1.8rem 2rem;

  border-radius: 1rem;
  outline: none;
  width: 100%;

  transition: box-shadow 0.2s ease-in-out;

  &:focus {
    box-shadow: #1d1d1d1c 5px 5px 20px;
  }
`;

const SearchButton = styled.button`
  position: absolute;
  width: fit-content;
  top: 50%;
  right: 0;
  padding: 1.8rem 2rem;
  background-color: ${colors.text.hex};
  color: ${({ clicked }: { clicked: boolean }) =>
    clicked ? "transparent" : colors.white.hex};
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: ${({ clicked }: { clicked: boolean }) =>
    clicked ? "0.75rem" : "0 0.75rem 0.75rem 0"};
  transform: ${({ clicked }: { clicked: boolean }) =>
    clicked ? "translateY(-50%) scaleY(0.8) scaleX(0.9)" : "translateY(-50%)"};
  transition: transform 0.1s ease-in-out, border-radius 0.1s ease-in;

  /* &:active {
    transform: translateY(-50%) scaleY(0.8) scaleX(0.9);
    border-radius: 0.75rem;
  } */
`;

const SignupCTA = styled.button`
  padding: 1.5rem 2rem;
  background-color: ${colors.text.hex};
  color: ${colors.white.hex};
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 10rem;
  margin-top: 2rem;
  width: 50%;

  @media only screen and (min-width: 28.125em) {
    display: none;
  }
`;
