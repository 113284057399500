export enum TaskType {
  TOPICAL = 0,
  PAST_PAPER = 1,
  TEST = 2,
  REVISE = 3,
  OTHER = 4,
}

export interface Task {
  title: string;
  id: string;
  date?: Date | null;
  start_time: Date | null;
  end_time: Date | null;
  subject: string;
  tag: TaskType;
  complete: boolean;
  created_at?: Date;
}
