import React from "react";

export const Spacer = ({
  size,
  direction,
}: {
  size: number;
  direction: "top" | "bottom" | "left" | "right";
}) => {
  return (
    <div
      style={{
        [`margin${
          direction[0].toUpperCase() + direction.slice(1)
        }`]: `${size}px`,
      }}
    ></div>
  );
};
