import { Color } from "../utils/Color";

export const colors = {
  text: Color.fromHex("#1D1D1D"),
  white: Color.fromHex("#F5F5F5"),
  primary: Color.fromHex("#4E47FF"),
  secondary: Color.fromHex("#F8C365"),
  red: Color.fromHex("#ff3c38"),
  green: Color.fromHex("#4ad66d"),
};
