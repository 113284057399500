import { createSlice } from "@reduxjs/toolkit";

export enum Theme {
  DARK,
  LIGHT,
}

export interface ThemeState {
  theme: Theme;
}

export const themeSlice = createSlice<
  ThemeState,
  { setTheme: (state: ThemeState, action: { payload: Theme }) => void },
  "theme"
>({
  name: "theme",
  initialState: {
    theme: localStorage.getItem("theme") === "dark" ? Theme.DARK : Theme.LIGHT,
  },
  reducers: {
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
  },
});

export const { setTheme } = themeSlice.actions;

export default themeSlice.reducer;
