import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch, useSelector } from "react-redux";

import { supabase } from "../../api/supabase";
import { Loader } from "../../components/Loader";
import { Navbar } from "../../components/Navbar";
import { colors } from "../../theme/colors";
import { styles } from "../../theme/styles";
import { Question, QuestionType } from "../../types/Question";
import { parseJson } from "../../utils/parseJson";
import { Board } from "../../types/Board";
import { Toggle } from "../../components/Toggle";
import { Bubble } from "../../widgets/Bubble";
import { Row as RowComponent } from "../../widgets/Row";
import { Spacer } from "../../components/Spacer";
import { Color } from "../../utils/Color";
import { ReduxState } from "../../redux/store";
import { addRatedQuestion } from "../../redux/slices/auth.slice";

const PAGE_SIZE = 10; // Number of items in a page
const MCQ_OPTIONS = ["A", "B", "C", "D"]; // Options for MCQs

export const Results = () => {
  const [results, setResults] = useState<Question[][]>([]); // Array of results by page
  const [pages, setPages] = useState(1); // Total number of pages of size PAGE_SIZE
  const [activePage, setActivePage] = useState(0); // Active page
  const [activeTab, setActiveTab] = useState<0 | 1>(0); // Active tab - Question (0) or Answer(1)
  const [loadedPage, setLoadedPage] = useState(false); // Loaded page number from query parameters into state

  const [fetchingPages, setFetchingPages] = useState<Record<number, boolean>>(
    []
  );
  const [fetchedPages, setFetchedPages] = useState<number[]>([]);
  const [boardsList, setBoardsList] = useState<Board[]>([]);

  const [builderQuestions, setBuilderQuestions] = useState<(number | string)[]>(
    []
  );

  const { board, subject } = useParams(); // Board and subject to query
  const [searchParams, setSearchParams] = useSearchParams(); // Search params

  const [selectedQuestion, setSelectedQuestion] = useState<number | null>(null); // Active question ID
  const [selectedQuestionImage, setSelectedQuestionImage] = useState<string[]>(
    []
  ); // Image URLs for active question
  const [selectedQuestionAnswer, setSelectedQuestionAnswer] = useState<
    string[]
  >([]); // Image URLs for active question's answer
  const [selectedQuestionType, setSelectedQuestionType] = useState<0 | 1>(0); // Active question type - MCQ (0) or Theory (1)
  const [selectedQuestionData, setSelectedQuestionData] =
    useState<Question | null>(null);

  const [completedQuestions, setCompletedQuestions] = useState<
    Record<string | number, boolean>
  >({}); // Map of completed questions
  const [questionCompleted, setQuestionCompleted] = useState(false); // The question's completion status

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const navigate = useNavigate();

  const [openSuccessSnackbar] = useSnackbar(successSnackbarStyles);
  const [openErrorSnackbar] = useSnackbar(errorSnackbarStyles);

  const dispatch = useDispatch();
  const userDetails = useSelector(
    (state: ReduxState) => state.auth.userDetails
  );

  // When the page loads, fetch the questions added to the builder from LocalStorage
  useEffect(() => {
    if (!(board && subject)) return;
    const questions = parseJson(localStorage.getItem("questions") || "{}");

    if (questions[board] && questions[board][subject]) {
      setBuilderQuestions(questions[board][subject]);
    }
  }, [board, subject]);

  // When results are fetched, set the active question to the first result
  useEffect(() => {
    if (results.length && results[0]?.length) {
      setSelectedQuestion(results[0][0].id);
    }
  }, [results]);

  // Runs when user selects a new question
  // Sets accompanying details - selectedQuestionImage, selectedQuestionAnswer and selectedQuestionType
  useEffect(() => {
    if (selectedQuestion) {
      if (results[activePage]) {
        const result = results[activePage].find(
          (result) => result.id === selectedQuestion
        );
        if (result) {
          setSelectedQuestionImage(result.images);
          setSelectedQuestionAnswer(result.answer);
          setSelectedQuestionType(result.question_type as 0 | 1);
          setSelectedQuestionData(result);
        }
      }
    }
  }, [selectedQuestion, activePage, results]);

  // Changes the URL when the active page changes
  useEffect(() => {
    if (searchParams.get("page") !== String(activePage)) {
      const newSearchParams: Record<string, any> = {};

      for (const [key, value] of Array.from(searchParams.entries())) {
        newSearchParams[key] = value;
      }

      newSearchParams.page = activePage;

      setSearchParams(newSearchParams);
    }
  }, [activePage, searchParams, setSearchParams]);

  // Checks if the URL specifies a start page
  useEffect(() => {
    const page = searchParams.get("page");
    if (page && !loadedPage) {
      setActivePage(Number(page));
      setLoadedPage(true);
    } else if (!page && !loadedPage) {
      setLoadedPage(true);
    }
  }, [searchParams, loadedPage]);

  // Fetches questions from the database according to the query. Typically runs on page load and when the user selects a new page.
  useEffect(() => {
    (async function () {
      // If the requested page has already been fetched, read from memory.
      if (results[activePage] && !results[activePage]?.length) return;
      if (results[activePage]?.length) {
        setSelectedQuestion(results[activePage][0].id);
        return;
      }
      if (!loadedPage) return;
      if (fetchedPages.includes(activePage)) return;
      if (fetchingPages[activePage]) return;

      setFetchingPages((fetchingPages) => ({
        ...fetchingPages,
        [activePage]: true,
      }));

      try {
        // Get the search parameters
        const [
          papers,
          topics,
          years,
          variants,
          sessions,
          levels,
          units,
          difficulty,
        ] = [
          (searchParams.get("papers") || "").split(";").filter((el) => !!el),
          (searchParams.get("topics") || "").split(";").filter((el) => !!el),
          (searchParams.get("years") || "").split(";").filter((el) => !!el),
          (searchParams.get("variants") || "").split(";").filter((el) => !!el),
          (searchParams.get("sessions") || "").split(";").filter((el) => !!el),
          (searchParams.get("levels") || "").split(";").filter((el) => !!el),
          (searchParams.get("units") || "").split(";").filter((el) => !!el),
          (searchParams.get("difficulty") || "")
            .split(";")
            .filter((el) => !!el),
        ];

        let boards: Board[] = boardsList;

        if (!boards.length) {
          const { data } = await supabase.from("boards").select("name,slug");

          boards = data as Board[];
          setBoardsList(boards);
        }

        const boardName = (
          boards || [
            { slug: "a-levels", name: "A Levels" },
            { slug: "igcse", name: "IGCSE" },
            { slug: "ib-diploma", name: "ib-diploma" },
          ]
        ).find((el) => el.slug === board)?.name;

        // Start constructing a query for all questions where board=board and subject=subject, including the total number (for pagination)
        let query = supabase
          .from("questions")
          .select("*", { count: "exact" })
          .eq("board", boardName)
          .eq("subject", subject);

        if (papers.length) {
          query = query.in(
            "paper_number",
            papers.map((paper) => paper.split("(")[0])
          ); // Add paper numbers as a filter if they have been selected
        }
        if (topics.length) {
          // Add topics as a filter if they have been selected
          let or: string[] = [];
          topics.forEach((topic) => {
            or.push(`topics.cs.{${topic.replace(",", " ")}}`);
          });
          query = query.or(or.join(","));
        }
        if (years.length) {
          // Add years as a filter if they have been selected
          query = query.in(
            "year",
            years.map((year) => Number(year))
          );
        }
        if (variants.length) {
          query = query.in(
            "variant",
            variants.map((variant) => Number(variant))
          );
        }
        if (sessions.length) {
          query = query.in("season", sessions);
        }
        if (levels.length && board === "ib-diploma") {
          query = query.in("level", levels);
        }
        if (units.length && board === "edexcel-a-levels") {
          query = query.in("unit_type", units);
        }
        if (difficulty.length) {
          const or: string[] = [];

          difficulty.forEach((difficulty) => {
            or.push(`difficulty.eq.${difficulty}`);
          });

          query = query.or(or.join(", "));

          // query = query.in(
          //   "difficulty",
          //   difficulty.map((el) => +el)
          // );
        }

        // Sort the query in descending order of year
        query = query
          .order("year", { ascending: false })
          .order("season", { ascending: false })
          .order("question_number", { ascending: true });

        // Get the questions and count, for the selected page

        const { data = [], count } = await query.range(
          PAGE_SIZE * activePage,
          PAGE_SIZE * (activePage + 1)
        );

        const numPages = Math.ceil(count! / PAGE_SIZE);

        let newResults: Question[][] = [];
        if (results.length === 0) {
          newResults = Array.from({ length: numPages }).fill(
            null
          ) as Question[][];
          newResults[activePage] = data as Question[];
        } else {
          newResults = [...results];
          newResults[activePage] = data as Question[];
        }

        // Add the results to state
        setResults(newResults);
        if (count) {
          // Set the number of total pages
          setPages(numPages);
        }
      } catch (e) {
        let newResults: Question[][] = [];
        if (results.length === 0) {
          newResults = Array.from({ length: activePage + 1 }).fill(
            null
          ) as Question[][];
          newResults[activePage] = [];
        } else {
          newResults = [...results];
          newResults[activePage] = [] as Question[];
        }

        setResults(newResults);
      } finally {
        setFetchedPages((fetchedPages) => [...fetchedPages, activePage]);
        setFetchingPages((fetchingPages) => ({
          ...fetchingPages,
          [activePage]: false,
        }));
      }
    })();
  }, [
    activePage,
    board,
    results,
    searchParams,
    subject,
    loadedPage,
    fetchedPages,
    boardsList,
    fetchingPages,
  ]);

  // When a new page is fetched, set the selected question to the first question from that page
  useEffect(() => {
    if (results[results.length - 1]?.length) {
      setSelectedQuestion(results[results.length - 1][0].id);
    }
  }, [results]);

  // When a new question is selected, select the Question tab and update the completion status
  useEffect(() => {
    setActiveTab(0);

    if (selectedQuestion) {
      const completedQuestions: Record<string | number, boolean> = parseJson(
        localStorage.getItem("completedQuestions") || "{}"
      );

      if (completedQuestions[selectedQuestion]) {
        setQuestionCompleted(true);
      } else {
        setQuestionCompleted(false);
      }

      setCompletedQuestions(completedQuestions);
    }
  }, [selectedQuestion]);

  // Mark question as completed
  const completeQuestion = useCallback(
    (id: number | string, newState: boolean) => {
      const completedQuestions = parseJson(
        localStorage.getItem("completedQuestions") || "{}"
      );
      completedQuestions[id] = newState;
      localStorage.setItem(
        "completedQuestions",
        JSON.stringify(completedQuestions)
      );
      setQuestionCompleted(newState);
    },
    [setQuestionCompleted]
  );

  // Add question to builder list
  const toggleFromQuestionBuilder = useCallback(
    (id: number, ids?: number[]) => {
      if (board && subject) {
        if (!ids || !ids.length) {
          ids = [id];
        }
        let newBuilderQuestions = [...builderQuestions];
        ids.forEach((id) => {
          if (newBuilderQuestions.includes(id)) {
            newBuilderQuestions = newBuilderQuestions.filter((el) => el !== id);
          } else {
            newBuilderQuestions.push(id);
          }

          const currentQuestions = parseJson(
            localStorage.getItem("questions") || "{}"
          );

          localStorage.setItem(
            "questions",
            JSON.stringify({
              ...currentQuestions,
              [board]: {
                ...currentQuestions[board],
                [subject]: Array.from(new Set(newBuilderQuestions)),
              },
            })
          );
        });
        setBuilderQuestions(newBuilderQuestions);
      }
    },
    [builderQuestions, board, subject]
  );

  // Implements keyboard-based navigation of questions: Tab to go to the next question and Shift+Tab to go to the previous question
  const onKeyDown = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.key === "Tab" || evt.key === "Enter") {
        evt.preventDefault();

        if (evt.key === "Enter" && selectedQuestion) {
          completeQuestion(selectedQuestion, true);
        }

        const increment = evt.shiftKey ? -1 : 1; // If shift key is pressed, decrement the selected question, otherwise, increment

        const page = results[activePage];

        const index = page.findIndex(
          (question) => question.id === selectedQuestion
        );

        const newIndex = index + increment;

        if (newIndex >= 0 && newIndex < page.length) {
          // If the incremented index is within page array bounds, set the selected question to that
          setSelectedQuestion(page[newIndex].id);
        } else if (newIndex >= 0 && activePage < pages - 1) {
          // If the incremented index is above page array bounds, increment the page and set the selected question to the first question from that page
          setActivePage(activePage + 1);
          setSelectedQuestion(results[activePage + 1][0].id);
        } else if (newIndex < page.length && activePage >= 1) {
          // If the incremented index is below page array bounds, decrement the page and set the selected question to the first question from that page
          setActivePage(activePage - 1);
          setSelectedQuestion(results[activePage - 1][0].id);
        }
      } else if ((evt.key === "=" || evt.key === "+") && selectedQuestion) {
        if (evt.shiftKey) {
          alert("Hey");
          toggleFromQuestionBuilder(
            0,
            (results[activePage] || []).map((question) => question.id)
          );
        } else {
          toggleFromQuestionBuilder(selectedQuestion); // Add/remove from question builder list
        }
      } else if (evt.key === "q" || evt.key === "a") {
        setActiveTab(evt.key === "q" ? 0 : 1); // Switch tabs to/from question/answer
      }
    },
    [
      results,
      selectedQuestion,
      activePage,
      setSelectedQuestion,
      pages,
      completeQuestion,
      toggleFromQuestionBuilder,
    ]
  );

  // Attach listener for keydown (keyboard-based navigation)
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  const currentQuestionAdded = !!(
    selectedQuestion && builderQuestions.includes(selectedQuestion)
  );

  return (
    <Main sidebarVisible={sidebarVisible}>
      <Navbar />
      <Content>
        <Overlay
          onClick={() => {
            setSidebarVisible((sidebarVisible) => !sidebarVisible);
          }}
          show={sidebarVisible}
        ></Overlay>
        <NavRow>
          <BackContainer onClick={() => navigate("/topical")}>
            <BackIcon />
          </BackContainer>
          <MobileMenuContainer
            onClick={() => {
              setSidebarVisible((sidebarVisible) => !sidebarVisible);
            }}
            style={{ marginLeft: "5rem" }}
          >
            {sidebarVisible ? <CloseIcon /> : <MenuIcon />}
          </MobileMenuContainer>
          {!window.frameElement && (
            <BuilderButton
              onClick={() => {
                navigate(`/topical/${board}/${subject}/builder`);
              }}
            >
              Build Worksheet
            </BuilderButton>
          )}
        </NavRow>
        <PagesContainer>
          {new Array(pages).fill(null).map((_, page) => (
            <Page
              active={activePage === page}
              onClick={() => setActivePage(page)}
            >
              {page + 1}
            </Page>
          ))}
        </PagesContainer>
        <Row>
          <SidebarContainer show={sidebarVisible}>
            <ListContainer>
              {!results || (!results[activePage] && <Loader />)}
              {results &&
                results[activePage] &&
                !results[activePage].length && (
                  <NoResults>No results!</NoResults>
                )}
              {(results[activePage] || []).map((question) => (
                <ListItem
                  key={question.id}
                  selected={question.id === selectedQuestion}
                  completed={completedQuestions[question.id]}
                  onClick={() => setSelectedQuestion(question.id)}
                >
                  <div>
                    <ListTitle>Question {question.question_number}</ListTitle>
                    <BubbleContainer>
                      <Bubble>{question.season}</Bubble>
                      <Bubble>{question.year}</Bubble>
                      {board !== "edexcel-a-levels" ? (
                        <Bubble>
                          {question.paper_number}
                          {question.variant}
                        </Bubble>
                      ) : (
                        <Bubble>{question.unit_type}</Bubble>
                      )}
                      {question.level && <Bubble>{question.level}</Bubble>}
                    </BubbleContainer>
                  </div>
                  <Thumbnail
                    style={{
                      backgroundImage: `url('${
                        "https://qnpumhfafqmcymbxzuvc.functions.supabase.co/get-image?url=" +
                        question.images[0].replace(
                          "https://www.exam-mate.com/",
                          ""
                        )
                      }')`,
                    }}
                    show={question.id !== selectedQuestion}
                  />
                </ListItem>
              ))}
            </ListContainer>
          </SidebarContainer>
          <div style={{ display: "flex", width: "100%", gap: "1rem" }}>
            <QuestionContainer>
              <TabsContainer>
                <div style={{ display: "flex" }}>
                  <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                    Question
                  </Tab>
                  <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                    Answer
                  </Tab>
                </div>
                <ToggleContainer>
                  <Toggle
                    active={questionCompleted}
                    onToggle={() => {
                      if (selectedQuestion) {
                        completeQuestion(selectedQuestion, !questionCompleted);
                      }
                    }}
                    onLabel="Completed"
                    offLabel="Not Completed"
                  />
                </ToggleContainer>
              </TabsContainer>
              <RowComponent
                style={{ flexWrap: "wrap", alignItems: "center" }}
                gap={20}
              >
                {selectedQuestionData?.average_difficulty && (
                  <DifficultyBar
                    difficulty={selectedQuestionData?.average_difficulty}
                    background={Color.interpolate(
                      new Color(78, 71, 255),
                      new Color(248, 195, 101),
                      selectedQuestionData?.average_difficulty / 5
                    ).toRGBA()}
                  ></DifficultyBar>
                )}
                {userDetails &&
                  !userDetails?.rated_questions?.includes(
                    selectedQuestion!
                  ) && (
                    <RowComponent style={{ alignItems: "center" }} gap={10}>
                      {[1, 2, 3, 4, 5].map((difficulty) => {
                        return (
                          <DifficultyOption
                            onClick={async () => {
                              if (!userDetails)
                                return openErrorSnackbar(
                                  "You must be signed in to rate questions!"
                                );
                              if (!selectedQuestion) return;
                              if (
                                userDetails.rated_questions.includes(
                                  selectedQuestion
                                )
                              )
                                return openErrorSnackbar(
                                  "You may only rate a question once!"
                                );

                              const { data, error } = await supabase.rpc(
                                "rate_question_difficulty",
                                {
                                  question_id: selectedQuestion,
                                  new_rating: difficulty,
                                }
                              );

                              if (!error) {
                                openSuccessSnackbar(
                                  "Difficulty rating updated!"
                                );

                                setSelectedQuestionData({
                                  ...(selectedQuestionData || {}),
                                  average_difficulty: data,
                                } as Question);

                                await supabase
                                  .from("users")
                                  .update({
                                    rated_questions: [
                                      ...userDetails.rated_questions,
                                      selectedQuestion,
                                    ],
                                  })
                                  .eq("id", userDetails.id);

                                dispatch(addRatedQuestion(selectedQuestion!));
                              } else openErrorSnackbar("There was an error!");
                            }}
                            // active={
                            //   difficulty ===
                            //   Math.round(selectedQuestionData?.average_difficulty || 0)
                            // }
                          >
                            {difficulty}
                          </DifficultyOption>
                        );
                      })}
                      <Spacer size={10} direction="left" />
                      <DifficultyText>Difficulty</DifficultyText>
                    </RowComponent>
                  )}
              </RowComponent>
              {(activeTab === 0
                ? selectedQuestionImage
                : selectedQuestionAnswer
              ).map((image, index) =>
                selectedQuestionType === QuestionType.MCQ && activeTab === 1 ? (
                  <MCQOptions key={Math.random().toString(36)}>
                    {MCQ_OPTIONS.map((option) => (
                      <MCQOption
                        key={Math.random().toString(36)}
                        active={
                          selectedQuestionAnswer[0].toUpperCase() === option
                        }
                      >
                        {option}
                      </MCQOption>
                    ))}
                  </MCQOptions>
                ) : (
                  <div key={Math.random().toString(36)}>
                    <QuestionImage
                      src={
                        "https://qnpumhfafqmcymbxzuvc.functions.supabase.co/get-image?url=" +
                        image.replace("https://www.exam-mate.com/", "")
                      }
                    />
                  </div>
                )
              )}
              {selectedQuestion && (
                <AddButton
                  added={currentQuestionAdded}
                  onClick={() => toggleFromQuestionBuilder(selectedQuestion)}
                >
                  {currentQuestionAdded ? "Added" : "Add"}
                </AddButton>
              )}
            </QuestionContainer>
          </div>
        </Row>
      </Content>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: 100vw;
  overflow-y: ${({ sidebarVisible }: { sidebarVisible: boolean }) =>
    sidebarVisible ? "hidden" : "scroll"};
  padding-top: 7rem;
`;

const Content = styled.div`
  width: 100%;
  height: calc(100% - 5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  padding: 0 5rem;
  gap: 2rem;
  height: calc(100% - 10rem);
`;

const ListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  width: 30vw;
  max-height: calc(100% - 5rem);
  min-height: calc(100% - 5rem);
  overflow-y: scroll;
  background-color: #fff;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 59.375em) {
    max-width: 30vw;
    min-width: 30rem;
  }
`;

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${({
    selected,
    completed,
  }: {
    selected?: boolean;
    completed?: boolean;
  }) => (selected ? colors.primary.hex : completed ? "#b5e48c" : "#fff")};
  padding: 1rem;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? "#fff" : colors.text.hex};
  cursor: pointer;
  transition: background-color 0.5s, filter 0.5s;

  &:hover {
    ${({ selected, completed }: { selected?: boolean; completed?: boolean }) =>
      selected
        ? "background-color: " + colors.primary.hex
        : completed
        ? "filter: brightness(0.8)"
        : "background-color: " + colors.white.hex};
  }
`;

const ListTitle = styled.h3`
  font-weight: 600;
  font-size: 1.6rem;
  transition: color 0.5s;
`;

const BubbleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const Thumbnail = styled.div`
  width: 6rem;
  height: 6rem;
  background-size: cover;
  border-radius: 0.5rem;
  border: 2px solid ${colors.white.hex};
  opacity: ${({ show = true }: { show?: boolean }) => (show ? 1 : 0)};
  transition: opacity 0.2s;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 50% 50%;
`;

const QuestionContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 0.5rem;
  box-shadow: ${styles.boxShadow};
  padding: 4rem;
  padding-top: 2.5rem;
  overflow-y: scroll;
  padding-bottom: 8rem;
`;

const QuestionImage = styled.img`
  width: 100%;
`;

const PagesContainer = styled.div`
  position: relative;
  width: 30vw;
  min-height: 3rem;
  overflow-x: scroll;
  align-self: flex-start;
  /* position: fixed;
  bottom: 0;
  left: 0; */
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: flex-start;
  padding: 0 1rem;
  gap: 0.5rem;
  margin-left: 5rem;
  margin-bottom: 1rem;
  margin-top: -1rem;
  z-index: 10;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 59.375em) {
    align-self: center;
    margin-left: 0;
    margin-top: 0.5rem;
    min-width: 30rem;
  }
`;

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-weight: ${({ active }: { active?: boolean }) => (active ? 600 : 600)};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : "transparent"};
  border-radius: 0.5rem;
  min-width: 3rem;
  height: 3rem;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${({ active }: { active?: boolean }) =>
      active ? colors.primary.hex : colors.white.hex};
  }
`;

const BackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  align-self: flex-start;
  margin-left: 5rem;
  margin-bottom: 2rem;
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${colors.white.hex};

    & > img {
      opacity: 50%;
    }
  }

  @media only screen and (max-width: 59.375em) {
    display: none;
  }
`;

const MobileMenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  align-self: flex-start;
  cursor: pointer;
  transition: background-color 0.5s, opacity 1s;
  margin: 0.5rem 0;
  z-index: 10;

  &:hover {
    background-color: ${colors.white.hex};

    & > img {
      opacity: 50%;
    }
  }

  @media only screen and (min-width: 59.375em) {
    display: none;
  }
`;

const BackIcon = styled.img.attrs({
  src: "/assets/icons/down-chevron.png",
})`
  width: 2rem;
  height: 2rem;
  transform: rotate(90deg);
  opacity: 70%;
  transition: opacity 0.5s;
`;

const CloseIcon = styled.img.attrs({
  src: "/assets/icons/close.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const MenuIcon = styled.img.attrs({
  src: "/assets/icons/menu.svg",
})`
  width: 2rem;
  height: 2rem;
  opacity: 70%;
  transition: opacity 0.5s;
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;

  @media only screen and (max-width: 27.5em) {
    flex-direction: column;
  }
`;

const Tab = styled.div`
  padding: 1rem 2rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.text.hex : "transparent"};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 1rem;
`;

const MCQOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MCQOption = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : colors.white.hex};
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ active }: { active?: boolean }) =>
    active ? colors.white.hex : colors.text.hex};
  animation: ${({ active }: { active?: boolean }) =>
    active ? "pulse 2s both infinite" : "none"};

  &::after {
    content: ${({ active }: { active?: boolean }) => (active ? '"←"' : '""')};
    position: absolute;
    left: 0;
    margin-left: 5rem;
    color: ${colors.primary.hex};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
    40% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(0.95);
    }
  }
`;

const AddButton = styled.button`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  bottom: 4rem;
  right: 8rem;
  padding: 1.2rem 5rem;
  border-radius: 10rem;
  background-color: ${({ added }: { added?: boolean }) =>
    added ? "#b5e48c" : colors.primary.hex};
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  box-shadow: 2px 2px 40px
    ${({ added }: { added?: boolean }) => (added ? "#b5e48c49" : "#4d47ff49")};

  transition: filter 0.5s, transform 0.1s;

  &:hover {
    filter: brightness(0.8);
  }

  &:active {
    transform: scale(0.98);
  }
`;

const NavRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
`;

const BuilderButton = styled.button`
  background-color: ${colors.primary.hex};
  font-size: 1.5rem;
  width: fit-content;
  height: fit-content;
  padding: 1.5rem 2.5rem;
  margin-right: 5rem;
  border-radius: 0.5rem;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 59.375em) {
    position: absolute;
    top: 0;
    left: 5rem;
    z-index: 10;
    height: calc(80vh - 1rem);
    opacity: ${({ show }: { show: boolean }) => (show ? 1 : 0)};
    transform: ${({ show }: { show: boolean }) =>
      show ? "translate(0)" : "translate(-100%)"};
    transition: opacity 1s, transform 1s;
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(8px);
  width: 100vw;
  height: 100vh;
  z-index: 9;
  display: ${({ show }: { show: boolean }) => (show ? "block" : "none")};
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media only screen and (max-width: 27.5em) {
    align-items: center;
  }
`;

const DifficultyOption = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1.5rem;
  height: 5rem;
  width: 5rem;
  border-radius: 0.5rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : "#fff"};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  box-shadow: ${styles.boxShadow};
  margin-bottom: 0.5rem;
  cursor: pointer;
  transition: all 0.5s;

  &:hover {
    background-color: ${({ active }: { active?: boolean }) =>
      active ? colors.primary.hex : colors.white.hex};
  }
`;

const DifficultyText = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
`;

const successSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.primary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};

const errorSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.secondary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};

// prettier-ignore
const DifficultyBar = styled.div`
  position: relative;
  width: 30rem;
  height: 2rem;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: ${colors.white.hex};
  margin-bottom: 2rem;
  &::after {
    content: "${({ difficulty }: { background: string; difficulty: number }) => Math.round(difficulty)}/5 difficulty";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.2rem;
    font-weight: 500;
    color: ${({ difficulty }: { background: string; difficulty: number }) =>
      difficulty >= 2.5 ? "#fff" : colors.text.hex};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ difficulty }: { background: string; difficulty: number }) => (difficulty / 5) * 100}%;
    background-color: ${({
      background,
    }: {
      background: string;
      difficulty: number;
    }) => background};
  }
`;

const NoResults = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
