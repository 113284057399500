import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useSnackbar } from "react-simple-snackbar";

import { styles } from "../../theme/styles";
import { Row } from "../../widgets/Row";
import { Column } from "../../widgets/Column";
import { Spacer } from "../../components/Spacer";
import { TextInput } from "../../components/TextInput";
import { supabase } from "../../api/supabase";
import { colors } from "../../theme/colors";
import { Link, useNavigate } from "react-router-dom";
import { ReduxState } from "../../redux/store";

export const LoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [openSuccessSnackbar] = useSnackbar(successSnackbarStyles);
  const [openErrorSnackbar] = useSnackbar(errorSnackbarStyles);

  const authenticated = useSelector(
    (state: ReduxState) => state.auth.authenticated
  );

  const navigate = useNavigate();

  const login = useCallback(async () => {
    const { data: loginData, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (!error && loginData.user) {
      openSuccessSnackbar(
        "You are logged in! Please wait while you are redirected..."
      );
      // navigate("/topical");
    } else if (error!.status === 400) {
      // Incorrect login details
      openErrorSnackbar("Incorrect email address/password!");
    } else {
      openErrorSnackbar("An error occurred!");
    }
  }, [email, password, openErrorSnackbar, openSuccessSnackbar]);

  useEffect(() => {
    if (authenticated) {
      navigate("/topical", { replace: true });
    }
  }, [authenticated, navigate]);

  return (
    <Container>
      <Logo src="/assets/images/logo.svg" />
      <Spacer size={50} direction="bottom" />
      <LoginContainer>
        <Row style={{ width: "100%", justifyContent: "space-between" }}>
          <Column>
            <Title>Login</Title>
            <Paragraph>Log in to get the full Markhint experience.</Paragraph>
          </Column>
        </Row>
        <Spacer size={20} direction="bottom" />
        <TextInput
          label="Email Address"
          placeholder=""
          value={email}
          onChange={setEmail}
        />
        <TextInput
          type="password"
          label="Password"
          placeholder=""
          value={password}
          onChange={setPassword}
        />
        <Spacer size={20} direction="bottom" />
        <LoginButton
          disabled={!email || !password || password.length < 8}
          onClick={login}
        >
          Log in
        </LoginButton>
        <Spacer size={10} direction="bottom" />
        <SignupLink>
          Don't have an account yet? <Link to="/auth/signup">Sign up now</Link>
        </SignupLink>
      </LoginContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`;

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: ${styles.boxShadow};
  border-radius: 0.5rem;
  padding: 3rem 4rem;
  min-width: 35vw;
`;

const Logo = styled.img`
  height: 3rem;
  opacity: 75%;
  margin-top: -7.2rem;
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 600;
`;

const Paragraph = styled.p`
  font-size: 1.3rem;
  font-weight: 400;
  max-width: 750%;
`;

const LoginButton = styled.button`
  position: relative;
  border-radius: 0.5rem;
  padding: 1.5rem 0.5rem;
  font-size: 1.5rem;
  font-weight: 600;
  color: #fff;
  overflow: hidden;
  cursor: ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "not-allowed" : "pointer"};
  opacity: ${({ disabled }: { disabled?: boolean }) => (disabled ? 0.75 : 1)};

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 150%;
    height: 100%;
    z-index: -1;
    background-image: ${styles.gradients.primary};
    transition: all 0.5s;
  }

  ${({ disabled }: { disabled?: boolean }) =>
    disabled ? "" : "&:hover::before {transform: translateX(-25%);}"};
`;

const errorSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.secondary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};

const successSnackbarStyles = {
  position: "top-center",
  style: {
    backgroundColor: colors.primary.hex,
    color: "#fff",
    fontFamily: 'Lato, "Core Sans", "Be Vietnam", sans-serif',
    fontWeight: 600,
    boxShadow: styles.boxShadow,
  },
  closeStyle: {
    color: "#fff",
  },
};

const SignupLink = styled.span`
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;

  & a {
    color: ${colors.primary.hex};
    text-decoration: none !important;
  }
`;
