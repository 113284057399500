import React, { useRef } from "react";
import styled from "styled-components";
import { useDrag, useDrop } from "react-dnd";
import { colors } from "../../../theme/colors";
import { Question } from "../../../types/Question";

export const QuestionCard = ({
  question,
  index,
  onClick,
  moveCard,
}: {
  question: Question;
  onClick: () => void;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
}) => {
  const ref = useRef<any>(null);

  const [{ handlerId }, drop] = useDrop({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: any, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset!.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id: question.id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <ListItem
      ref={ref}
      style={{ opacity }}
      data-handler-id={handlerId}
      selected={false}
      onClick={onClick}
    >
      <div>
        <ListTitle>Question {question.question_number}</ListTitle>
        <BubbleContainer>
          <ListBubble>{question.season}</ListBubble>
          <ListBubble>{question.year}</ListBubble>
          <ListBubble>
            {question.paper_number}
            {question.variant}
          </ListBubble>
        </BubbleContainer>
      </div>
      <Thumbnail
        style={{
          backgroundImage: `url('${question.images[0]}')`,
        }}
      />
    </ListItem>
  );
};

const ListItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  background-color: ${({ selected }: { selected?: boolean }) =>
    selected ? colors.primary.hex : "#fff"};
  padding: 1rem;
  color: ${({ selected }: { selected?: boolean }) =>
    selected ? "#fff" : colors.text.hex};
  cursor: pointer;
  transition: background-color 0.5s;

  &:hover {
    background-color: ${({ selected }: { selected?: boolean }) =>
      selected ? colors.primary.hex : colors.white.hex};
  }
`;

const ListTitle = styled.h3`
  font-weight: 600;
  font-size: 1.6rem;
  transition: color 0.5s;
`;

const BubbleContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 1rem;
`;

const ListBubble = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 10rem;
  font-size: 1.4rem;
  background-color: ${colors.secondary.hex};
  color: #fff;
  width: fit-content;
  font-weight: 500;
`;

const Thumbnail = styled.div`
  width: 6rem;
  height: 6rem;
  background-size: cover;
  border-radius: 0.5rem;
  border: 2px solid ${colors.white.hex};
  /* opacity: ${({ show = true }: { show?: boolean }) => (show ? 1 : 0)}; */
  transition: opacity 0.2s;
`;
