import React from "react";
import styled from "styled-components";
import { colors } from "../../../../theme/colors";
import { useNavigate } from "react-router-dom";

export const FooterSection = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Row>
        <div>
          <Logo src="/assets/images/logo-white.svg" />
          <Caption>
            Some content on Markhint is property of Cambridge International
            Examinations and the International Baccalaureate Organisation.
          </Caption>
        </div>
        <LinksContainer>
          <Subtitle>Quick Links</Subtitle>
          <Link onClick={() => navigate("/topical")}>Topical Questions</Link>
          <Link onClick={() => navigate("/search")}>Past Paper Search</Link>
        </LinksContainer>
      </Row>
      <div>
        <Subtitle>Get In Touch</Subtitle>
        <Link
          onClick={() => {
            const link = document.createElement("a");
            link.href = "mailto:info@markhint.in";
            link.click();
          }}
        >
          info@markhint.in
        </Link>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  margin-top: 10rem;
  padding: 5rem 10rem;
  width: 100%;
  background-color: ${colors.text.hex};
  color: ${colors.white.hex};

  @media only screen and (max-width: 59.375em) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    gap: 4rem;
  }

  @media only screen and (max-width: 28.125em) {
    padding: 3rem 7rem;
  }

  @media only screen and (max-width: 25em) {
    padding: 1rem 5rem;
  }
`;

const Row = styled.div`
  display: flex;

  @media only screen and (max-width: 59.375em) {
    flex-direction: column;
    align-items: center;
    gap: 4rem;
  }
`;

const Logo = styled.img`
  height: 3rem;
  opacity: 0.7;
`;

const Caption = styled.h3`
  font-size: 1.5rem;
  font-weight: 400;
  opacity: 0.5;
  max-width: 25vw;
  margin-top: 2rem;

  @media only screen and (max-width: 59.375em) {
    max-width: 100%;
  }
`;

const Subtitle = styled.h2`
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  opacity: 0.8;
`;

const Text = styled.h3`
  font-size: 1.5rem;
  font-weight: 400;
  opacity: 0.5;

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
`;

const Link = styled(Text)`
  transition: opacity 0.5s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const LinksContainer = styled.div`
  margin-left: 10rem;

  @media only screen and (max-width: 59.375em) {
    margin-left: 0;
  }
`;
