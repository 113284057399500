export const sluggify = (
  str: string,
  options: { prefix?: string; suffix?: string } = { prefix: "", suffix: "" }
) => {
  return (
    (options.prefix ? "-" + options.prefix : "") +
    str
      .split(" ")
      .map((el) => el.toLowerCase())
      .join("-") +
    (options.suffix ? "-" + options.suffix : "")
  );
};
