import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

export const PdfPage = () => {
  const { slug, type } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!slug) navigate("/papers");
  }, [slug]);

  return (
    <Container>
      <iframe
        width="100%"
        height="100%"
        src={`https://qnpumhfafqmcymbxzuvc.supabase.co/functions/v1/get-paper?slug=${slug}&type=${
          type ?? "qp"
        }`}
        style={{ outline: "none", border: "none" }}
      ></iframe>
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;
