import { PastPaperBoard } from "./years";

export const pastPaperCodes: Record<
  PastPaperBoard,
  Record<string, string[]>
> = {
  "A Levels": {
    "9706": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
    ],
    "9700": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "34",
      "35",
      "41",
      "42",
      "43",
      "51",
      "52",
      "53",
    ],
    "9701": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "34",
      "35",
      "41",
      "42",
      "43",
      "51",
      "52",
      "53",
    ],
    "9702": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "34",
      "35",
      "41",
      "42",
      "43",
      "51",
      "52",
      "53",
    ],
    "9608": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
    ],
    "9618": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
    ],
    "9708": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
    ],
    "9231": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
    ],
    "9709": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
      "51",
      "52",
      "53",
      "61",
      "62",
      "63",
      "71",
      "72",
      "73",
    ],
    "9990": [
      "11",
      "12",
      "13",
      "21",
      "22",
      "23",
      "31",
      "32",
      "33",
      "41",
      "42",
      "43",
    ],
  },
  "IB Diploma": {},
  IGCSE: {},
  "Edexcel A Levels": {},
};
