import React, { useCallback, useEffect, useRef, useState } from "react";
import styled, { StyledProps } from "styled-components";
import { Subject, debounceTime } from "rxjs";
import { colors } from "../../theme/colors";
import { ellipsize } from "../../utils/ellipsize";
import { Spacer } from "../../components/Spacer";

interface DropdownProps {
  id?: string;
  label: string;
  values: string[];
  onChange: (value: string[]) => void;
  multi?: boolean;
  options: { value: string; label: string; category?: string | null }[];
  disabled?: boolean;
  searchable?: boolean;
  style?: React.CSSProperties;
}

const searchTermSubject$ = new Subject<string>();

export const Dropdown = ({
  id,
  values,
  label,
  onChange,
  multi = false,
  options,
  disabled = false,
  searchable = false,
  style = {} as React.CSSProperties,
}: DropdownProps) => {
  const [dropdownDisplayed, setDropdownDisplayed] = useState<boolean>(false);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState<
    { value: string; label: string; category?: string | null }[]
  >([]);
  const [searchText, setSearchText] = useState("");

  const inputRef = useRef<HTMLDivElement | null>(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const listener = searchTermSubject$
      .pipe(debounceTime(500))
      .subscribe((searchTerm) => {
        setSearchText(searchTerm);
      });

    return () => {
      listener.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const formattedSearchText = searchText.toLowerCase().trim();

    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(formattedSearchText)
      )
    );
  }, [searchText]);

  const onOptionSelected = useCallback(
    (value: string) => {
      const indexOfValue = values.indexOf(value);

      const newValues = [...values];

      // Option already selected, so deselect it
      if (~indexOfValue) {
        if (multi) {
          newValues.splice(indexOfValue, 1);

          onChange(newValues);
        } else {
          onChange([]);
        }
      } else {
        // Option not selected, so select it
        if (multi) {
          newValues.push(value);

          onChange(newValues);
        } else {
          onChange([value]);
          return;
        }
      }
    },
    [values, onChange, multi]
  );

  const onMouseClick = useCallback(
    ({ clientX: x, clientY: y }: { clientX: number; clientY: number }) => {
      if (!inputRef.current || !dropdownRef.current) return;

      const {
        left: dropdownLeft,
        top: dropdownTop,
        right: dropdownRight,
        bottom: dropdownBottom,
      } = dropdownRef.current?.getBoundingClientRect();

      const {
        left: inputLeft,
        top: inputTop,
        right: inputRight,
        bottom: inputBottom,
      } = inputRef.current?.getBoundingClientRect();
      if (
        !(
          x >= dropdownLeft &&
          x <= dropdownRight &&
          y >= dropdownTop &&
          y <= dropdownBottom
        ) &&
        !(
          x >= inputLeft &&
          x <= inputRight &&
          y >= inputTop &&
          y <= inputBottom
        )
      ) {
        setDropdownDisplayed(false);
      }
    },
    [inputRef, dropdownRef, setDropdownDisplayed]
  );

  useEffect(() => {
    document.addEventListener("click", onMouseClick);

    return () => {
      document.removeEventListener("click", onMouseClick);
    };
  }, [onMouseClick]);

  const formatLabel = useCallback(() => {
    if (values.length === 0) return label;

    const ellipsized = ellipsize(values.join(", "), 30);

    if (multi) {
      return `${ellipsized}|(${values.length} selection${
        values.length > 1 ? "s" : ""
      })`;
    } else {
      return ellipsized;
    }
  }, [values, label, multi]);

  return (
    <Container {...(id ? { id } : {})} {...(style as any)} disabled={disabled}>
      <InputBox
        onClick={
          disabled
            ? () => null
            : () => {
                setDropdownDisplayed(!dropdownDisplayed);
                setDropdownClicked(true);
              }
        }
        disabled={disabled}
        ref={inputRef}
      >
        <Label placeholderText>
          {formatLabel().split("|").slice(0, -1).join("|")}{" "}
          <span>
            {formatLabel().split("|")[formatLabel().split("|").length - 1]}
          </span>
        </Label>
        <DownIcon />
      </InputBox>
      <DropdownContainer
        // style={{ display: dropdownDisplayed ? "block" : "none" }}
        show={dropdownDisplayed}
        dropdownClicked={dropdownClicked}
        ref={dropdownRef}
      >
        {searchable && (
          <>
            <SearchBox
              placeholder="Search"
              onInput={(evt) =>
                searchTermSubject$.next(evt.currentTarget.value)
              }
            />
            <Spacer size={5} direction="bottom" />
          </>
        )}
        {filteredOptions.map(({ label, value, category }) => {
          const selected = values.includes(value);

          return (
            <div key={value}>
              {category && <Category>{category}</Category>}
              <Option
                disabled={disabled}
                onClick={disabled ? () => null : () => onOptionSelected(value)}
              >
                <Checkbox active={selected}>
                  {selected ? <CheckIcon /> : <></>}
                </Checkbox>
                {label}
              </Option>
            </div>
          );
        })}
        {!options.length && (
          <NoOptionsText>No options available!</NoOptionsText>
        )}
      </DropdownContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 20vw;
  min-width: 20rem;
  opacity: ${({ disabled }: { disabled: boolean }) => (disabled ? 0.7 : 1)};

  @media only screen and (max-width: 560px) {
    width: 100%;
  }
`;

const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: #1d1d1d14 0px 0px 30px;
  width: 100%;

  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "not-allowed" : "pointer"};
  user-select: none;
`;

const Label = styled.h3`
  font-size: 1.5rem;
  font-weight: ${({ placeholderText }: { placeholderText: boolean }) =>
    placeholderText ? 600 : 500};
  opacity: ${({ placeholderText }: { placeholderText: boolean }) =>
    placeholderText ? 0.7 : 1};

  & span {
    font-weight: 500;
  }

  @media only screen and (max-width: 300px) {
    font-size: 1.5rem;
  }
`;

const DownIcon = styled.img.attrs({
  src: "/assets/icons/down-chevron.png",
})`
  height: 1.6rem;
  opacity: 0.7;
`;

const DropdownContainer = styled.div`
  border-radius: 0.75rem;
  background-color: #fff;
  box-shadow: #1d1d1d14 0px 0px 30px;
  width: 100%;
  overflow-y: scroll;

  max-height: 0;

  margin-top: 1rem;

  animation: ${({
      show,
      dropdownClicked,
    }: {
      show?: boolean;
      dropdownClicked?: boolean;
    }) => (dropdownClicked ? (show ? "animateIn" : "animateOut") : "none")}
    1s forwards;

  @keyframes animateIn {
    0% {
      max-height: 0;
      opacity: 0% !important;
    }
    75% {
      padding: 1rem 2rem;
    }
    100% {
      max-height: 32rem;
      padding: 1rem 2rem;
      opacity: 100% !important;
    }
  }
  @keyframes animateOut {
    0% {
      max-height: 32rem;
      opacity: 100% !important;
      padding: 1rem 2rem;
    }
    75% {
      max-height: 0;
    }
    100% {
      max-height: 0;
      opacity: 0% !important;
      padding: 0;
    }
  }
`;

const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 1.75rem;
  min-height: 1.75rem;
  border-radius: 0.25rem;
  background-color: ${({ active }: { active: boolean }) =>
    active ? colors.primary.hex : "#fff"};
  margin-right: 1rem;

  border: ${({ active }: { active: boolean }) =>
    !active ? `1px solid ${colors.text.hex}` : "none"};
`;

const CheckIcon = styled.img.attrs({ src: "/assets/icons/checkmark.svg" })`
  height: 1.25rem;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  padding: 1rem;
  cursor: ${({ disabled }: { disabled: boolean }) =>
    disabled ? "not-allowed" : "pointer"};
`;

const Category = styled.h3`
  width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  padding: 1rem;
  padding-bottom: 0;
  font-weight: 600;
`;

const NoOptionsText = styled.h3`
  font-weight: 400;
  font-size: 1.5rem;
  text-align: center;
`;

const SearchBox = styled.input`
  width: 100%;
  font-size: 1.5rem;
  border: none;
  border-bottom: 1.5px solid ${colors.text.hex};
  padding: 0.5rem;
  color: ${colors.text.hex};
  font-weight: 500;

  &:focus {
    outline: none;
    border-bottom: 1.5px solid ${colors.primary.hex};
  }
`;
