import { FINAL_YEAR, generateYears } from "../../data/years";

export type PastPaperBoard = keyof typeof pastPaperYears;

export const pastPaperYears: {
  "A Levels": Record<string, string[]>;
  "IB Diploma": Record<string, string[]>;
  IGCSE: Record<string, string[]>;
  "Edexcel A Levels": Record<string, string[]>;
} = {
  "A Levels": {
    "9706": generateYears(2010, FINAL_YEAR),
    "9700": generateYears(2001, FINAL_YEAR),
    "9701": generateYears(2001, FINAL_YEAR),
    "9702": generateYears(2001, FINAL_YEAR),
    "9608": generateYears(2015, 2021),
    "9618": generateYears(2021, FINAL_YEAR),
    "9708": generateYears(2001, FINAL_YEAR),
    "9231": generateYears(2002, FINAL_YEAR),
    "9709": generateYears(2010, FINAL_YEAR),
    "9990": generateYears(2018, FINAL_YEAR),
  },
  "IB Diploma": {},
  IGCSE: {},
  "Edexcel A Levels": {},
};
