import React, { useCallback, useEffect, useState } from "react";
import { Navbar } from "../../components/Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { MCQResponse, Test } from "../../types/Test";
import styled from "styled-components";
import { Loader } from "../../components/Loader";
import { supabase } from "../../api/supabase";
import { Row } from "../../widgets/Row";
import { Question } from "../../types/Question";
import { Spacer } from "../../components/Spacer";
import { Modal } from "../../components/Modal";
import { colors } from "../../theme/colors";
import { generateSlug } from "../../utils/generateSlug";

const MCQ_OPTIONS = ["A", "B", "C", "D"];

export const ResultsPage = () => {
  const { id: testId } = useParams();
  const [test, setTest] = useState<Test | null>(null);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [topicWiseMistakes, setTopicWiseMistakes] = useState<
    Record<string, { mistakes: Question[]; total: number }>
  >({});
  const [correctQuestions, setCorrectQuestions] = useState(0);

  const [activeQuestion, setActiveQuestion] = useState<Question | null>();
  const [showModal, setShowModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    (async function () {
      if (testId) {
        const { data } = (await supabase
          .from("tests")
          .select("*")
          .eq("id", testId)) as any as { data: Test[] };

        const [test] = data;

        if (!test) return navigate("/topical");

        if (!test.completed) return navigate(`/tests/${test.id}`);
        setTest(test);

        const { data: questions } = await supabase
          .from("questions")
          .select("*")
          .in("id", test.questions);

        setQuestions(questions as any as Question[]);

        const topicWiseMistakes: Record<
          string,
          { mistakes: Question[]; total: number }
        > = {};

        let correctQuestions = 0;

        (questions as Question[])?.forEach((question, i) => {
          // const completed = test.responses[question.id] != null;
          // if (!completed) return;

          const correct =
            test.responses[question.id] ===
            MCQResponse[question.answer[0] as "A" | "B" | "C" | "D"];
          if (correct) correctQuestions++;
          question.topics.forEach((topic) => {
            if (topic in topicWiseMistakes) {
              topicWiseMistakes[topic].total++;
              !correct && topicWiseMistakes[topic].mistakes.push(question);
            } else {
              topicWiseMistakes[topic] = {
                total: 1,
                mistakes: correct ? [] : [question],
              };
            }
          });
        });

        setCorrectQuestions(correctQuestions);

        setTopicWiseMistakes(topicWiseMistakes);
      }
    })();
  }, [testId, navigate]);

  const onKeyDown = useCallback((evt: KeyboardEvent) => {
    if (evt.key === "Escape") {
      setShowModal(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [onKeyDown]);

  function season(month: string) {
    if (month === "Oct/Nov") return "w";
    else if (month === "May/Jun") return "s";
    else return "m";
  }

  const percentageScore = (100 * correctQuestions) / questions.length;

  return (
    <>
      <Navbar />
      <Logo src="/assets/images/logo.svg" />
      <Main>
        <Modal
          title={activeQuestion ? generateSlug(activeQuestion!) : ""}
          show={showModal}
          setShow={setShowModal}
        >
          {activeQuestion && (
            <>
              <Spacer size={10} direction="bottom" />
              <TabsContainer>
                <Tab active={activeTab === 0} onClick={() => setActiveTab(0)}>
                  Question
                </Tab>
                <Tab active={activeTab === 1} onClick={() => setActiveTab(1)}>
                  Answer
                </Tab>
              </TabsContainer>
              {(activeTab === 0
                ? activeQuestion!.images
                : activeQuestion!.answer
              ).map((image) =>
                image.length === 1 ? (
                  <MCQOptions>
                    {MCQ_OPTIONS.map((option) => (
                      <MCQOption
                        key={Math.random().toString(36)}
                        active={option === image}
                      >
                        {option}
                      </MCQOption>
                    ))}
                  </MCQOptions>
                ) : (
                  <QuestionImage src={image} key={Math.random().toString(36)} />
                )
              )}
            </>
          )}
        </Modal>
        {!test && <Loader />}
        {test && (
          <>
            <Marks>
              You scored {correctQuestions}/{questions.length}.
            </Marks>
            <Subtitle>
              {percentageScore >= 80 && percentageScore <= 100
                ? "Great job! Looks like you understood these topics well."
                : percentageScore >= 60 && percentageScore <= 80
                ? "You are on the right track, but may need a little more practice!"
                : "You should revise the topics mentioned below and try again!"}
            </Subtitle>
            <Spacer size={20} direction="bottom" />
            <Row>
              <QuestionList>
                {questions.map((question, i) => (
                  <QuestionBox
                    key={question.id}
                    onClick={() => {
                      //   setCurrentQuestionIndex(key);
                      setActiveQuestion(question);
                      setShowModal(true);
                    }}
                    background={
                      test.responses[question.id] != null
                        ? test.responses[question.id] ===
                          MCQResponse[
                            question.answer[0] as "A" | "B" | "C" | "D"
                          ]
                          ? "rgba(25, 222, 104, 0.87)"
                          : "rgba(255, 21, 21, 0.67)"
                        : "#fff"
                    }
                  >
                    <div>Q{i + 1} - </div>
                    <div>
                      {season(question.season)}
                      {question.year.toString().substring(2, 4)} QP
                      {(question.unit_type ?? question.paper_number).toString()}
                      {question.variant?.toString() ?? ""}
                    </div>
                  </QuestionBox>
                ))}
              </QuestionList>
              <Spacer size={50} direction="right" />
              <div>
                <TopicsContainer>
                  {Object.entries(topicWiseMistakes).map(
                    ([topic, { total, mistakes }]) => (
                      <TopicContainer>
                        <TopicName>{topic}</TopicName>
                        <TopicScoreBackground
                          percentage={
                            Math.floor(
                              (1000 * (total - mistakes.length)) / total
                            ) / 10
                          }
                        />
                      </TopicContainer>
                    )
                  )}
                </TopicsContainer>
                <Spacer size={20} direction="top" />
                <NextBtn onClick={() => navigate("/")}>Go back to home</NextBtn>
              </div>
            </Row>
          </>
        )}
      </Main>
    </>
  );
};

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 8rem 4rem;
`;

const Marks = styled.h1`
  font-size: 5rem;
  font-weight: 600;
`;

const Subtitle = styled.h3`
  font-size: 2rem;
  font-weight: 500;
`;

const QuestionBox = styled.div`
  background: ${({ background }: { background: string }) => background};
  border: 1px solid #333333;
  border-radius: 5px;
  font-size: 16px;
  padding: 5px 10px;
  transition: all 300ms;
  display: flex;
  align-items: flex-start;
  gap: 3px;
  &:hover {
    cursor: pointer;
    background-color: #f5f5f5;
  }
`;

const QuestionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: auto;
  min-width: 33vw;
`;

const TabsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

const Tab = styled.div`
  padding: 1rem 2rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.text.hex : "transparent"};
  color: ${({ active }: { active?: boolean }) =>
    active ? "#fff" : colors.text.hex};
  font-size: 1.5rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 1rem;
`;

const MCQOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const MCQOption = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: ${({ active }: { active?: boolean }) =>
    active ? colors.primary.hex : colors.white.hex};
  font-size: 1.5rem;
  font-weight: 500;
  color: ${({ active }: { active?: boolean }) =>
    active ? colors.white.hex : colors.text.hex};
  animation: ${({ active }: { active?: boolean }) =>
    active ? "pulse 2s both infinite" : "none"};

  &::after {
    content: ${({ active }: { active?: boolean }) => (active ? '"←"' : '""')};
    position: absolute;
    left: 0;
    margin-left: 5rem;
    color: ${colors.primary.hex};
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }
    40% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(0.95);
    }
  }
`;

const QuestionImage = styled.img`
  width: 100%;
`;

const TopicsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  height: fit-content;
  gap: 2rem;
`;

const TopicContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 20rem;
  gap: 0.5rem;
`;

const TopicName = styled.h3`
  font-size: 1.8rem;
  font-weight: 600;
`;

const TopicScoreBackground = styled.div`
  position: relative;
  width: 30rem;
  height: 3rem;
  border-radius: 0.5rem;
  background-color: ${colors.white.hex};
  overflow: hidden;

  &::before {
    content: "${({ percentage }: { percentage: number }) => percentage}%";
    z-index: 2;
    color: ${({ percentage }: { percentage: number }) =>
      percentage < 40 ? colors.text.hex : "#fff"};
    position: absolute;
    top: 50%;
    left: ${({ percentage }: { percentage: number }) =>
      percentage < 40 ? 50 : percentage / 2}%;
    transform: translate(-50%, -50%);
    font-size: 1.3rem;
    font-weight: 500;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: ${({ percentage }: { percentage: number }) => percentage}%;
    background-color: ${colors.primary.hex};
  }
`;

const Logo = styled.img`
  height: 3rem;
  opacity: 75%;
  position: absolute;
  top: 4rem;
  left: 4rem;
`;

const NextBtn = styled.button`
  background: ${({ negative }: { negative?: boolean }) =>
    negative ? "#fff" : colors.primary.hex};
  box-shadow: ${({ negative }: { negative?: boolean }) =>
    negative ? "none" : "0px 4px 10px rgba(0, 0, 0, 0.25)"};
  border-radius: 0.5rem;
  font-size: 1.6rem;
  color: ${({ negative }: { negative?: boolean }) =>
    negative ? colors.primary.hex : "#fff"};
  height: fit-content;
  padding: 1.5rem 2.5rem;
  font-weight: 600;
`;
