import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "react-simple-snackbar";
import { colors } from "../../../../theme/colors";
import { Modal } from "../../../../components/Modal";
import { TextInput } from "../../../../components/TextInput";
import { Spacer } from "../../../../components/Spacer";
import { Task, TaskType } from "../../../../types/Task";
import { formatDate } from "../../../../utils/formatDate";
import {
  errorSnackbarStyles,
  styles,
  successSnackbarStyles,
} from "../../../../theme/styles";
import { capitalize } from "../../../../utils/capitalize";
import { formatTime } from "../../../../utils/formatTime";
import { supabase } from "../../../../api/supabase";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux/store";
import { Subject } from "../../../../types/Subject";
import { IoIosTrash as RemoveIcon } from "react-icons/io";

export const Tasks = ({
  tasks,
  onTaskCreated,
  completeTask,
  onTaskDeleted,
}: {
  tasks: Task[];
  onTaskCreated: (task: Task) => void;
  completeTask: (id: string) => void;
  onTaskDeleted: (id: string) => void;
}) => {
  const [openSuccessSnackbar] = useSnackbar(successSnackbarStyles);
  const [openErrorSnackbar] = useSnackbar(errorSnackbarStyles);

  const [addingTask, setAddingTask] = useState(false);
  const [newTask, setNewTask] = useState({
    title: "",
    id: "",
    start_time: new Date(),
    end_time: null,
    subject: "",
    tag: TaskType.OTHER,
    date: new Date(),
  } as Task);
  const [newTaskDuration, setNewTaskDuration] = useState(0);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteTask, setDeleteTask] = useState<Task | null>(null);

  const userDetails = useSelector(
    (reduxState: ReduxState) => reduxState.auth.userDetails
  );
  const [subjects, setSubjects] = useState<Subject[]>([]);

  useEffect(() => {
    (async function () {
      if (!userDetails?.default_board) return;

      const { data: subjects } = await supabase
        .from("subjects")
        .select("*")
        .eq("board", userDetails.default_board);

      setSubjects((subjects as Subject[]) || []);
    })();
  }, [userDetails]);

  const currentTime = new Date();

  return (
    // @TODO add check for start time in future
    <Container>
      <Modal
        title="Are you sure you want to delete this task?"
        show={showDeleteModal}
        setShow={setShowDeleteModal}
        actions={[
          {
            label: "Delete",
            onClick: async () => {
              try {
                if (!deleteTask) return;
                await supabase.from("tasks").delete().eq("id", deleteTask.id);
                onTaskDeleted(deleteTask.id);

                openSuccessSnackbar("Task deleted successfully!");
                setShowDeleteModal(false);
                setDeleteTask(null);
              } catch (e) {
                console.log(e);
                openErrorSnackbar("There was an error!");
              }
            },
          },
        ]}
      >
        <></>
      </Modal>
      <Modal
        title="Add New Task"
        show={addingTask}
        setShow={setAddingTask}
        actions={[
          {
            label: "Add Task",
            onClick: async () => {
              try {
                const newTaskObj = {
                  user: userDetails?.id,
                  start_time: newTask.start_time,
                  end_time: newTask.end_time,
                  subject: newTask.subject,
                  tag: newTask.tag,
                  title: newTask.title,
                };

                const result = await supabase.from("tasks").insert(newTaskObj);
                console.log(result);
                setAddingTask(false);
                openSuccessSnackbar("Task created successfully!");
                onTaskCreated({
                  ...newTaskObj,
                  id: Math.random().toString(36),
                  complete: false,
                });

                setNewTask({
                  title: "",
                  id: "",
                  start_time: new Date(),
                  end_time: null,
                  subject: "",
                  tag: TaskType.OTHER,
                  date: new Date(),
                  complete: false,
                });
              } catch (e) {
                console.log(e);
                openErrorSnackbar("There was an error!");
              }
            },
          },
        ]}
      >
        <Spacer direction="bottom" size={10} />
        <TextInput
          label="Title"
          placeholder="e.g. Solve topical papers"
          value={newTask.title}
          onChange={(value: string) => setNewTask({ ...newTask, title: value })}
        />
        <TextInput
          type="date"
          label="Date"
          placeholder=""
          value={newTask.date ? formatDate(newTask.date, "-") : ""}
          onChange={(date: string) => {
            const newDate = new Date(date);
            const update = { ...newTask, date: newDate };
            if (update.start_time) {
              const newStartTime = new Date(+update.start_time);
              newStartTime.setDate(newDate.getDate());
              newStartTime.setMonth(newDate.getMonth());
              newStartTime.setFullYear(newDate.getFullYear());

              update.start_time = newStartTime;
            }
            if (update.end_time) {
              const newEndTime = new Date(+update.end_time);
              newEndTime.setDate(newDate.getDate());
              newEndTime.setMonth(newDate.getMonth());
              newEndTime.setFullYear(newDate.getFullYear());

              update.end_time = newEndTime;
            }

            setNewTask(update);
          }}
        />
        <TextInput
          type="time"
          label="Start Time"
          placeholder=""
          value={
            newTask.start_time
              ? `${newTask.start_time
                  .getHours()
                  .toString()
                  .padStart(2, "0")}:${newTask.start_time
                  .getMinutes()
                  .toString()
                  .padStart(2, "0")}`
              : ""
          }
          onChange={(time: string) => {
            const [hours, minutes] = time.split(":");
            const date = !!newTask.date ? new Date(+newTask.date) : new Date();
            date.setHours(+hours);
            date.setMinutes(+minutes);

            setNewTask({ ...newTask, start_time: date });
          }}
        />
        <TextInput
          type="number"
          label="Duration (minutes)"
          placeholder="Enter duration"
          value={newTaskDuration}
          onChange={(duration: number) => {
            setNewTaskDuration(duration);

            if (!!newTask.start_time) {
              const endTime = new Date(
                +newTask.start_time + duration * 60 * 1000
              );

              setNewTask({ ...newTask, end_time: endTime });
            }
          }}
        />
        <TextInput
          type="dropdown"
          label="Subject"
          placeholder="Select subject"
          options={subjects.map((subject) => ({
            label: subject.name,
            value: subject.code,
          }))}
          value={newTask.subject}
          onChange={(value: string) =>
            setNewTask({ ...newTask, subject: value })
          }
        />
        <TextInput
          type="dropdown"
          label="Tag"
          placeholder="Select tag"
          options={[
            { label: "Topical", value: TaskType.TOPICAL },
            { label: "Past Paper", value: TaskType.PAST_PAPER },
            { label: "Timed Test", value: TaskType.TEST },
            { label: "Revise", value: TaskType.REVISE },
            { label: "Other", value: TaskType.OTHER },
          ]}
          value={newTask.tag}
          onChange={(value: string) => {
            const tag = (function () {
              switch (+value) {
                case 0:
                  return TaskType.TOPICAL;
                case 1:
                  return TaskType.PAST_PAPER;
                case 2:
                  return TaskType.TEST;
                case 3:
                  return TaskType.REVISE;
                case 4:
                  return TaskType.OTHER;
                default:
                  return TaskType.OTHER;
              }
            })();

            setNewTask({ ...newTask, tag });
          }}
        />
      </Modal>
      <Row
        style={{
          paddingTop: "2rem",
          marginTop: "-2rem",
          gap: 0,
          paddingLeft: "2rem",
          marginLeft: "-2rem",
        }}
      >
        {tasks.map((task) => (
          <TaskContainer>
            <TaskCard
              onClick={() => completeTask(task.id)}
              status={
                task.complete
                  ? "Complete"
                  : +currentTime >= +task.start_time! &&
                    +currentTime <= +task.end_time!
                  ? "In Progress"
                  : +task.start_time! > +currentTime
                  ? "Upcoming"
                  : "Past Due"
              }
            >
              <TaskTitle>{capitalize(task.title)}</TaskTitle>
              <Spacer direction="bottom" size={5} />
              <Row style={{ gap: "1rem" }}>
                <TaskPill>
                  {subjects.find((subject) => subject.code === task.subject)
                    ?.name ?? task.subject}
                </TaskPill>
                <TaskPill>
                  {task.tag === TaskType.TOPICAL
                    ? "Topical"
                    : task.tag === TaskType.TEST
                    ? "Timed Test"
                    : task.tag === TaskType.REVISE
                    ? "Revision"
                    : task.tag === TaskType.PAST_PAPER
                    ? "Past Paper"
                    : "Other"}
                </TaskPill>
              </Row>
            </TaskCard>
            <Row>
              <TaskTime>
                {!!task.start_time ? formatTime(task.start_time) : "-"} to{" "}
                {!!task.end_time ? formatTime(task.end_time) : "-"}
              </TaskTime>
              <RemoveIcon
                cursor="pointer"
                color={colors.red.withOpacity(0.8).hex}
                size={20}
                onClick={async () => {
                  setShowDeleteModal(true);
                  setDeleteTask(task);
                }}
              />
            </Row>
          </TaskContainer>
        ))}
        <AddTasks onClick={() => setAddingTask(true)}>Add New Task</AddTasks>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  max-width: calc(100vw - 12rem);
  display: flex;
`;

const Row = styled.div`
  display: flex;
  gap: 2rem;
  max-width: 100%;
  height: fit-content;

  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const TaskContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  min-width: 27rem;
  padding-right: 2rem;
`;

const TaskTime = styled.h3`
  font-size: 1.6rem;
  font-weight: 500;
`;

const TaskCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;

  cursor: pointer;

  position: relative;
  background-color: #fff;
  padding: 2rem;
  padding-top: 4rem;
  border-radius: 1rem;
  box-shadow: ${styles.boxShadow};
  min-width: 25rem;

  &::before {
    content: "${({ status }: { status: string }) => status}";
    font-size: 1.2rem;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: ${({ status }: { status: string }) =>
      status === "Upcoming"
        ? colors.white.hex
        : status === "In Progress"
        ? colors.secondary.hex
        : status === "Complete"
        ? colors.green.hex
        : colors.red.hex};
    padding: 0.2rem 2rem;
    width: 100%;
    text-align: center;
    border-radius: 1rem 1rem 0 0;
    color: ${({ status }: { status: string }) =>
      status === "Past Due" ? "#fff" : colors.text.hex};
  }
`;

const TaskTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 500;
`;

const TaskPill = styled.div`
  background-color: ${colors.primary.hex};
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  color: #fff;
  border-radius: 5rem;
  font-weight: 500;
`;

const AddTasks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${colors.white.hex};
  padding: 1rem 4rem;
  border-radius: 1rem;
  border: 2px dashed ${colors.text.withOpacity(0.5).hex};
  font-size: 1.7rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.1s ease-out;
  user-select: none;

  min-width: 25rem;

  transform: scale(0.99);

  &:hover {
    transform: scale(1);
  }

  &:active {
    transform: scale(0.98);
  }
`;
