import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import { FeaturesSection } from "./sections/features/features.section";
import { HeroSection } from "./sections/hero/hero.section";
import { TopicalSection } from "./sections/topical/topical.section";
import { SchoolFeaturesSection } from "./sections/school-features/school-features.section";
import { FooterSection } from "./sections/footer/footer.section";

export const LandingPage = () => {
  useEffect(() => {
    document.title =
      "Markhint — Prepare for exams 5x faster | IGCSE | A Levels";
  }, []);

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <HeroSection />
      <FeaturesSection />
      <TopicalSection />
      <SchoolFeaturesSection />
      <FooterSection />
    </Container>
  );
};

const Container = styled(motion.div)`
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
`;
