import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Column } from "../widgets/Column";
import { Spacer } from "./Spacer";
import { ReduxState } from "../redux/store";
import { styles } from "../theme/styles";
import { colors } from "../theme/colors";
import { supabase } from "../api/supabase";

export const Navbar = ({ left }: { left?: boolean }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const authState = useSelector((state: ReduxState) => state.auth);
  const navigate = useNavigate();

  return (
    <Main left={left}>
      {/* <Logo
        src={"/assets/images/logo.svg"}
        alt="Logo"
        onClick={() => navigate("/")}
      /> */}
      <div style={{ position: "relative" }}>
        <AccountContainer
          onClick={() => {
            if (authState.authenticated) {
              setShowDropdown((showDropdown) => !showDropdown);
            } else {
              navigate("/auth/login");
            }
          }}
        >
          <Thumbnail src="/assets/images/avatar.jpg" alt="Avatar" />
          <Column>
            <UserName>{authState.userDetails?.name || "Guest User"}</UserName>
            <UserEmail
              onClick={() => {
                if (!authState.authenticated) {
                  navigate("/auth/login");
                }
              }}
            >
              {authState.userDetails?.email ||
                authState.user?.email ||
                "Login to continue"}
            </UserEmail>
          </Column>
          <Spacer size={5} direction="left" />
          <Open
            src="/assets/icons/down-chevron.png"
            onClick={() => {
              if (!authState.authenticated) {
                setShowDropdown((showDropdown) => !showDropdown);
              }
            }}
          />
        </AccountContainer>
        <DropdownContainer show={showDropdown}>
          {/* <DropdownSectionTitle>My Profile</DropdownSectionTitle>
          <DropdownItem onClick={() => navigate("/profile")}>
            Dashboard
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/profile/tests")}>
            My Tests
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/profile/settings")}>
            Profile Settings
          </DropdownItem> */}
          <DropdownSectionTitle>Markhint</DropdownSectionTitle>
          <DropdownItem onClick={() => navigate("/search")}>
            Search
          </DropdownItem>
          <DropdownItem onClick={() => navigate("/topical")}>
            Topical Questions
          </DropdownItem>
          <Logout
            onClick={async () => {
              if (authState.authenticated) {
                const confirmed = window.confirm(
                  "Are you sure you want to log out?"
                );
                if (confirmed) {
                  await supabase.auth.signOut();
                }
              } else {
                navigate("/auth/login");
              }
            }}
          >
            Log {authState.authenticated ? "out" : "in"}
          </Logout>
        </DropdownContainer>
      </div>
    </Main>
  );
};

const Main = styled.div`
  width: fit-content;
  padding: 3rem;
  display: flex;
  justify-content: ${({ left }: { left?: boolean }) =>
    left ? "flex-start" : "flex-end"};
  align-items: center;
  position: absolute;
  top: 0;
  ${({ left }: { left?: boolean }) => (left ? "left" : "right")}: 0;
  z-index: 5;

  @media only screen and (max-width: 56.25em) {
    display: none;
  }
`;

const AccountContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

const Thumbnail = styled.img`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
`;

const UserName = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
`;

const UserEmail = styled.h4`
  font-size: 1.2rem;
  font-weight: 400;
`;

const Open = styled.img`
  width: 2rem;
`;

const DropdownContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  top: 6.5rem;
  padding: 1.5rem;
  width: 100%;
  background-color: #fff;
  box-shadow: ${styles.boxShadow};
  z-index: 4;
  transition: all 0.5s;
  transform: scaleY(${({ show }: { show: boolean }) => (show ? "100%" : "0")});
  transform-origin: 50% 0%;
  overflow: hidden;
`;

const DropdownSectionTitle = styled.h4`
  font-size: 1.5rem;
  font-weight: 500;
  border-bottom: 1px solid ${colors.white.hex};
  width: 100%;
  padding: 0.5rem 1rem;
`;

const DropdownItem = styled.div`
  width: 100%;
  padding: 0.5rem 1.5rem;
  font-size: 1.4rem;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 0.5rem;

  &:hover {
    background-color: ${colors.white.hex};
  }
`;

const Logout = styled(DropdownItem)`
  background-color: ${colors.secondary.hex};
  color: ${colors.white.hex};
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  margin-top: 1rem;

  &:hover {
    background-color: #e3950a;
  }
`;
