export interface Test {
  id: string;
  created_at: Date;
  name: string;
  total_marks: number;
  duration: number;
  completed: boolean;
  completed_at?: Date;
  obtained_marks?: number;
  questions: number[];
  responses: Record<string, MCQResponse | null>;
  user_id: string | null;
  board: string;
  subject: string;
  guest_user: boolean;
  started: boolean;
  time_elapsed: number;
}

export enum MCQResponse {
  A = 0,
  B = 1,
  C = 2,
  D = 3,
}
